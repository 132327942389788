import { PathService } from '../../../../shared/services/path.service';
import { AuthenticationService } from '../../../../shared/services/auth.service';
import { Job } from '../../../../shared/models/job.model';
import { UserList, } from '../../../../shared/models/auth.models';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { IDeleteComponent } from '../../../common/idelete/idelete.component';
import { ToolLocation, Tools } from '../../tools.data';
import { UserService } from '../../../../shared/services/users/user.service';
import { DateService } from './../../../../shared/services/date.service';
import { SearchService } from './../../../../shared/services/search.service';
import { ActivatedRoute } from '@angular/router';
import { ToolLocationsService } from '../../../../shared/services/requests/core/tools.service';

@Component({
  selector: 'tools-list',
  templateUrl: './tools-list.component.html',
  styleUrls: ['./tools-list.component.scss']
})
export class ToolsAndAssetsListComponent implements OnInit {

  @ViewChild(IDeleteComponent) idelete: IDeleteComponent;
  public delete_path = this.pathService.DELETE_USER_PATH
  public onDeleteChange: Subject<any> = new Subject();

  public changingValue: Subject<any> = new Subject();


  public page:number = 1
  public config: any = {
    currentPage: 1,
    pageSize: 10,
    maxSize: 10
};

  public tool:Tools = new Tools()
  @Output() onResponse = new EventEmitter<any>()
  @Input() tools: Tools[] = new Array<Tools>()
  public loading: boolean = false

  public is_update: boolean = false

  public status: string = "success"

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private pathService:PathService,
    private userService: UserService,
    private dateService: DateService,
    private searchService: SearchService,
    private toolLocationsService: ToolLocationsService
    ) {
  }


  public access: any;

  ngOnInit(): void {

    this.access = this.authService.access.user
    this.loadUsers()
    this.loadLocations()
  }

  public job_search_text: string
  public dataJobHolder:Array<Job> = new Array<Job>()
  searchTableData(){

    this.dataJobHolder = this.searchService.search(this.tools, this.job_search_text)
  }

  createTool(toolView: any){
      this.modalService.open(toolView)
  }

  public users: Array<UserList> = new Array<UserList>();
  loadUsers(){

      let $this = this

      this.userService.all(function(result, status){

        if(status){
            $this.users = result
        }

      })
  }

  public locations: Array<ToolLocation> = new Array<ToolLocation>();
  loadLocations(){

    this.toolLocationsService.all().subscribe(response => {

      if(response && response.status == "success"){
        this.locations = response.data;
        return
    }
    })
  }

  refreshRecords(){

      console.log("logger in tool list")
      this.onResponse.emit(true)
  }

  formatDate(date: string){

    return this.dateService.formatEnUSWithOption(date)
  }

  getItemImage(item: Tools){

    try{

        if(item.images.length > 0){

            let image = item.images[0].name
            return `https://api.ambulance.uk/storage/${image}`
        }
        return null;

    }catch(e){
        return null
    }
  }


  // *ngIf="item.current&&item.current.user">
  //                       {{ item.current.user.name }}</span>


  getCurrentPocessor(item: Tools){
    try{


      if(item.current.user){
          let username = item.current.user.name;
          return `With ${username}`
      }else{

        if(item.place){

          let location = item.place.name
          return `At ${location}`

        }
      }
    }catch(e){

    }
  }

}

import { VehicleAssigned } from '../../../../reporting/service-report/editor/model/report.model';
import { VehicleScheduleService, VehicleJobHistoryService } from '../../../../../shared/services/requests/core/vehicles.service';
import { frequencies, ServiceHistory, States, scheduleStates, VehicleSchedule, VehicleUsers, VehicleWorkshop, AssignedVehicle, VehicleDocuments } from '../vehicle.data';
import { UtilService } from '../../../../../shared/services/utilities/utils.service';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
  ButtonsConfig, ButtonsStrategy, AdvancedLayout, GalleryService, Image, KS_DEFAULT_BTN_CLOSE, KS_DEFAULT_BTN_DELETE, KS_DEFAULT_BTN_DOWNLOAD, KS_DEFAULT_BTN_EXTURL, KS_DEFAULT_BTN_FULL_SCREEN, ButtonEvent, ButtonType, PlainGalleryConfig, PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery';
import { AuthenticationService } from '../../../../../shared/services/auth.service';
import { request_error } from '../../../../../shared/services/requests/core/status.data';

@Component({
  selector: 'vehicle-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class VehicleFileComponent implements OnInit {

  @Input() vehicleid!: number
  @Input() documents: VehicleDocuments[] = new Array<VehicleDocuments>()

  public onDeleteChange: Subject<any> = new Subject();
  @Output() onSuccessResponse = new EventEmitter<boolean>();

  public page = 1
  public config: any = {
      currentPage: 1,
      pageSize: 6,
      maxSize: 5
  };

  public is_update: boolean = false

  public status: string = "success"

  public selectedFrequency: States = new States()
  public frequencys: States[] =  frequencies


  public selectedState: States = new States()
  public states: States[] = scheduleStates;

  public payload: AssignedVehicle = new AssignedVehicle()

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    private utilService: UtilService,
    private assignedService: VehicleJobHistoryService
    ) {}

  public userid:any;

  ngOnInit(): void {

        this.setGalleryImage()
  }

  reload(){

      // console.log("Files Hoow--------------------------------")
      this.onSuccessResponse.emit(true)
  }

   /** GALLERY SECTION ------------- */

   public GalleryImage: Image[] = []

   buttonsConfigDefault: ButtonsConfig = {
     visible: true,
     strategy: ButtonsStrategy.DEFAULT
   }

   buttonsConfigSimple: ButtonsConfig = {
     visible: true,
     strategy: ButtonsStrategy.SIMPLE
   };

   buttonsConfigAdvanced: ButtonsConfig = {
     visible: true,
     strategy: ButtonsStrategy.ADVANCED
   };

   buttonsConfigFull: ButtonsConfig = {
     visible: true,
     strategy: ButtonsStrategy.FULL
   };

   buttonsConfigCustom: ButtonsConfig = {
     visible: true,
     strategy: ButtonsStrategy.CUSTOM,
     buttons: [
       KS_DEFAULT_BTN_FULL_SCREEN,
       KS_DEFAULT_BTN_DELETE,
       KS_DEFAULT_BTN_EXTURL,
       KS_DEFAULT_BTN_DOWNLOAD,
       KS_DEFAULT_BTN_CLOSE
     ]
   };


   customPlainGalleryRowDescConfig: PlainGalleryConfig = {
     strategy: PlainGalleryStrategy.CUSTOM,
     layout: new AdvancedLayout(-1, true)
   };

   openImageModalRowDescription(image: Image) {
     const index: number = this.getCurrentIndexCustomLayout(image, this.GalleryImage);
     this.customPlainGalleryRowDescConfig = Object.assign({}, this.customPlainGalleryRowDescConfig, { layout: new AdvancedLayout(index, true) });
   }

   private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
     return image ? images.indexOf(image) : -1;
   };

   onButtonBeforeHook(event: ButtonEvent) {
     if (!event || !event.button) {
       return;
     }

     if (event.button.type === ButtonType.DELETE) {
       this.GalleryImage = this.GalleryImage.filter((val: Image) => event.image && val.id !== event.image.id);
     }
   }

   onButtonAfterHook(event: ButtonEvent) {
     if (!event || !event.button) {
       return;
     }
   }

   onCustomButtonBeforeHook(event: ButtonEvent, galleryId: number | undefined) {
     if (!event || !event.button) {
       return;
     }

     if (event.button.type === ButtonType.CUSTOM) {
       this.addRandomImage();
       setTimeout(() => {
         this.galleryService.openGallery(galleryId, this.GalleryImage.length - 1);
       }, 0);
     }
   }

   onCustomButtonAfterHook(event: ButtonEvent, galleryId: number | undefined) {
     if (!event || !event.button) {
       return;
     }
   }

   addRandomImage() {
     const imageToCopy: Image = this.GalleryImage[Math.floor(Math.random() * this.GalleryImage.length)];
     const newImage: Image = new Image(this.GalleryImage.length - 1 + 1, imageToCopy.modal, imageToCopy.plain);
     // this.GalleryImage = [...this.GalleryImage, newImage];
   }

   setGalleryImage(){

        this.documents.forEach(row => {

          //console.log("Demo Data:::", row)
          let image = new Image( 1, { img: `https://api.ambulance.uk/storage/${row.name}`, title: '', })
          this.GalleryImage.push(image)
        });

        //console.log(this.GalleryImage, this.documents)
   }



}

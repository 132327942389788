import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

    validate(obj: Record<string, any>): boolean {

        console.log("Checking object", obj)

        if(Object.keys(obj).length === 0){
            return false;
        }

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'string' && obj[key].trim() === '') {
                return false; // String property is empty
              }
            }
            if (obj.hasOwnProperty('email')) {
                const email = obj.email;
                if (this.isValidEmail(email)) {
                  return true; // Email is present and in a valid format
                }
              }
          }
          return true; // Object is not empty, and all values are non-empty
      }

      isValidEmail(email: string): boolean {
            // Regular expression to validate email format
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailPattern.test(email);
      }
}
import { Injectable } from '@angular/core';
import { CoreService } from './app.service';
import { CoreRequestService } from './request.service';


@Injectable({
  providedIn: 'root'
})
export class PostTreatmentService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `report/fetch/treatment-note`;
        this.ALL_PATH       = `report/list/treatment-note`;
        this.FIND_PATH      = `report/find/treatment-note`;
        this.UPDATE_PATH    = `report/update/treatment-note`;
        this.CREATE_PATH    = `report/create/treatment-note`;
        this.DELETE_PATH    = `report/delete/treatment-note`;
    }

    /**
     * All Other methods can come here
     */
}
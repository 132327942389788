import { Job } from './../../../shared/models/job.model';
import { UserService } from './../../../shared/services/users/user.service';
import { JobPath } from './../../../shared/services/jobs/job-path';
import { JobAssignedService } from './../../../shared/services/jobs/assigned.service';
import { JobService } from './../../../shared/services/jobs/job.service';
import { AuthenticationService } from './../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'flatpickr/dist/flatpickr.css';


const colors: any = {
  red: {
    primary: '#705ec8',
    secondary: '#6958be',
  },
  blue: {
    primary: '#fb1c52',
    secondary: '#f83e6b',
  },
  yellow: {
    primary: '#ffab00',
    secondary: '#f3a403',
  },
};



@Component({
  selector: 'embedded-calendar',
  templateUrl: './embedded-calendar.component.html',
  styleUrls: ['./embedded-calendar.component.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class EmbeddedCalendarComponent implements OnInit {

  public changingValue: Subject<any> = new Subject();

  public access: any;

  constructor(
    private authService: AuthenticationService,
      private toastService: ToastrService,
      private modal: NgbModal,
      private jobService: JobService,
      private jobAssignedService: JobAssignedService,
      private jobPath:JobPath,
      private userService: UserService) {
      // constructor action
    }

  ngOnInit(): void {

      // console.log("Days: ", startOfDay(new Date()))

      this.loadJobs();
      this.loadUsers();

      this.access = this.authService.check(this.authService.CALENDAR_ACCESS)
  }

  @ViewChild('modalAssign', { static: true })
  modalAssign: TemplateRef<any>;

  modalAssignData: {
    date: Date;
    jobs: CalendarEvent<any>[];
    users: CalendarEvent<any>[];
  };

  assignPayload: any = {}

  @ViewChild('modalContent', { static: true })

  modalContent: TemplateRef<any>;

  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  // start: subDays(startOfDay(new Date()), 0),
      // end: addDays(new Date(), 1),

    public jobs: Array<Job>;

    loadJobs(){

        let $this = this

        this.jobService.all(function(result, status){

           if(status){

              $this.jobs = result

              $this.setJobOnCalendar(result, colors.red)

           }

        }, {"type":"jobs"})
    }

    loadUsers(){

      let $this = this

      this.userService.availability(function(result, status){

        if(status){

          // console.log("availability", result)

            // $this.setUsersOnCalendar(result, colors.blue)

        }

      })
  }

  setUsersOnCalendar(jobs: any, color:any){

    jobs.forEach(elem => {

      try{
        let data: any = {
          userid: elem.user_id,
          start: new Date(`${elem.start_date.toString().replace(" ", "T")}Z`),
          end: new Date(`${elem.end_date.toString().replace(" ", "T")}Z`),
          title: elem.user.name,
          color: color,
          actions: this.actions,
          allDay: true,
          draggable: true,
        }
        this.events.push(data)

      }catch(e){

      }

    });

    this.refresh.next();

    // console.log("Testing User Log: ", jobs, this.events)

  }

  setJobOnCalendar(jobs: any, color:any){



      jobs.forEach(elem => {

        // const nDate = new Date("2022-09-09T00:23:00").toLocaleString('en-US', {
        //   timeZone: 'Europe/London'
        // });
        // // console.log("Data Testing Date", nDate, elem.start_date)

          let data: any = {
            jobid:elem.id,
            start: new Date(`${elem.start_date.toString().replace(" ", "T")}Z`),
            end: new Date(`${elem.end_date.toString().replace(" ", "T")}Z`),
            title: elem.name,
            color: color,
            actions: this.actions,
            allDay: true,
            draggable: true,
          }
          this.events.push(data)

      });

      this.refresh.next();

      // console.log("Testing Job Log: ",jobs, this.events)

  }

  onUserSelected(userid: number){

      this.assignPayload.user_id = userid
  }

  onJobSelected(jobid: number){

      this.assignPayload.job_id = jobid
  }

  onUserAssigned(){

      let $this = this;

      this.assignPayload.job_assigned_date = this.modalAssignData.date
      this.assignPayload.status = 1;

      // // console.log("Payload Assign: ", this.assignPayload)

      // $this.chatService.sendTask( this.assignPayload )

      // send request to server
      this.jobAssignedService._post(this.jobPath.CREATE_ASSIGNED_JOB_USER, this.assignPayload, function(response, status, message){

        if( status ){
          // $this.jobs = result;
          $this.modal.dismissAll()

          $this.toastService.success(message)

          // emit event so the record can be added to calender on the phone
          // $this.chatService.sendTask( this.assignPayload )

          // $this.changingValue.next({status:"success", message:""})
          return;
       }

       // error occured
       $this.toastService.success("You cannot assing a task multiple times to the same user!")

      })

  }

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;

      // console.log("all events: ", events, date)

      /**
       * Parse selected dates to get the list of users, jobs and the date
       */
      this.parseSelectedDate(date, events);

    }
  }

  /**
   *
   * @param date
   * @param events
   */
  parseSelectedDate(date:Date, events: CalendarEvent<any>[] ){

    let jobs:any = [];
    let users:any = [];

    events.forEach(elem => {

        let data:any = elem

        if(data.jobid !== undefined){
            jobs.push(elem)
        }

        if(data.userid !== undefined){
            users.push(elem)
        }
    })

    // console.log("Sorted Payloads: ", jobs, users)

      /**
       * Open Modal for the date clicked
       */
     if(this.access.execute){
        this.modalAssignData = { date, jobs, users };
        this.modal.open(this.modalAssign, { centered: true });
     }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }
  newEvent: CalendarEvent;
  addEvent(): void {
    this.newEvent = {
      title: 'New event',
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
      color: colors.red,
      draggable: true,
      actions: this.actions,
    }
    this.events.push(this.newEvent);

    this.handleEvent('Add new event', this.newEvent);
    this.refresh.next();
  }

  eventDropped({
    event,
    newStart,
    newEnd,
    allDay,
  }: CalendarEventTimesChangedEvent): void {
    const externalIndex = this.events.indexOf(event);
    if (typeof allDay !== 'undefined') {
      event.allDay = allDay;
    }
    if (externalIndex > -1) {
      this.events.splice(externalIndex, 1);
      this.events.push(event);
    }
    event.start = newStart;
    if (newEnd) {
      event.end = newEnd;
    }
    if (this.view === 'month') {
      this.viewDate = newStart;
      this.activeDayIsOpen = true;
    }
    // this.events = [...this.events];
  }

  externalDrop(event: CalendarEvent) {
    if (this.events.indexOf(event) === -1) {
      this.events = this.events.filter((iEvent) => iEvent !== event);
      this.events.push(event);
    }
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


}

import { Component, OnInit, Output, Input, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserList, } from '../../../shared/models/auth.models';
import { Tools, ToolHistory, ToolLocation } from '../../tools-assets/tools.data';
import { AssetTransferService } from '../../../shared/services/requests/core/tools.service';

@Component({
  selector: 'assets-transfer',
  templateUrl: './assets-transfer.component.html',
  styleUrls: ['./assets-transfer.component.scss'],
})
export class TransferAssetsComponent {

  constructor(
    private modal: NgbModal, 
    private toastr: ToastrService,
    private assetTransferService: AssetTransferService){
  }

  @Output() onResponse = new EventEmitter<any>()
  @Input() target!: string //could be users | locations
  @Input() item!: Tools
  @Input() users: UserList[]
  @Input() locations: ToolLocation[]


    ngAfterViewInit(){

    }

    open(transferAssetToUser){
          this.modal.open(transferAssetToUser, {size: 'sm'})
    }

    public types: any[] = [
        { name: "Assign to User"},
        { name: "Move to Location"}
    ]
    public isLocation: boolean = false;
    public location: string = "";

    loadLocations(){

      this.assetTransferService.all().subscribe(response => {
  
        if(response && response.status == "success"){
          this.locations = response.data;
          return
       }
      })
    }

    onSelectChange($event){
      
        if($event == "Assign to User")
        { 
          this.isLocation = false; 
          this.target = "user";
        }else{ 
          this.isLocation = true;
          this.target = "location";
        }
    }
    public payload: ToolHistory = new ToolHistory()

    transfer(){

        this.payload.radio_id = this.item.id;
        this.payload._state = 1;
        this.payload.note = `Admin Assigned this ${this.target}`
        this.payload.target = this.target

        // console.log("data check", this.payload); return;

        this.assetTransferService.create(this.payload).subscribe(response => {
            if(response && response.status == "success")
            {
                this.onResponse.emit(true)
                this.toastr.success(response.message, "Success!"); return;
            }
            this.toastr.error(response.message, "Error!");
        })
    }

}

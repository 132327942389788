import { VehicleJobHistoryService } from './../requests/core/vehicles.service';
import { VehicleService } from 'src/app/shared/services/requests/core/vehicles.service';
import { ToastrService } from 'ngx-toastr';
import { Stock } from './../../../components/jobs/stock/stock.model';
import { PathService } from './../path.service';
import { ServiceRecord, Hospital, Equipment, Vehicle, JobLocation, Observation, Drug, Procedure } from './../../../components/reporting/service-report/editor/model/report.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestService } from './../request.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ReportAccessor {

  constructor(
    private appService: AppService,
    private pathService: PathService,
    private toastr: ToastrService,
    private vehicleService: VehicleService,
    private vehicleJobHistoryService: VehicleJobHistoryService ){}

   // Private
   private _patient: BehaviorSubject<ServiceRecord[] | null> = new BehaviorSubject(null);
   private _hospital: BehaviorSubject<Hospital[] | null> = new BehaviorSubject(null);
   private _equipment: BehaviorSubject<Equipment[] | null> = new BehaviorSubject(null);
   private _vehicle: BehaviorSubject<Vehicle[] | null> = new BehaviorSubject(null);
   private _locations: BehaviorSubject<JobLocation[] | null> = new BehaviorSubject(null);
   private _drugs: BehaviorSubject<Drug[] | null> = new BehaviorSubject(null);
   private _observations: BehaviorSubject<Observation[] | null> = new BehaviorSubject(null);
   private _procedures: BehaviorSubject<Procedure[] | null> = new BehaviorSubject(null);
   private _stocks: BehaviorSubject<Stock[] | null> = new BehaviorSubject(null);

   /** * Getter for tags             */
   get patients$(): Observable<ServiceRecord[]>
   {
       return this._patient.asObservable();
   }
   get hospitals$(): Observable<Hospital[]>
   {
       return this._hospital.asObservable();
   }
   get equipments$(): Observable<Equipment[]>
   {
       return this._equipment.asObservable();
   }
   get vehicles$(): Observable<Vehicle[]>
   {
       return this._vehicle.asObservable();
   }
   get locations$(): Observable<JobLocation[]>
   {
       return this._locations.asObservable();
   }
   get drugs$(): Observable<Drug[]>
   {
       return this._drugs.asObservable();
   }
   get observation$(): Observable<Observation[]>
   {
       return this._observations.asObservable();
   }

   get procedures$(): Observable<Observation[]>
   {
       return this._procedures.asObservable();
   }

   get stocks$(): Observable<Stock[]>
   {
       return this._stocks.asObservable();
   }

//   public JOB_UPDATE_DETAILS =  `job/update/details`;

   updateJobDetails(option){
        return this.appService.post(this.pathService.JOB_UPDATE_DETAILS, option )
   }

   getPatient(options){
      this.appService.get(this.pathService.SERVICE_LIST_RECORD_ITEMS ).subscribe(response => {
          if(response.status=="success"){ this._patient.next(response.data) }
      }, error => "")
   }

    getHospital(){
      this.appService.get(this.pathService.SERVICE_LIST_HOSPITALS).subscribe(response => {
          if(response.status=="success"){ this._hospital.next(response.data) }
      }, error => "")
   }

   findHospital(option){
    this.appService.post(this.pathService.SERVICE_SEARCH_HOSPITALS, option).subscribe(response => {
        if(response.status=="success"){ 
            this._hospital.next(response.data) 
        }
    }, error => "")
 }

   

   getEquipment(){
      this.appService.get(this.pathService.SERVICE_LIST_EQUIPMENTS).subscribe(response => {
          if(response.status=="success"){ this._equipment.next(response.data) }
      }, error => "")
    }

  getVehicle(){

      this.vehicleService.all().subscribe(response => {
          if(response.status == 'success'){
              this._vehicle.next(response.data)
          }
      })
    }

    findVehicle(option:any){

        return this.vehicleJobHistoryService.find(option)
    }

  getLocation(){
      this.appService.get(this.pathService.LIST_LOCATION_PATH).subscribe(response => {
          if(response.status=="success"){ this._locations.next(response.data) }
      }, error => "")
    }

    getDrug(){
      this.appService.get(this.pathService.LIST_DRUG_ITEMS).subscribe(response => {
          if(response.status=="success"){ this._drugs.next(response.data) }
      }, error => "")
    }

    getObservation(){
      this.appService.get(this.pathService.LIST_OBSERVATION_ITEMS).subscribe(response => {
          if(response.status=="success"){ this._observations.next(response.data) }
      }, error => "")
    }

    getProcedure(){
        this.appService.get(this.pathService.LIST_PROCEDURE_ITEMS).subscribe(response => {
            if(response.status=="success"){ this._procedures.next(response.data) }
        }, error => "")
    }

    /**
     * Setters
     */
     setStock(option){
        return this.appService.post(this.pathService.CREATE_JOB_STOCKS, option)
     }

     findStocks(option){
          this.appService.post(this.pathService.FIND_JOB_STOCKS, option).subscribe(response => {

            console.log("Finder Checks:: ", response)
              if(response.status=="success"){ this._stocks.next(response.data) }
          }, error => this.toastr.error("No stock record found for this job"))
     }

}

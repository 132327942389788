<div class="row">

  <div class="col-lg-12 image-holer border text-center">

    <div *ngIf="getFirstImage(images)">
      <img *ngIf="images&&images.length!=0" src="{{ setImagePath(mainImage) }}"/>
      <p *ngIf="images&&images.length==0">Image Preview</p>
    </div>

  </div>
  <div class="col-lg-12">
        <div class="thumbs">
            
            <div
                *ngFor="let image of images"
                class="thumbnail border">
                <button class="btn btn-sm btn-danger trash-button" (click)="deleteImage(image)">
                  <i class="fa fa-trash"></i>
               </button>
                <img (click)="selectedImage(image)" src="{{setImagePath(image.name)}}"/>
            </div>
        </div>
  </div>

  <div class="col-lg-12">
      <div class=" text-center" *ngIf="vehicle.id">

        <div class="ff_fileupload_wrap my-2">
            <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
                <ngx-dropzone-label>Drop it, Here!</ngx-dropzone-label>
                <ngx-dropzone-preview class="boxImage" *ngFor="let f of files" [removable]="true"
                    (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
        </div>

      </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class ImageUtilService {


  getFileContent(file, callback) {

      this.readFile(file).then(fileContents => {
        // Put this string in a request body to upload it to an API.
        callback(fileContents);
      })
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {

      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader | any).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }
}

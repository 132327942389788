export let briefingNote = `
<h4>
All accidents must be reported to ESR  (event safety rep ) via control room and logged on
the SHE system plus Medical solutions App
</h4>
<ol>
<li>No Microwaving food in the medical centre please use staff room </li>
<li>No photos or videos inside the arena bowl</li>
<li>No access backstage unless medical assistance is required </li>
<li>Please keep the medical centre tidy </li>
<li>No drinks left on the side or by electrical equipment </li>
<li>Batteries back on charger at the end of shift </li>
<li>Please make sure fridge is left empty</li>
</ol>
`
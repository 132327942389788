import { Participant } from './../../../components/common/chat/invite/participant.model';
import { UserService } from './../users/user.service';
import { ParticipantService } from './../chat/participant.service';
import { User, ChatActiveUser } from './../../models/auth.models';
import { ToastrService } from 'ngx-toastr';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { audio_str } from './sound';
import { ChatService } from './chat.service';
import { AuthenticationService } from '../auth.service';

@Injectable({
    providedIn: 'root'
  })
export class RunnerService {

    public chat!: ChatService;

    constructor(
        private chatService: ChatService,
        private authService: AuthenticationService,
        private userService: UserService,
        private participantService: ParticipantService,
        private toastr: ToastrService
        ){

        // this.chatService.init()
        this.chat = this.chatService;
    }



    public socket1 = new Subject<string>();
    public socket1$ = this.socket1.asObservable()

    public subscription = this.socket1$.subscribe(data => {
            // do stuff with data
            // e.g. this.property = data
            // this.socket = data;
            // console.log("Cart Updater:: Running",  data)
    });

    public updateStringSubject(newStringVar: string) {
        this.socket1.next(newStringVar);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    ngOnInitRunner(){

        // console.log("On init Running form Runner Service")

        /**
         * Session
         */
        // this.loadChatSession()

        /**
         * Status Listener
         */
        this.registerStatusListener();

        /**
         * Online Status Pulse
         */
        this.registerOnlineStatusPulse()

        // this.tokenRefreshService.checkVisibility();

        this.loadUsers();

        this.loadChatParticipants();
    }

    private users: Array<ChatActiveUser> = new Array<ChatActiveUser>();
    loadUsers(){

        let $this = this;

         this.userService.all(function(response, status){

           if(status){
              $this.users = response
           }
           // console.log("All users", response, $this.users)
         })
    }

    private sessions: Array<Participant> = new Array<Participant>();

    loadChatParticipants(){

        let userid = this.authService.userid();

         this.participantService.filter(userid).subscribe(data => {

          // console.log("participants:: Channel::", data)

           data.data.forEach(element => {

               this.sessions.push(element)
               // Register chat channel listener
               this.registerListener(element._channel.channel)
            //    // console.log("Users Channel::", element._channel.channel)
           });
         })
     }

     registerListener(channel: string){

        // console.log("Listerner Registration::", channel)

        this.chatService.disconnect(channel)

        this.chatService.connect(channel).subscribe(data => {

            // this.messages.push(data)
            // console.log("Checking onMessage::", data, channel, this.authService.identifier())
            if(data.source != this.authService.identifier()){
                this.chatService.audio().play()

                if(!data.message){
                    return
                }
                if(!data._source){
                    this.toastr.info(data.message, "New Message Received")
                    return;
                }
                let source = data._source
                this.toastr.info(data.message, `MS: ${source.name}`)
            }

        })
     }

    registerStatusListener(){

        let identifier = this.authService.identifier();
        if(!identifier){
            return;
        }
        this.chatService.connect(`connection_status_update_${identifier}`).subscribe(data => {
            // if(data.status){

            // }
            // console.log("Connection Updates:: ", data)

            // data.forEach(element => {
            //     this.searchUser(element)
            // });
        })

        this.chatService.connect(`connection_status_update`).subscribe(data => {

            if(!this.users) return;

            let user = this.users.filter(x => {
                return x.identifier == data.target
            })

            if(user.length == 0) return

            if(this.authService.isSuperAdmin()){

                this.toastr.clear()

                if(data.status){
                    this.toastr.info(`${user[0].name} connected`)
                }else{
                    this.toastr.info(`${user[0].name} disconnected`)
                }
            }

        })
    }

     /**
   * Register independent listeners
   */
   registerOnlineStatusPulse(){

    // // console.log("Pulse Working....")

      setTimeout(() => {
          this.onlineStatusRequester()
      }, 3000)
  }

  /**
  * Ask server for who is online
  */
  onlineStatusRequester(){

      let identifier = this.chatService.getIdentifier();

      // let targets: Array<string> = []
      // this.users.forEach(x => {
      //   if(x._channel.is_group == 0){
      //       if(identifier ==  x._channel.source){
      //         targets.push(x._channel.target);
      //       }else{
      //         targets.push(x._channel.source);
      //       }
      //   }
      // });

      // // console.log("Checking Requesr::", identifier, targets);

      // let chat: Chat = new Chat()
      // chat.action = "whois_online"
      // chat.channel = null;
      // chat.message = ""
      // chat.source = this.authService.identifier()
      // chat.data = targets
      // chat.target = "server"
      // this.chatService.send(chat)

      // check the network status if return false, break the recursion
      // to be added later
      this.registerOnlineStatusPulse()
  }

  /**
   *
   * @param data
   * @param type = status | typing
   */
   searchUser(target:any){

    //search in session
    let session = this.users.filter(x => {
        // return (x.identifier == target || x._channel.source == target)
    })

    // console.log("Searching User", session)
  }
}

<div class="card custom-card">
    <div class="card-header  border-bottom-0 pb-0">
        <div>
            <div class="">
                <label class="main-content-label my-auto pt-2">Records</label>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="row table-filter">
            <div class="col-lg-3">
                <div class="show-entries">
                    <span>Show</span>
                    <select class="form-control">
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
            <div class="col-lg-9 d-lg-flex">
                <div class="d-flex ml-auto mt-4 mt-lg-0">
                    <div class="filter-group">
                        <input 
                            type="text" 
                            class="form-control" 
                            [(ngModel)]="job_search_text" 
                            (keyup)="searchTableData()" 
                            placeholder="search">
                    </div>
                    <button type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
                </div>
                <div class="d-flex mt-4 mt-lg-0">

                </div>
            </div>
        </div>
        <div class="table-responsive border">

          <table class="table mb-0 text-nowrap text-md-nowrap">
            <thead>
                <tr>
                    <th>Image</th>
                    <th>Item</th>
                    <th>Number.</th>
                    <!-- <th>Location</th> -->
                    <th>Description</th>
                    <th>Tracking</th>
                    <th>Date Taken</th>
                    <th>Status</th><!-- active=1, damaged=0-->
                    <th>Note</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr *ngFor="let item of tools"> -->
                <tr class="border-bottom" *ngFor="let item of (dataJobHolder.length !=0 ? dataJobHolder : tools) | slice: (page-1) * config.pageSize : page * config.pageSize; let index = i">
                    <td><img style="width: 45px;height:auto;" *ngIf="getItemImage(item)" src="{{ getItemImage(item) }}" alt="{{ item.item}}" /></td>
                    <td><a href="/resources/tools/{{ item.uuid }}/details">{{ item.item }}</a></td>
                    <td>{{ item.number }}</td>
                    <!-- <td>{{ item.location }}</td> -->
                    <td>{{ item.description }}</td>
                    <td><span>
                        {{ getCurrentPocessor(item) }}
                    </span> </td>
                    <td><span *ngIf="item.current">{{ formatDate(item.current.created_at) }}</span></td>
                    <td>{{ item._state }}</td>
                    <td>-</td>
                    <td>
                        <assets-transfer [item]="item" (onResponse)="refreshRecords()" [locations]="locations" [users]="users" [asset]="item"></assets-transfer>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="mt-3">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="tools.length" [maxSize]="config.maxSize" [pageSize]="config.pageSize" [(page)]="page" aria-label="Custom pagination">
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
        </div>

        </div>
    </div>
</div>
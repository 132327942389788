import { UserRecord } from 'src/app/shared/models/auth.models';
import { Job } from 'src/app/shared/models/job.model';

export class ServiceRecord
{
    public id: number
    public patient: string
    public report_id: number
    public time_in: string
    public user_id: number
    public is_arena_staff: number	  = 0;
    public is_catering: number      = 0;
    public is_contractor: number    = 0;
    public is_exhibitor: number	    = 0;
    public is_visitor: number	      = 0;
    public is_cleaning:number       = 0;
    public is_security:number       = 0;
    public illness_or_treatment_description: string
    public hospital_id: number
    public vehicle_id: number
    public location_id: number
    public gender: string
    public she_number: string
    public status: number
    public created_at: string
    public updated_at: string
    public job!: Job
    public hospital: Hospital  = new Hospital
    public equipment: AttachedEquipment[] = Array<AttachedEquipment>()
    public observation: Observation[]     = Array<Observation>()
    public drug: Drug[] = Array<Drug>()
    public procedure: Procedure[] = new Array<Procedure>()
    public user: UserRecord = new UserRecord
    public location: JobLocation = new JobLocation
    public vehicle: Vehicle =  new Vehicle
    public treatment: PostTreatment
    public is_ambulance_needed: number
}

export class Service {
    job_id: number
    senior_staff_id: number
    date: string
    paramedics: number
    technicians: number
    clock_in_time: string
    clock_out_time: string
    callouts:number
    ear_pugs: number
    ambulances: number
    arena_staff: number
    catering: number
    event_staff: number
    competitor: number
    visitor: number
    use_of_o2: string
    use_of_entonox: string
}

export class Hospital {

    public id: number;
    public name: string;
    public address: string
    public location_id: number;
    public email: string;
    public phone: string;
    public status: number;
    public created_at: string
    public updated_at: string
}

export class EquipmentPayload {

    id!: number
    quantity!: number
    report_item_id!: number
    equipment_id!: number
    tool!: Equipment
    name: string;
    type: string
    active_id!: number
    description: string;
    cost: number;
    status: number;
    created_at: string;
    updated_at: string;
    is_new: boolean = false
}

export class Equipment {

  public id: number;
  public name: string;
  public type: string
  public quantity: number = 0
  public checked:boolean = false
  public report_item_id?: number
  public is_new:boolean = true
  public description: string;
  public cost: number;
  public status: number;
  public created_at: string;
  public updated_at: string;
}

export class Drug extends Equipment {}

export class Observation extends Equipment {}
export class Procedure extends Equipment {}

export class EmailReport {
  public report_id: number
  public email: string
  public message: string
}

export class JobLocation
{
    id!: number;
    identifier!: string
    name!: string;
    address!: string;
    postcode!: string;
    status!: number;
    created_at!: string;
    updated_at!: string;
}

export class Vehicle
{
    id!: number;
    uuid!:string
    identifier!: string
    name!: string;
    type!:string
    registration!: string;
    date_insured!: string
    model!: string
    tax_due_date!: string
    service_due_date!: string
    mot_due_date!: string
    insurance_company!: string
    _state!: number;
    status!: number;
    images!: VehicleProfileImage[]
    created_at!: string;
    updated_at!: string;
    editable: boolean = false
}

export class VehicleProfileImage {
  id!: number;
  uuid!: string;
  vehicle_id!: number;
  name!: string;
  type!: string;
  status!: number;
  created_at!: string;
  updated_at!: string;

}

export class VehicleAssigned {

  id!: number;
  uuid!: string
  job_id!: number;
  vehicle_id!: number;
  description!: string;
  assigned_by_id!: number;
  _state!: number;
  created_at!: string;
  updated_at!: string;
}


export class Tool {
  id: number;
  name: string;
  type: string;
  status: number;
  created_at: Date;
  updated_at: Date;
}

export class AttachedEquipment {
  id: number;
  report_item_id: number;
  equipment_id: number;
  status: number;
  created_at: Date;
  updated_at: Date;
  tool: Tool;
}


export class PostTreatment {

  id!: number
  uuid!: string
  patient_id!: number
  callout!: string
  is_drug!: string
  is_alcohol!: string
  is_heat_related!: string
  to_event!: string
  to_home!: string
  to_hospital!: string
  category!: string
  type!: string
  oxygen_use!: string
  enthonox_use!: string
  status!: number
  created_at!: string
  updated_at!: string
}

export class Fault {

    id!: number
    uuid!: string
    situation!: string
    vehicle_id!: number
    job_id!: number
    name!: string
    description!: string
    _state!: string
    status!: number
    created_at!: string
    updated_at!: string
    vehicle!: Vehicle
}
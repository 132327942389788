<div class="card custom-card">
    <div class="card-header  border-bottom-0 pb-0 justify-content-between">
        
        <label class="main-content-label">Briefing Sheet</label>

        <div ngbDropdown class="ml-auto" style="float:right;">
            <a href="javascript:;" class="option-dots" ngbDropdownToggle aria-haspopup="true"
                aria-expanded="false"><i class="fe fe-more-vertical"></i></a>
            <div ngbDropdownMenu>
                <a ngbDropdownItem href="javascript:;" (click)="openAddManager(addBriefingSheetManagers)">
                    <i class="fe fe-user mr-1"></i>Manage
                </a>
            </div>
        </div>
    </div>
    <div class="card-body border-top" style="min-height: 600px;">
        <div class="row">

            <div class="col-lg-12">

                <div class="inner-liner" *ngIf="!briefing">
                    <div class="holder">
                        <div class="btn-label mb-2">No Briefing Sheet Record Found</div>
                        <button (click)="open(addBriefingSheet)" class="btn btn-primary btn-sm"> Create Briefing Sheet </button>
                    </div>
                </div>
                <div class="inner-liner" *ngIf="briefing">

                    <div class="table-responsive">
                        <table class="table mb-0 border-top table-bordered text-nowrap">
    
                            <tbody>
                                <tr>
                                    <th scope="row">Event Manager</th>
                                    <td>
                                        {{ briefing.event_manager}}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Security Manager</th>
                                    <td>
                                        {{ briefing.security_manager }}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">ESR</th>
                                    <td>
                                        {{ briefing.esr }}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Manager on Call</th>
                                    <td>
                                        {{ briefing.manager_on_call }}
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">Max Cap</th>
                                    <td>
                                        <span *ngIf="briefing.max_cap">
                                            {{ briefing.max_cap }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">Artist Stage Time</th>
                                    <td>
                                        <span *ngIf="briefing.artist_stage_time">
                                            {{ briefing.artist_stage_time }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">Finishing Time</th>
                                    <td>
                                        <span *ngIf="briefing.finishing_time">
                                            {{ briefing.finishing_time }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">Door Opening Time</th>
                                    <td>
                                        <span *ngIf="briefing.door_opening_time">
                                            {{ briefing.door_opening_time }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <strong class="mt-2 mb-1">Roaming Medics</strong><br/>
                                        <span *ngIf="briefing.roaming_medics">
                                            {{ briefing.roaming_medics }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <strong class="mt-2 mb-1">Upstairs Medics</strong><br/>
                                        <span *ngIf="briefing.upstairs_medics">
                                            {{ briefing.upstairs_medics }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <strong class="mt-2 mb-1">Downstairs Medics</strong><br/>
                                        <span *ngIf="briefing.downstairs_medics">
                                            {{ briefing.downstairs_medics }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <strong class="mt-2 mb-1">Call Sign Venue</strong><br/>
                                        <span *ngIf="briefing.call_sign_venue">
                                            {{ briefing.call_sign_venue }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <strong class="mt-2 mb-1">Note</strong><br/>
                                        <span *ngIf="briefing.note" [innerHTML]="briefing.note"></span>
                                    </td>
                                </tr>
                              
                                
                            </tbody>
                        </table>
                    </div>

                    <div class="col-lg-12 mt-4">
  
                        <button (click)="open(addBriefingSheet)" class="btn btn-danger btn-sm">Update Briefing Sheet</button>
        
                      </div>

                </div>

            </div>

        </div>
    </div>
</div>

<ng-template #addBriefingSheet let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Create Briefing Sheet</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
  
        <div class="table-responsive">
  
          <div class="row">
  
            <div class="col-lg-6">

                <div class="mt-2">
                    
                    <dropdown-form [label]="'Event Manager'" [list]="eventManagers" (onSuccessResponse)="onChangeEventManager($event)"></dropdown-form>
                    <span>
                        <app-input
                            [type]="'text'"
                            [class]="'form-control'"
                            [id]="'event_manager'"
                            [value]="payload.event_manager"
                            [placeholder]="'Event Manager'"
                            [(inputModel)]="payload.event_manager">
                        </app-input>
                    </span>
                </div>
                   
            </div>

            
            <div class="col-lg-6">
                <div class="mt-2">
                    <dropdown-form [label]="'Security Manager'" [list]="briefingSheetManagers" (onSuccessResponse)="onChangeSecurityManager($event)"></dropdown-form>
                    <span>
                        <app-input
                            [type]="'text'"
                            [class]="'form-control'"
                            [id]="'event_manager'"
                            [value]="payload.security_manager"
                            [placeholder]="'Security Manager'"
                            [(inputModel)]="payload.security_manager">
                        </app-input>
                    </span>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="mt-2">
                    <dropdown-form [label]="'Select ESR'" [list]="briefingSheetManagers" (onSuccessResponse)="onChangeEsrManager($event)"></dropdown-form>
                    <span>
                        <app-input
                            [type]="'text'"
                            [class]="'form-control'"
                            [id]="'event_manager'"
                            [value]="payload.esr"
                            [placeholder]="'ESR Manager'"
                            [(inputModel)]="payload.esr">
                        </app-input>
                    </span>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="mt-2">

                    <dropdown-form [label]="'Medsol Manager On Call'" [list]="medsolManagers" (onSuccessResponse)="onChangeOnCallManager($event)"></dropdown-form>
                    <span>
                        <app-input
                            [type]="'text'"
                            [class]="'form-control'"
                            [id]="'manager_on_call'"
                            [value]="payload.manager_on_call"
                            [placeholder]="'Security Manager'"
                            [(inputModel)]="payload.manager_on_call">
                        </app-input>
                    </span>

                </div>
            </div>

            <div class="col-lg-4 mt-2">
                <label> Max Cap </label>
                <app-input
                    [type]="'number'"
                    [class]="'form-control'"
                    [id]="'max_cap'"
                    [value]="payload.max_cap"
                    [placeholder]="'Max Cap'"
                    [(inputModel)]="payload.max_cap">
                </app-input>
            </div>

            

            <div class="col-lg-4 mt-2">
                <label> Artist Stage Time </label>
                <app-input
                    [type]="'time'"
                    [class]="'form-control'"
                    [id]="'artist_stage_time'"
                    [value]="payload.artist_stage_time"
                    [placeholder]="'Artist Stage Time'"
                    [(inputModel)]="payload.artist_stage_time">
                </app-input>
            </div>

            <div class="col-lg-4 mt-2">
                <label> Door Opening Time </label>
                <app-input
                    [type]="'time'"
                    [class]="'form-control'"
                    [id]="'door_opening_time'"
                    [value]="payload.door_opening_time"
                    [placeholder]="'Door Opening Time'"
                    [(inputModel)]="payload.door_opening_time">
                </app-input>
            </div>

            <div class="col-lg-4 mt-2">
                <label> Finishing Time </label>
                <app-input
                    [type]="'time'"
                    [class]="'form-control'"
                    [id]="'finishing_time'"
                    [value]="payload.finishing_time"
                    [placeholder]="'Finishing Time'"
                    [(inputModel)]="payload.finishing_time">
                </app-input>
            </div>

            <div class="col-lg-8 mt-2">
                <label> Call Sign Venue </label>
                <app-input
                    [type]="'text'"
                    [class]="'form-control'"
                    [id]="'call_sign_venue'"
                    [value]="payload.call_sign_venue"
                    [placeholder]="'Call Sign Venue'"
                    [(inputModel)]="payload.call_sign_venue">
                </app-input>
            </div>
  
              <div class="col-lg-12">
                  <div class="mt-2">
                    <label> Roaming Medics  </label>
                    <app-textarea
                        [type]="'text'"
                        [class]="'form-control'"
                        [id]="'roaming_medics'"
                        [value]="payload.roaming_medics"
                        [placeholder]="'Roaming Medics'"
                        [(inputModel)]="payload.roaming_medics">
                    </app-textarea>
                  </div>
                </div>

                <div class="col-lg-12">
                    <div class="mt-2">
                      <label> Upstairs Medics  </label>
                      <app-textarea
                          [type]="'text'"
                          [class]="'form-control'"
                          [id]="'upstairs_medics'"
                          [value]="payload.upstairs_medics"
                          [placeholder]="'Upstairs Medics'"
                          [(inputModel)]="payload.upstairs_medics">
                      </app-textarea>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mt-2">
                      <label> Downstairs Medics  </label>
                      <app-textarea
                          [type]="'text'"
                          [class]="'form-control'"
                          [id]="'downstairs_medics'"
                          [value]="payload.downstairs_medics"
                          [placeholder]="'Downstairs Medics'"
                          [(inputModel)]="payload.downstairs_medics">
                      </app-textarea>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mt-2">
                      <label> Brieffing Note  </label>
                     
                      <div style="max-height: 300px;overflow-y: scroll;">
                        <quill-text-editor 
                            [label]="'Brieffing Note'"
                            [id]="'message'"
                            [value]="payload.note"
                            (onModelChanged)="onModelChanged($event)"
                            [(inputModel)]="payload.note">
                        </quill-text-editor>
                    </div>
                    </div>
                  </div>
  
                <div class="col-lg-12 mt-4">
  
                  <button (click)="saveBriefinfSheet(payload.id ? 'update' : 'create')" class="btn btn-danger btn-sm">{{ payload.id ? 'Update' : 'Create' }} Briefing Sheet</button>
  
                </div>
  
          </div>
  
        </div>
  
    </div>
  
  </ng-template>



  <ng-template #addBriefingSheetManagers let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Manage</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
  
        <div class="table-responsive">
  
            <div class="row">
    
                <div class="col-lg-12">

                    <nav ngbNav #nav="ngbNav" class="main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                        <ng-container ngbNavItem>
                            <a href="javascript:;" ngbNavLink>Managers</a>
                            <ng-template ngbNavContent>
                                <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                      
                                                <h4>Briefing Sheet Managers</h4>

                                                <div class="table-responsive">
                                                    <table width="100%" class="table tb-padding-squatched table-invoice table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Phone</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of managers;let i = index">
                                                                <td>{{ item.name }}</td>
                                                                <td>{{ item.email }}</td>
                                                                <td>{{ item.phone }}</td>
                                                                <td>
                                                                    <span *ngIf="item._type==0">Event Manager</span>
                                                                    <span *ngIf="item._type==1">MedSol Manager</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                      
                                              </div>
                                    
                      
                            </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem>
                            <a href="javascript:;" ngbNavLink>Add Record</a>
                            <ng-template ngbNavContent>
                                <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                                    <div class="card-body p-0 p-0 rounded-10">
                      
                                      <div class="row row-sm" >
                                          <div class="col-md-12 col-lg-12">
                      
                                                <div class="row">

                                                    <div class="col-lg-6 mt-2">
                                                        <label> Name </label>
                                                        <app-input
                                                            [type]="'text'"
                                                            [class]="'form-control'"
                                                            [id]="'name'"
                                                            [value]="payload.name"
                                                            [placeholder]="'Manager Name'"
                                                            [(inputModel)]="payloadManager.name">
                                                        </app-input>
                                                    </div>
                                    
                                                    <div class="col-lg-6 mt-2">
                                                        <label> Email </label>
                                                        <app-input
                                                            [type]="'email'"
                                                            [class]="'form-control'"
                                                            [id]="'email'"
                                                            [value]="payload.email"
                                                            [placeholder]="'Email Address'"
                                                            [(inputModel)]="payloadManager.email">
                                                        </app-input>
                                                    </div>
                                    
                                                    <div class="col-lg-6 mt-2">
                                                        <label> Phone </label>
                                                        <app-input
                                                            [type]="'phone'"
                                                            [class]="'form-control'"
                                                            [id]="'phone'"
                                                            [value]="payload.phone"
                                                            [placeholder]="'Phone Number'"
                                                            [(inputModel)]="payloadManager.phone">
                                                        </app-input>
                                                    </div>
                                    
                                                    <div class="col-lg-6">
                                                        <div class="mt-2">
                                                            <label> Type  </label>
                                                            <span>
                                                                <app-select
                                                                    [list]="managerTypes"
                                                                    [id]="'_type'"
                                                                    [name]="'name'"
                                                                    [value]="selectedManagerType.name"
                                                                    [placeholder]="'Manager Type'"
                                                                    [(inputModel)]="selectedManagerType">
                                                                </app-select>
                                                            </span>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12 mt-4">
                                                        <button class="btn btn-sm btn-danger" (click)="onCreateManager()">Add Manager</button>
                                                    </div>
                                                    
                                                    <!-- End of forms -->
                                                </div>
                                                
                                              </div>
                                      </div>
                      
                                    </div>
                      
                                </div>
                            </ng-template>
                        </ng-container>
                    </nav>

                    <div class="row row-sm">
                        <div class="col-lg-12 col-md-12">
                            <div class="main-content-body-profile">
                                <div class="tab-content">
                                    <div [ngbNavOutlet]="nav"></div>
                                </div>
                            </div>
                        </div>
                      </div>
                      
                </div>
                
            </div>


        </div>
    </div>
</ng-template>
  
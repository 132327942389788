import { ReportAccessor } from './../../../../shared/services/jobs/report.accessor';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from 'src/app/shared/services/request.service';
import { PathService } from 'src/app/shared/services/path.service';
import { ServiceRecord, JobLocation, Vehicle, Hospital, Equipment, Drug, Observation, Procedure } from './../../../reporting/service-report/editor/model/report.model';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../shared/services/auth.service';
import { UtilService } from 'src/app/shared/services/utilities/utils.service';

@Component({
  selector: 'add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})

/*
 *
 */
export class AddPatientComponent implements OnInit {

  public modalValue: Subject<any> = new Subject();
  public changingValue: Subject<any> = new Subject();

  @Input() jobid: number;
  @Input() reportid: number;
  @Input() locationid: number;
  @Output() onSuccessResponse = new EventEmitter<any>();

 constructor(
  private model: NgbModal,
  private authService: AuthenticationService,
  private pathService: PathService,
  private requestService: RequestService,
  private reportAccessor: ReportAccessor,
  private utilService: UtilService,
  private toastr: ToastrService){}

  public genders: any = [
    {id: 1, name: "Male"},
    {id: 2, name: "Female"  },
    {id: 3, name: "Rather Not Say"}
  ]

  ngOnInit(): void {

    this.reportAccessor.equipments$.subscribe(response => {
        this.equipments = response;
    })

    this.reportAccessor.hospitals$.subscribe(response => {

        // console.log("Logger changes:: test", response)
        if(response){
          this.hospitals = response;
        }
        
    })

    this.reportAccessor.locations$.subscribe(response => {
      if(response){
        this.locations = response;
      }
    })

    // this.reportAccessor.vehicles$.subscribe(response => {
    //     this.vehicles = response;
    // })

    this.reportAccessor.drugs$.subscribe(response => {
        this.drugs = response;
    })

    this.reportAccessor.procedures$.subscribe(response => {
      if(response){
        this.procedures = response;
      }
    })

    this.reportAccessor.observation$.subscribe(response => {
      if(response){
      this.observations = response;
      }
  })

    this.reportAccessor.getEquipment();
    this.reportAccessor.findHospital({location_id: this.locationid});
    this.reportAccessor.getLocation();
    // this.reportAccessor.getHospital();
    this.reportAccessor.getVehicle();

    // this.reportAccessor.getVehicle();
    this.reportAccessor.findVehicle({job_id: this.jobid}).subscribe(response => {
      if(response.status == 'success'){
          let vehicles = response.data;
          if(vehicles){
              vehicles.forEach(element => {
                this.vehicles.push(element.vehicle)
            });
          }
      }
    })

    this.findAssignedVehicles(this.jobid)
    this.reportAccessor.getDrug();
    this.reportAccessor.getObservation();
    this.reportAccessor.getProcedure();
    
  }

 /**
   * Use this to determine if the patient name is known or not
   */
  public is_patient_name: boolean = true;

  public locations: Array<JobLocation> = new Array<JobLocation>()
  public vehicles: Array<Vehicle> = new Array<Vehicle>()
  public hospitals: Array<Hospital> = new Array<Hospital>()
  public equipments: Array<Equipment> = new Array<Equipment>()
  public observations: Array<Observation> = new Array<Observation>()
  public drugs: Array<Drug> = new Array<Drug>()
  public procedures: Array<Procedure> = new Array<Procedure>()

  public new_record: ServiceRecord = new ServiceRecord;

  public selectedHospitals: Hospital = new Hospital()
  public selectedLocations: JobLocation = new JobLocation()
  public selectedGender: Gender = new Gender()

  onSwitchToggled(type){

    this.new_record.is_visitor = 0;
    this.new_record.is_arena_staff = 0;
    this.new_record.is_catering = 0;
    this.new_record.is_contractor = 0;
    this.new_record.is_exhibitor = 0;
    this.new_record.is_cleaning = 0;
    this.new_record.is_security = 0;

    switch(type)
    {
        case 'visitor': this.new_record.is_visitor = 1; break;
        case 'staff': this.new_record.is_arena_staff = 1; break;
        case 'catering': this.new_record.is_catering = 1; break;
        case 'cleaning': this.new_record.is_cleaning = 1; break;
        case 'security': this.new_record.is_security = 1; break;
        case 'contractor': this.new_record.is_contractor = 1; break;
        case 'exhibitor': this.new_record.is_exhibitor = 1; break;
        case 'patient_name': this.is_patient_name = this.is_patient_name ? false : true; break
    }

  }

  isAdmin(){
      return this.authService.isAdmin()
  }

  openAddRecord(addModal:any ){
    this.model.open(addModal, {size: "xl"})
  }

  onChecked($event, type, old_value:any){

    this.new_record.is_arena_staff =  this.new_record.is_catering =
    this.new_record.is_contractor =  this.new_record.is_exhibitor =
    this.new_record.is_visitor = this.new_record.is_security = this.new_record.is_cleaning = 0

    switch(type){
        case 'visitor': this.new_record.is_visitor = old_value ? 0: 1; break;
        case 'staff': this.new_record.is_arena_staff = old_value ? 0: 1;break;
        case 'catering': this.new_record.is_catering = old_value ? 0: 1; break;
        case 'contractor': this.new_record.is_contractor = old_value ? 0: 1; break;
        case 'exhibitor': this.new_record.is_exhibitor = old_value ? 0: 1; break;
        case 'cleaning': this.new_record.is_cleaning = old_value ? 0: 1; break;
        case 'security': this.new_record.is_security = old_value ? 0: 1; break;
    }

    // console.log("Testing cahnges;;;;", type, this.new_record)
  }

  public message: string = "Are you sure you want to do this?"
  public selected

  onCreateRecord(){

    let $this = this

    let data:any = this.new_record;
    delete data["hospital"]
    delete data["equipment"]
    delete data["location"]
    delete data["vehicle"]
    delete data["user"]

    this.new_record.equipment = this.getCheckedValue(this.equipments)
    this.new_record.observation = this.getCheckedValue(this.observations)
    this.new_record.drug = this.getCheckedValue(this.drugs)
    this.new_record.procedure = this.getCheckedValue(this.procedures)

    this.new_record.is_ambulance_needed = this.selectedAmbulanceRequired.id
    this.new_record.vehicle_id = this.selectedVehicles.id
    this.new_record.hospital_id = this.selectedHospitals.id
    this.new_record.gender = this.selectedGender.name

    this.new_record.report_id = this.reportid

    console.log("Checking Logger::", this.new_record)

    this.requestService._post(this.pathService.CREATE_RECORDS, this.new_record, function(result, status){

      if(status){
          $this.onSuccessResponse.emit(true)
          $this.toastr.success("Patient successfully added to this report")
          $this.model.dismissAll()
       }
    })
  }

  getCheckedValue(records: any){

    let res = records.filter(x => {
        return x.quantity > 0 && x.checked
    })
    return res;
  }

  onCheckedDrug(event){
    if(!event.target){ return; }
    if(event.target.checked){
        this.drugs.filter(x => {
            if(x.id == event.target.value){
              if(event.target.checked){
                x.checked = true
            }else{
              x.checked = false}
            }
            return x
        })
      return;
    }
}

onCheckedEquipment(event){
    if(!event.target){ return; }
    if(event.target.checked){
      this.equipments.filter(x => {
          if(x.id == event.target.value){

            if(event.target.checked){
                x.checked = true
            }else{
              x.checked = false}
          }
          return x
      })
    return;
  }
}

onCheckedObservation(event){
    if(!event.target){ return; }
    if(event.target.checked){
      this.observations.filter(x => {
          if(x.id == event.target.value){
            if(event.target.checked){
              x.checked = true
            }else{
              x.checked = false}
          }
          return x
      })
    return;
  }
}

public ambulanceNeededStatus: Status[] = [
  {id: 1, name: "Yes"},
  {id: 0, name: "No"}
]
public selectedAmbulanceRequired: Status = new Status()

public listOfVehicles: Vehicle[] = new Array<Vehicle>()
public selectedVehicles: Vehicle = new Vehicle()
  findAssignedVehicles(jobid: number){

    this.reportAccessor.findVehicle({job_id: jobid}).subscribe(response => {

      console.log("----------->>>Vehicles:::::::", response, jobid)

      if(response.status == 'success'){

          //Reset Record back to empty array
          this.listOfVehicles = this.vehicles = []

          let vehicles = response.data;
          if(vehicles){

            let vehiclePlaceHolder = new Vehicle()
            vehiclePlaceHolder.id = 0;
            vehiclePlaceHolder.name = "Not Required"

            vehicles.forEach(element => {
                this.vehicles.push(element.vehicle)
            });
            

            this.vehicles.unshift(vehiclePlaceHolder)
            this.listOfVehicles = this.vehicles

            
            if(this.vehicles.length > 0){

              this.selectedVehicles = this.utilService.find(this.listOfVehicles, this.new_record.vehicle_id) || vehiclePlaceHolder

              console.log("check the list of vehicles:: ", this.new_record, this.listOfVehicles, this.selectedVehicles)
            }
          }
      }
    })
  }

  /** Post Treatment Report */

}

class Status{
  id!: number 
  name!: string
}

class Gender {
  id!: number 
  name!: string
}
import { Injectable } from '@angular/core';
import { CoreService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export abstract class CoreRequestService {

    constructor(protected appService: CoreService){}

    public FETCH_PATH       =  `store/fetch/products`;
    public ALL_PATH         =  `store/list/products`;
    public FIND_PATH        =  `store/find/products`;
    public UPDATE_PATH      =  `store/update/products`;
    public CREATE_PATH      = `store/create/products`;
    public DELETE_PATH      = `store/delete/products`;
    public PAGINATE_PATH      = ``;
    public SEARCH_PATH      = ``;
    public FILTER_PATH      = ``;
    public DOWNLOAD_PATH      = ``;

    fetch(id:any){
        return this.appService.get(this.FETCH_PATH+`/${id}`)
    }

    all(){
        return this.appService.get(this.ALL_PATH)
    }

    find(params: any){
        return this.appService.post(this.FIND_PATH, params)
    }

    update(params: any){
        return this.appService.post(this.UPDATE_PATH, params)
    }

    create(params: any){
        return this.appService.post(this.CREATE_PATH, params)
    }

    delete(id:any){
        return this.appService.get(this.DELETE_PATH+`/${id}`)
    }

    search(params: any){
        return this.appService.post(this.SEARCH_PATH, params)
    }

    filter(params: any){
      return this.appService.post(this.FILTER_PATH, params)
  }
  download(params: any){
        return this.appService.blob(this.DOWNLOAD_PATH, params)
    }

    fetchPaginate(params){
        return this.appService.get(this.PAGINATE_PATH+`?page=${params}`, params)
    }

    paginate(page, path: string = ''){
        
        let active_page = "";
        if(page){ active_page = page }
        return this.appService.get(path ? path+`${active_page}` : this.PAGINATE_PATH+`${active_page}`) // params equals ?page=1
    }

    findPaginate(page, params){
        
        return this.appService.post(this.FIND_PATH+`${page}`, params) // params equals ?page=1
    }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/auth.service';
import { first } from 'rxjs/operators';
import { CookieService } from '../shared/services/cookie.service';
import { Register, UserPayload } from '../shared/models/auth.models';
import { UserService } from 'src/app/shared/services/users/user.service';
import { v4 as uuid } from 'uuid';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public isloading: boolean = false;

  public newUser: Register = new Register();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private cookieService: CookieService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
  }

  get loginFormControl(){
    return this.loginForm.controls;
  }

  public isSignUpFailed:boolean = false;
  public isSignUpSuccess:boolean = false;

  public selectedType: Types = new Types()
  public types:Types[] = [
    {id:1, name:"Permanent Staff"},
    {id:2, name:"Temporary Staff"},
    {id:3, name:"Contract Staff"}
  ]

  submit(){

    let $this = this

    this.isSignUpFailed = false;
    this.isSignUpSuccess = false;

    this.newUser.type = this.selectedType.id
    this.newUser.code = this.route.snapshot.paramMap.get('index') || "";

    let payload = encodeURIComponent(window.btoa(JSON.stringify({staff:true,access: uuid(), means:"qrcode"})));

    // console.log("Encodeing ",payload)
    this.newUser.key = payload

      this.userService.register("register", this.newUser, function(result, status){

        if(status){
            $this.isSignUpSuccess = true;
            return;
        }
        $this.isSignUpFailed = true
    })

  }
}

class Types {
    id: number 
    name: string
}
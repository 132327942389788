import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { isNumber } from 'util';


@Component({
  selector: 'quill-text-editor',
  templateUrl: `./quill-editor.component.html`,
  styleUrls: ['./quill-editor.component.scss'],
})
export class QuillTextEditorComponent {


  @Input() label:string;
    @Input() required:boolean = true;
    @Input() type: string = "text";
    @Input() id: string = "";
    @Input() placeholder: string = "";
    @Input() value: any = "";
    @Input() class: string = "form-control";

    @Input() inputModel: string;
    @Output() onModelChanged = new EventEmitter<string>();

    constructor(){

    }

    logChange($event: any){

        if($event.html == undefined || $event.html == ""){
            return;
        }

        if(this.id == undefined || this.id == ""){
           return;
        }
        this.onModelChanged.emit($event.html)
    }
}

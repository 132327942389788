<div class="row">

  <div class="col-lg-12 mb-4">
    <uploader [fileType]="'Vehicle'" [buttonLabel]="'Upload Vehicle Licence or Certificates'" (onSuccessResponse)="reload()" [vehicleid]="vehicleid" [owner]="'vehicles'" [url]="'vehicles/profile/upload'"></uploader>
  </div>
  <div class="col-lg-12">
    <div class="demo-gallery">

      <ul id="lightgallery" class="list-unstyled row row-sm">

          <li class="col-sm-6 col-lg-4" *ngFor="let img of GalleryImage">
              <a href="javascript:void(0)" class="wd-100p">
                  <img class="img-responsive mb-4 wd-100p" [src]="img.modal.img"
                      alt="Thumb" (click)="openImageModalRowDescription(img)">
              </a>
          </li>
      </ul>
  </div>
  <ks-modal-gallery [id]="1" [modalImages]="GalleryImage"
      [plainGalleryConfig]="customPlainGalleryRowDescConfig"
      [currentImageConfig]="{downloadable : true}"
      [buttonsConfig]="buttonsConfigCustom">
  </ks-modal-gallery>

  </div>
</div>

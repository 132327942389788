import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobPath {

  constructor() { }

  public FETCH_JOB = `fetch/job/`;
  public UPDATE_JOB = `update/job`;
  public CREATE_ASSIGNED_JOB_USER = `create/job/assigned/record`;
  public FETCH_ASSIGNED_JOB_USER = `fetch/job/assigned/list/all`;
  public FIND_ASSIGNED_JOB_USER = `fetch/job/assigned/list`;
  public FIND_JOB_FILES = `fetch/job/record/files/`;
  public GET_ASSIGNED_JOB_USER = `fetch/job/assigned/`; //Append the ide to this route
  public JOBS_DELETE_RECORD = `delete/job/`

  
}

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserList, UserRecord } from 'src/app/shared/models/auth.models';
import { Briefing, Job, User } from 'src/app/shared/models/job.model';
import { DateService } from 'src/app/shared/services/date.service';
import { EventBriefingService, EventUsersService } from 'src/app/shared/services/jobs/event-manager.service';
import { UtilService } from 'src/app/shared/services/utilities/utils.service';
import { ValidatorService } from 'src/app/shared/services/utilities/validate.service';
import { briefingNote } from './briefing.data';

@Component({
    selector: 'briefing-sheet',
    templateUrl: `./briefing.component.html`,
    styleUrls: ['./briefing.component.scss'],
})

export class BriefingComponent {

    @Output() onSuccessResponse = new EventEmitter<any>()

    @Input() briefing!:Briefing;
    @Input() job!:Job;

    constructor(
      private model: NgbModal, 
      private validatorService: ValidatorService,
      private eventUsersService: EventUsersService,
      private eventBriefingService: EventBriefingService,
      private utilService: UtilService,
      private dateService: DateService,
      private toastr: ToastrService){

    }

    public payload: Briefing = new Briefing()
    public managers: User[] = new Array<User>()
    public selectedManager: User = new User()
    public selectedSecurity: User = new User()
    public selectedEsr: User = new User()
    public selectedManagerOnCall: User = new User()

    ngOnInit(): void {

      this.onLoadUsers()
    }


    public medsolManagers:  User[] = new Array<User>()
    public briefingSheetManagers: User[] = new Array<User>()
    public eventManagers: User[] = new Array<User>()
    open(addBriefingSheet:any){

        
        if(this.briefing&&this.briefing.id){
            this.payload = this.briefing;
            if(!this.payload.note){
                // this.payload.note = this.briefing_note;
            }
            // this.selectedManager = this.utilService.find(this.managers, this.payload.event_manager_id)
            // this.selectedManagerOnCall = this.utilService.find(this.managers, this.payload.manager_on_call_id)
            // this.selectedSecurity = this.utilService.find(this.managers, this.payload.security_manager_id)
            // this.selectedEsr = this.utilService.find(this.managers, this.payload.esr_id)   
        }

        /**
         * 
         */
        this.payload.note = briefingNote

        this.model.open(addBriefingSheet, {size: "lg"})
    }


    public managerTypes: ManagerType[] = [
        {id: 0, name: "Regular Manager"},
        {id: 1, name: "MedSol Manager"}
    ]
    public selectedManagerType: ManagerType = new ManagerType()
    openAddManager(addBriefingSheetManager){
        this.model.open(addBriefingSheetManager, {size: "lg"})
    }

    onLoadUsers(){

        this.eventUsersService.all().subscribe(response => {

            if(response.status == "success"){

              if(response.data){
                
                this.managers = response.data
                this.medsolManagers = this.managers.filter(x => {
                    return x._type == 1;
                })
                this.eventManagers = this.managers.filter(x => {
                    return x._type == 0;
                })
                this.briefingSheetManagers = this.managers.filter(x => {
                  return x._type == 0;
              })
              }
            }

            // let placeholder = new User()
            // placeholder.email = ""
            // placeholder.name = "Add New Manager";
            
            // this.eventManagers.unshift(placeholder)
        })
    }

    onModelChanged($event){
        this.payload.note = $event
    }

    saveBriefinfSheet(action: string){

      if(!this.payload.event_manager){
        this.toastr.info("Event Manager is required!"); return;
      }
      if(!this.payload.manager_on_call){
        this.toastr.info("Manager on Call is required!"); return;
      }

      if(!this.payload.security_manager){
        this.toastr.info("Security Manager is required!"); return;
      }

      if(!this.payload.esr){
        this.toastr.info("ESR is required!"); return;
      }

      this.payload.job_id = this.job.id
 
      let request;

      if(action == "update"){
          let body:any = {}
          body.target = {uuid: this.payload.uuid}
          body.data = this.payload
          request = this.eventBriefingService.update(body)
      }else{
        request = this.eventBriefingService.create(this.payload)
      }

      request.subscribe(response => {
        if(response.status == "success"){

            this.briefing = this.payload
            this.model.dismissAll()
            this.onSuccessResponse.emit(true)
            this.toastr.success(response.message); return;
        }
        this.toastr.error(response.message)
    })
    }

    public showNewForm: boolean = false
    onChangeEventManager($event: UserList){
        this.payload.event_manager = $event.name
    }

    onChangeSecurityManager($event: UserList){
        this.payload.security_manager = $event.name
    }

    onChangeEsrManager($event: UserList){
        this.payload.esr = $event.name
    }

    onChangeOnCallManager($event: UserList){
      this.payload.manager_on_call = $event.name
    }

    /**
     * Create managers
     */
    public payloadManager: User = new User()

    onCreateManager(){

        if(!this.validatorService.validate(this.payloadManager)){
          this.toastr.warning("Some required values are missing")
          return;
        }

        this.payloadManager._type = this.selectedManagerType.id || 0

        this.eventUsersService.create(this.payloadManager).subscribe(response => {

              if(response){
                if( response.status == "success" ){
                    this.toastr.success(response.message)
                    this.onLoadUsers()
                    return;
                }
                this.toastr.error(response.message)
              }
              this.toastr.error("Unknown Error occured")
        })
    }    
}


class ManagerType {
    id!: number;
    uuid?: string;
    name!: string 
}
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

  @Injectable({
    providedIn: 'root'
  })
  export class ChatObserver {
    private data = new BehaviorSubject<any | null>(null);

    sendData(data: any) {
       this.data.next(data);
    }
    getData(): Observable<any> {
       return this.data.asObservable()
    }
  }


<ng-template #transferAssetToUser let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Transfer Assets</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       
        <div class="form-group row">

            <div class="col-lg-12 mt-4">

                This item will be transferred to the care of the user or location you choose below. 
                Additionally, please be aware that each time you relocate an item, a history is generated for it.

            </div>

            <div class="col-lg-12 mt-4">
                <label>Transfer Type</label>
                <ng-select class="form-control p-0" [(ngModel)]="location" (ngModelChange)="onSelectChange($event)">
                    <ng-option *ngFor="let data of types" value="{{ data.name}}">{{ data.name }}</ng-option>
                </ng-select>
            </div>
  
            <div class="col-lg-12 mt-4" *ngIf="!isLocation">
                  <label>Select User</label>
                  <ng-select class="form-control p-0" [(ngModel)]="payload.user_id">
                      <ng-option *ngFor="let data of users" value="{{ data.id}}">{{ data.name }}</ng-option>
                  </ng-select>
            </div>

            <div class="col-lg-12 mt-4" *ngIf="isLocation">
                <label>Select Location</label>
                <ng-select class="form-control p-0" [(ngModel)]="payload.location_id">
                    <ng-option *ngFor="let data of locations" value="{{ data.id}}">{{ data.name }}</ng-option>
                </ng-select>
            </div>
             
          </div>
  
  
    </div>
    <div class="modal-footer">
        <button (click)="transfer()" class="btn ripple btn-primary" type="button">Assign Asset</button>
        <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
    </div>
  
  </ng-template>

  <button 
        id="transferAssetToUser" 
        class="btn btn-primary btn-sm" 
        data-toggle="modal" (click)="open(transferAssetToUser)" 
        data-target="#transferAssetToUser">Transfer</button>

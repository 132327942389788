<ng-template #addReportModal let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">New Stock</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

      <nav ngbNav #nav="ngbNav" class="main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
        <ng-container ngbNavItem>
            <a href="javascript:;" ngbNavLink>Equipment</a>
            <ng-template ngbNavContent>
                <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                    <div class="card-body p-0 border p-0 rounded-10">

                      <div class="row row-sm" >
                          <div class="col-md-12 col-lg-12">
                              <div class="card custom-card">

                                  <!-- Start Equipment -->
                                        <div class="row">

                                          <div class="col-lg-12 mt-4">
                                            <!-- <label>Equipment</label> -->

                                              <div style="max-height:400px; overflow-y:scroll;width:100%">

                                                <table class="table table-invoice table-bordered">

                                                    <thead>
                                                        <tr>
                                                          <th>Item</th>
                                                          <th>Cost</th>
                                                          <th>Quantity</th>
                                                          <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of equipments; let i = index" value="{{ data.id }}">
                                                            <td>{{ data.name }}</td>
                                                            <td>£{{ data.cost }}</td>
                                                            <td>

                                                            </td>
                                                            <td class="tx-4">
                                                                <input type="checkbox" (change)="onCheckedEquipment($event)" name="" value="{{ data.id }}" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                              </div>

                                          </div>

                                      </div>

                                      <!-- End Equipment -->

                              </div>
                          </div>
                      </div>

                    </div>

                </div>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a href="javascript:;" ngbNavLink>Observation</a>
          <ng-template ngbNavContent>
              <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                  <div class="card-body p-0 border p-0 rounded-10">

                    <div class="row row-sm" >
                        <div class="col-md-12 col-lg-12">
                            <div class="card custom-card">


                              <!-- Start Observation  -->

                              <div class="row">

                                <div class="col-lg-12 mt-4">
                                  <!-- <label>Equipment</label> -->

                                    <div style="max-height:400px; overflow-y:scroll;width:100%">

                                      <table class="table table-invoice table-bordered">

                                        <thead>
                                            <tr>
                                              <th>Item</th>
                                              <th>Cost</th>
                                              <th>Quantity</th>
                                              <th>Action</th>
                                            </tr>
                                        </thead>
                                          <tbody>
                                              <tr *ngFor="let data of observations; let i = index" value="{{ data.id }}">
                                                  <td>{{ data.name }}</td>
                                                  <td>£{{ data.cost }}</td>
                                                  <td></td>
                                                  <td class="tx-4">
                                                      <input type="checkbox" (change)="onCheckedObservation($event)" name="" value="{{ data.id }}" />
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>

                                    </div>

                                </div>

                            </div>

                              <!-- End Observation -->

                            </div>
                        </div>
                    </div>

                  </div>

              </div>
          </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a href="javascript:;" ngbNavLink>Drugs</a>
        <ng-template ngbNavContent>
            <div class="main-content-body tab-pane p-4 border-top-0 active" id="about">
                <div class="card-body p-0 border p-0 rounded-10">

                  <div class="row row-sm" >
                      <div class="col-md-12 col-lg-12">
                          <div class="card custom-card">

                            <!-- Start Drug -->

                            <div class="row">

                              <div class="col-lg-12 mt-4">
                                <!-- <label>Equipment</label> -->

                                  <div style="max-height:400px; overflow-y:scroll;width:100%">

                                    <table class="table table-invoice table-bordered">

                                        <thead>
                                            <tr>
                                              <th>Item</th>
                                              <th>Cost</th>
                                              <th>Quantity</th>
                                              <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of drugs; let i = index" value="{{ data.id }}">
                                                <td>{{ data.name }}</td>
                                                <td>£{{ data.cost }}</td>
                                                <td></td>
                                                <td class="tx-4">
                                                    <input type="checkbox" (change)="onCheckedDrug($event)" name="" value="{{ data.id }}" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                  </div>

                              </div>

                          </div>

                            <!-- End Drug -->

                          </div>
                      </div>
                  </div>

                </div>

            </div>
        </ng-template>
      </ng-container>
      </nav>

      <div class="row row-sm">
        <div class="col-lg-12 col-md-12">
            <div class="card  main-content-body-profile">
                <div class="tab-content">
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
        <button class="btn ripple btn-primary" type="button" (click)="onSubmit($event)">Add Stocks</button>
    </div>

</ng-template>

<button id="openModalButton" style="display: none;" data-toggle="modal" (click)="openPopUPModal(addReportModal)" data-target="#myModal">Open Modal</button>

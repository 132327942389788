import { Injectable } from '@angular/core';
import { CoreService } from './app.service';
import { CoreRequestService } from './request.service';


@Injectable({
  providedIn: 'root'
})
export class VehicleService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/vehicle`;
        this.ALL_PATH       = `vehicles/list/vehicle`;
        this.FIND_PATH      = `vehicles/find/vehicle`;
        this.UPDATE_PATH    = `vehicles/update/vehicle`;
        this.CREATE_PATH    = `vehicles/create/vehicle`;
        this.DELETE_PATH    = `vehicles/delete/vehicle`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleServiceHistoryService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/service-history`;
        this.ALL_PATH       = `vehicles/list/service-history`;
        this.FIND_PATH      = `vehicles/find/service-history`;
        this.UPDATE_PATH    = `vehicles/update/service-history`;
        this.CREATE_PATH    = `vehicles/create/service-history`;
        this.DELETE_PATH    = `vehicles/delete/service-history`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleUsersService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/users`;
        this.ALL_PATH       = `vehicles/list/users`;
        this.FIND_PATH      = `vehicles/find/users`;
        this.UPDATE_PATH    = `vehicles/update/users`;
        this.CREATE_PATH    = `vehicles/create/users`;
        this.DELETE_PATH    = `vehicles/delete/users`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleJobHistoryService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/job-vehicles`;
        this.ALL_PATH       = `vehicles/list/job-vehicles`;
        this.FIND_PATH      = `vehicles/find/job-vehicles`;
        this.UPDATE_PATH    = `vehicles/update/job-vehicles`;
        this.CREATE_PATH    = `vehicles/create/job-vehicles`;
        this.DELETE_PATH    = `vehicles/delete/job-vehicles`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleScheduleService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/schedules`;
        this.ALL_PATH       = `vehicles/list/schedules`;
        this.FIND_PATH      = `vehicles/find/schedules`;
        this.UPDATE_PATH    = `vehicles/update/schedules`;
        this.CREATE_PATH    = `vehicles/create/schedules`;
        this.DELETE_PATH    = `vehicles/delete/schedules`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleWorkshopService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/workshop`;
        this.ALL_PATH       = `vehicles/list/workshop`;
        this.FIND_PATH      = `vehicles/find/workshop`;
        this.UPDATE_PATH    = `vehicles/update/workshop`;
        this.CREATE_PATH    = `vehicles/create/workshop`;
        this.DELETE_PATH    = `vehicles/delete/workshop`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleDocumentService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `vehicles/fetch/docs`;
        this.ALL_PATH       = `vehicles/list/docs`;
        this.FIND_PATH      = `vehicles/find/docs`;
        this.UPDATE_PATH    = `vehicles/update/docs`;
        this.CREATE_PATH    = `vehicles/create/docs`;
        this.DELETE_PATH    = `vehicles/delete/docs`;
    }

    /**
     * All Other methods can come here
     */
}

@Injectable({
  providedIn: 'root'
})
export class VehicleImageService extends CoreRequestService {

  constructor( protected override appService: CoreService){
      super(appService)

      this.FETCH_PATH     = `vehicles/fetch/images`;
      this.ALL_PATH       = `vehicles/list/images`;
      this.FIND_PATH      = `vehicles/find/images`;
      this.UPDATE_PATH    = `vehicles/update/images`;
      this.CREATE_PATH    = `vehicles/create/images`;
      this.DELETE_PATH    = `vehicles/delete/images`;
  }

  /**
   * All Other methods can come here
   */
}

@Injectable({
  providedIn: 'root'
})
export class FaultService extends CoreRequestService {

  constructor( protected override appService: CoreService){
      super(appService)

      this.FETCH_PATH     = `vehicles/fetch/faults`;
      this.ALL_PATH       = `vehicles/list/faults`;
      this.FIND_PATH      = `vehicles/find/faults`;
      this.UPDATE_PATH    = `vehicles/update/faults`;
      this.CREATE_PATH    = `vehicles/create/faults`;
      this.DELETE_PATH    = `vehicles/delete/faults`;
  }

  /**
   * All Other methods can come here
   */
}
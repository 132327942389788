<!-- <div class="form-group select2-sm"> -->
    <label class="form-label" *ngIf="label!=''">{{ label }}</label>
    <ng-select style="border:1px solid #ccc"
        [items]="list"
        [disabled]="disabled"
        bindLabel="{{name || ''}}"
        [(ngModel)]="inputModel"
        (change)="updateSelected($event)"
        class="form-select-md select2 ps-0"
        placeholder="{{ placeholder }}">
    </ng-select>


<!-- </div> -->


<!-- <ng-option value="pl" selected>Poland</ng-option> -->

<div class="row justify-content-center" *ngIf="loading">
  <!-- <div class="col-lg-3"> -->
      <div style="float:left" class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span style="float:left;margin-top:7px;margin-left:10px">  Loading...</span>      
  <!-- </div> -->
</div>

<!-- <div class=" justify-content-center" *ngIf="loading">
        <button class="btn ripple btn-secondary" disabled type="button">
          <span aria-hidden="true"
              class="spinner-border spinner-border-sm" role="status"></span>
          <span class="sr-only">Loading...</span>
          Loading...
        </button>
</div> -->
import { UtilService } from './../../../../shared/services/utilities/utils.service';
import { AuthenticationService } from '../../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from '../../../../shared/services/request.service';
import { PostTreatmentService } from '../../../../shared/services/requests/core/treatment.service';
import { PathService } from '../../../../shared/services/path.service';
import { PostTreatment, ServiceRecord } from '../../../reporting/service-report/editor/model/report.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'treatment-note',
  templateUrl: './treatment-note.component.html',
  styleUrls: ['./treatment-note.component.scss']
})

/*
 *
 */
export class TreatmentNoteComponent implements OnInit {

  public modalValue: Subject<any> = new Subject();
  public changingValue: Subject<any> = new Subject();

  @Input() patient: ServiceRecord = new ServiceRecord();
  @Output() onSuccessResponse = new EventEmitter<any>();

 constructor(
  private model: NgbModal,
  private pathService: PathService,
  private requestService: RequestService,
  private postTreatmentService: PostTreatmentService,
  private utilService: UtilService,
  private toastr: ToastrService){

    // this.new_record = this.report
  }

  public status:Status [] = [
    {id: 1, name: "Yes"},
    {id: 2, name: "No"  },
  ]

  public usages:Status [] = [
    {id: 1, name: "None"},
    {id: 2, name: "High"  },
    {id: 3, name: "Low"  },
  ]

  public distcharges:Status [] = [
    {id: 1, name: "Event"},
    {id: 2, name: "Home"  },
    {id: 3, name: "Hospital"  },
  ]

  public categories:Status [] = [
    {id: 1, name: "Medical"},
    {id: 2, name: "Trauma"  },
  ]

  public types:Status [] = [
    {id: 1, name: "Major"},
    {id: 2, name: "Minor"  },
    {id: 3, name: "Triage"  },
  ]

  public selectedAlcoholRelated: Status = new Status()
  public selectedHeatRelated: Status = new Status()
  public selectedPatientOnDrug: Status = new Status()
  public selectedCallOutPatient: Status = new Status()
  public selectedDischargeTo: Status = new Status()
  public selectedOxygenUsage: Status = new Status()
  public selectedAEnthonoxUsage: Status = new Status()
  public selectedCategoryType: Status = new Status()
  public selectedCategory: Status = new Status()

  ngOnInit(): void {

    
  }

  public treatment: PostTreatment = new PostTreatment()
  open(UpdateOrCreatePosttreatmentRecord: any){

        this.treatment = this.patient.treatment;
        if(this.treatment){

            this.selectedAlcoholRelated = this.utilService.findBy(this.status, "name", this.treatment.is_alcohol) || new Status()
            this.selectedHeatRelated = this.utilService.findBy(this.status, "name", this.treatment.is_heat_related) || new Status()
            this.selectedPatientOnDrug = this.utilService.findBy(this.status, "name", this.treatment.is_drug) || new Status()
            this.selectedCallOutPatient = this.utilService.findBy(this.status, "name", this.treatment.callout) || new Status()
            this.selectedOxygenUsage = this.utilService.findBy(this.usages, "name", this.treatment.oxygen_use) || new Status()
            this.selectedAEnthonoxUsage = this.utilService.findBy(this.usages, "name", this.treatment.enthonox_use) || new Status()
            this.selectedCategory = this.utilService.findBy(this.categories, "name", this.treatment.category) || new Status()
            this.selectedCategoryType = this.utilService.findBy(this.types, "name", this.treatment.type) || new Status()

            if(this.treatment.to_event == 'Yes'){
                this.selectedDischargeTo = {id: 1, name: "Event"}
            }else if(this.treatment.to_home == "Yes"){
                this.selectedDischargeTo = {id: 1, name: "Home"}
            }else{
                this.selectedDischargeTo = {id: 1, name: "Hospital"}
            }
        }

        this.model.open(UpdateOrCreatePosttreatmentRecord, {size: "md"})
  }

  updateOrCreateTreatment(){

        let payload: PostTreatment = new PostTreatment()
        payload.patient_id = this.patient.id
        payload.is_alcohol =  this.selectedAlcoholRelated.name
        payload.is_heat_related = this.selectedHeatRelated.name
        payload.is_drug =  this.selectedPatientOnDrug.name
        payload.callout =  this.selectedCallOutPatient.name
        payload.to_event =  this.selectedDischargeTo.name
        payload.oxygen_use =  this.selectedOxygenUsage.name
        payload.enthonox_use =  this.selectedAEnthonoxUsage.name
        payload.type = this.selectedCategoryType.name
        payload.category = this.selectedCategory.name 

        payload.to_event = payload.to_home = payload.to_hospital = "No"

        if(this.selectedDischargeTo.name == 'Event'){
            payload.to_event = "Yes";
        }else if(this.selectedDischargeTo.name == "Home"){
            payload.to_home = "Yes";
        }else{
            payload.to_hospital = "Yes";
        }

        this.postTreatmentService.create(payload).subscribe(response => {
            if(response && response.status == "success"){
                this.toastr.success(response.message, "Saved!")
                return;
            }
            this.toastr.error(response.message, "Failed to Save!")
        })
    }
}



class Status{
    id!: number 
    name!: string
}


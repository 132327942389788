import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ToolImages, Tools } from "../../tools.data";
import { ToastrService } from "ngx-toastr";
import { ToolImageService, ToolsService } from "../../../../shared/services/requests/core/tools.service";
import { ImageUtilService } from "../../../../shared/services/requests/core/image.utils.service";
import { environment } from "../../../../../environments/environment.prod";


@Component({
  selector: 'tools-image',
  templateUrl: './tools-image.component.html',
  styleUrls: ['./tools-image.component.scss']
})
export class ToolImagesComponent implements OnInit {

  @Output() onSuccessResponse = new EventEmitter<boolean>();

  @Input() tool!: Tools;
  @Input() images!: ToolImages[]

  constructor(
    private toastr: ToastrService,
    private toolImageService: ToolImageService,
    private imageUtilService: ImageUtilService
    ) 
    {}


  public userid:any;

  ngOnInit(): void {

  }

  public mainImage: string;
  selectedImage(image: ToolImages){
      this.mainImage = image.name
  }

  deleteImage(image: ToolImages){

      this.toolImageService.delete(image.uuid).subscribe(response => {
          if(response.status == "success"){
              this.onSuccessResponse.emit(true)
              this.toastr.success(response.message || "Delete request successfully completed"); 
              return;
          }
          this.toastr.error(response.message || "Unable to complete delete request")
      })
  }

  getFirstImage(images: ToolImages[]){

      if(!images){
          return null;
      }

      if(this.mainImage){
          return this.mainImage;
      }
      if(images.length > 0){
        this.mainImage = images[0].name
        return this.mainImage
      }
      return null;
  }

  /**
  * Image Section
  */
 files: File[] = [];

 onSelect(event: any) {

  let $this = this;

  //  console.log(event);
  //  this.files.push(...event.addedFiles);
  event.addedFiles.forEach(element => {
      this.files.push(element)
  });

   this.imageUtilService.getFileContent(event.addedFiles[0], function(imageString){
        let payload = {
            image: imageString,
            radio_id: $this.tool.id,
            type: "rms_products",
            sizes: {
                small: {width: 70, height:70},
                medium: {width: 320, height:350},
                large: {width: 600, height:670}
            }
        }
        $this.toolImageService.create(payload).subscribe(response => {
            if(response.status == "success"){
                $this.onSuccessResponse.emit(true)
                $this.toastr.success("Upload successfully completed"); return;
            }
            $this.toastr.error("Unable to complete upload request")
        })
   })
 }

 onRemove(event: any) {
  // console.log(event);
   this.files.splice(this.files.indexOf(event), 1);
 }

 setImagePath(image: string){
  // console.log("Image Path::", image, this.images)
  return `${environment.apiUrl}/storage/${image}`;
}

}

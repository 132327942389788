<div class="card-body border">



  <div class="row row-sm">
      <div class="col-md-12 col-lg-12">
          <div class="card custom-card">
              <div class="card-header  border-bottom-0 pb-0">

                  <button (click)="add()" class="pull-right btn-danger btn-sm btn">Add Schedule</button>

                  <div>
                      <div class="d-flex">
                          <label class="main-content-label my-auto pt-2">Service Plan</label>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <div class="row table-filter">
                      <div class="col-lg-3">
                          <div class="show-entries">
                              <span>Show</span>
                              <select class="form-control">
                                  <option>10</option>
                              </select>
                              <span>entries</span>
                          </div>
                      </div>
                      <div class="col-lg-9 d-lg-flex">
                          <div class="d-flex ml-auto mt-4 mr-3 mt-lg-0">
                              <div class="filter-group">
                                  <input type="text" class="form-control" placeholder="search">
                              </div>
                              <button type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
                          </div>
                          <div class="d-flex mt-4 mt-lg-0">

                          </div>
                      </div>
                  </div>
                  <div class="table-responsive border">
                      <table class="table mb-0 text-nowrap text-md-nowrap">
                          <thead>
                              <tr>
                                  <th>Name</th>
                                  <th>Frequency</th>
                                  <th>Due Date</th>
                                  <th>Status</th>
                                  <th>Comment</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr class="border-bottom" *ngFor="let item of schedules">
                              <td *ngIf="item.editable" style="padding:30px" valign="top" colspan="7">
                                    <div class="row">

                                      <div class="col-lg-3">
                                        <div class="m-2">
                                            <label>Schedule Name</label>
                                            <input class="form-control" type="text" [(ngModel)]="payload.name" placeholder=""/>
                                        </div>
                                    </div>

                                        <div class="col-lg-3">
                                          <div class="m-2">
                                            <label>Frequency</label>
                                            <app-select
                                                [list]="frequencys"
                                                [id]="'frequencys'"
                                                [name]="'name'"
                                                [value]="selectedFrequency.name"
                                                [placeholder]="'Frequency'"
                                                [(inputModel)]="selectedFrequency">
                                            </app-select>

                                          </div>
                                        </div>
                                        <div class="col-lg-3">
                                          <div class="m-2">
                                            <label>Service Status</label>
                                            <app-select
                                                [list]="states"
                                                [id]="'states'"
                                                [name]="'name'"
                                                [value]="selectedState.name"
                                                [placeholder]="'States'"
                                                [(inputModel)]="selectedState">
                                            </app-select>
                                          </div>
                                        </div>

                                        <div class="col-lg-3">
                                            <div class="m-2">
                                                <label>Next Due Date</label>
                                                <input class="form-control" type="date" [(ngModel)]="payload.due_date" placeholder=""/>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">

                                        <div class="col-lg-12">
                                            <div class="m-2">
                                              <label>Comments</label>
                                              <textarea rows="10" class="form-control m-2"  type="text" [(ngModel)]="payload.comment" placeholder=""></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                      <div class="col-lg-6">
                                          <button class="btn btn-success btn-sm m-2" *ngIf="payload.id" (click)="update(item)">Update Record</button>
                                          <button class="btn btn-primary btn-sm m-2" *ngIf="!payload.id" (click)="save(item)">Save Record</button>
                                          <button class="btn btn-danger btn-sm m-2" (click)="cancel(item)">Cancel</button>
                                      </div>
                                    </div>
                              </td>
                              <td *ngIf="!item.editable">{{ item.name }}</td>
                              <td *ngIf="!item.editable">{{ getFrequency(item) }}</td>
                              <td *ngIf="!item.editable">{{ item.due_date }}</td>
                              <td *ngIf="!item.editable">
                                  <span *ngIf="item._state==0">Paused</span>
                                  <span *ngIf="item._state==1">Running</span>
                                  <span *ngIf="item._state==2">Blocked</span>
                              </td>
                              <td *ngIf="!item.editable">{{ item.comment }}</td>

                              <td *ngIf="!item.editable&&isSuperAdmin()">
                                <div class="button-list">
                                    <a href="javascript:;" class="btn"><i class="ti ti-eye"></i></a>
                                    <a href="javascript:;" (click)="edit(item)" class="btn"><i class="ti ti-pencil"></i></a>
                                    <!-- <idelete (onSuccess)="load()" [onDeleteChange]="onDeleteChange" [id]="data.id" [path]="delete_path"></idelete> -->
                                </div>
                            </td>
                          </tr>
                          </tbody>
                      </table>

                      <!-- <div class="mt-3">
                          <ngb-pagination id="page2" class="d-flex justify-content-end" [collectionSize]="user.availability.length" [maxSize]="moddal_available_config.maxSize" [pageSize]="moddal_available_config.maxSize" [(page)]="available_page" aria-label="Custom pagination">
                              <ng-template ngbPaginationPrevious>Prev</ng-template>
                              <ng-template ngbPaginationNext>Next</ng-template>
                          </ngb-pagination>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- End Row -->





</div>

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/auth.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'uploader',
  templateUrl: `./uploader.component.html`,
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {

    @Output() onSuccessResponse = new EventEmitter<boolean>();

    @Input() state: boolean = true
    @Input() label: string = ""
    @Input() buttonLabel: string = "Click Here to Upload"

    @Input() id: string = ""
    @Input() column: string = ""
    @Input() table: string = ""

    @Input() inputModel: string;
    @Output() inputModelChange = new EventEmitter<string>();

    public is_required: boolean = false;

    constructor(
      private requestService: RequestService,
      private modalService: NgbModal,
      private authService: AuthenticationService){

    }


    @Input() fileType: string = "Certificates"; //license
    @Input() url: string;
    @Input() owner: string = "users";
    @Input() vehicleid: number

    ngOnInit(): void {


      this.setDropZoneConfig()
    }

    public param = "";
    public initial: any = ""

    public config1: DropzoneConfigInterface;

    showUPloader(documentUploader: any){

        this.modalService.open(documentUploader)
    }

    setDropZoneConfig(){

        let $this = this;

        this.config1 = {
          clickable: true,
          maxFiles: 20,
          addRemoveLinks: true,
          autoReset: null,
          errorReset: null,
          cancelReset: null,
          paramName: "image",
          withCredentials: false,
          method: "POST",
          headers: {
            'Cache-Control': null,
            'X-Requested-With': null,
            'Authorization':`Bearer ${this.authService.token()}`
         } ,
         params: {
            user_id: this.authService.userid(),
            type: '',
            source: 'web',
            owner: ''
          },
          init: function() {

              this.on("success", function(file) {
                $this.onSuccessResponse.emit(true)
              });
           }
        };

        this.config1.params.type = this.fileType
        this.config1.params.owner = this.owner
        this.config1.params.vehicle_id = this.vehicleid
        this.config1.url = this.url ? `${environment.apiUrl}/api/${this.url}` :  `${environment.apiUrl}/api/user/profile/upload`

    }

}

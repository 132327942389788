<!-- Page Header -->
<div class="page-header">
    <div>
        <h2 class="main-content-title tx-24 mg-b-5">Calendar</h2>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Calendar</li>
        </ol>
    </div>
    <div class="d-flex">
        <div class="justify-content-center">

            <button *ngIf="access.read" type="button" class="btn btn-white btn-icon-text my-2 mr-2">
                <i class="fe fe-filter mr-2"></i> Search
            </button>
        </div>
    </div>
</div>
<!-- End Page Header -->

<!-- *ngIf="access.read" -->

<!-- row -->
<div class="row row-sm">
    <div class="col-sm-12 col-md-12">
        <div class="card custom-card">
            <div class="card-body">

                <div class="row">

                    <div class="col-xl-12 " id="external-events-1">

                        <div class="row text-center">
                            <div class="col-md-4 text-left">
                                <div class="btn-group">
                                    <div class="btn btn-primary pl-3 pr-3" mwlCalendarPreviousView [view]="view"
                                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                    </div>
                                    <div class="btn btn-primary pl-3 pr-3" mwlCalendarNextView [view]="view"
                                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </div>
                                    <div class="btn btn-primary ml-2" mwlCalendarToday [(viewDate)]="viewDate">
                                        Today
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <!-- <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3> -->
                            </div>
                            <div class="col-md-4 text-right">
                                <div class="btn-group">
                                    <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                                        [class.active]="view === CalendarView.Month">
                                        Month
                                    </div>
                                    <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                                        [class.active]="view === CalendarView.Week">
                                        Week
                                    </div>
                                    <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                                        [class.active]="view === CalendarView.Day">
                                        Day
                                    </div>
                                    <button class="btn btn-raised btn-primary float-right" (click)="addEvent()">
                                        Add Event
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">

                            </div>
                        </div>
                        <br />

                        <div [ngSwitch]="view">
                            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                                [events]="events" [activeDayIsOpen]="activeDayIsOpen" [refresh]="refresh"
                                (dayClicked)="dayClicked($event.day)"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                (eventTimesChanged)="eventTimesChanged($event)">
                            </mwl-calendar-month-view>
                            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
                                [events]="events" [refresh]="refresh" [snapDraggedEvents]="false"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                (eventTimesChanged)="eventDropped($event)">
                            </mwl-calendar-week-view>
                            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate"
                                [events]="events" [refresh]="refresh" [snapDraggedEvents]="false"
                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                (eventTimesChanged)="eventDropped($event)">
                            </mwl-calendar-day-view>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- /row -->

<app-alert [status]="changingValue"></app-alert>

<ng-template #modalAssign let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Assign User to Jobs</h5>
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form action="#" class="form form-horizontal">
            <div class="form-body">

              <table class="table table-hover m-b-0 transcations mt-2">
                <tbody>

                    <tr *ngFor="let data of modalAssignData?.jobs; let i = index">

                        <td>
                            <div class="d-flex align-middle ml-3">
                                <div class="d-inline-block">
                                    <h6 class="mb-1">{{ data?.title }}</h6>
                                    <!-- <p class="mb-0 tx-13 text-muted">Location: {{ data?.location }}</p> -->
                                </div>
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="d-inline-block">
                                <a class="btn btn-sm btn-primary" (click)="close()" href="/jobs/{{data?.jobid}}/view">
                                    View Event Details
                                </a>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

            </div>
        </form>
    </div>

</ng-template>

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

    getDate(){

        let d:any = moment().format()
       // console.log("Checking Moment", d)
    }

    getDaysInBetween(date1){

      return moment.utc(date1).fromNow();
    }

    compareDate2Date(first_date?:string, second_date?: string){

        if( (new Date(first_date).getTime() > new Date(second_date).getTime()))
        {
            return true;
        }
        return false;
    }

    compare(first_date?:string, second_date?: string){

      // console.log(first_date, second_date)

        let first =  new Date();
         if( first_date != undefined){
            first = new Date(first_date);
         }

         let second =  new Date();
         if( second_date != undefined){
            second = new Date(second_date);
         }

        //  console.log("Compare Dates:: ", "First::", first, "Second::", second, first_date, second_date)

        if( (new Date(first).getTime() > new Date(second).getTime()))
        {
            return true;
        }
        return false;
    }

    isSameDay(date1: Date, date2: Date): boolean {
        return (
          date1.getUTCFullYear() === date2.getUTCFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      }

    getSeparatorBody(date: Date): string {
        if (this.isSameDay(date, new Date())) {
          return 'Today';
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (this.isSameDay(date, yesterday)) {
          return 'Yesterday';
        }
        return date.toISOString();
      }

      getDateOrTime(date: Date): string {
        if (this.isSameDay(date, new Date())) {
          return this.formatDate(date)
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        if (this.isSameDay(date, yesterday)) {
          return this.formatDate(yesterday)
        }
        return date.toISOString();
      }

  formatDate(date) {
        var d = new Date(date);
        var day:any = d.getDate();
        var month:any = d.getMonth() + 1; // Months are zero-indexed
        var year = d.getFullYear();
    
        // Ensure day and month are two digits
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
    
        return `${day}/${month}/${year}`;
    }

    formatDateTime(dateTimeString) {
      var dateTime = new Date(dateTimeString);
      var day:any = dateTime.getDate();
      var month:any = dateTime.getMonth() + 1; // Months are zero-indexed
      var year:any = dateTime.getFullYear();
      var hours:any = dateTime.getHours();
      var minutes:any = dateTime.getMinutes();
  
      // Ensure day, month, hours, and minutes are two digits
      if (day < 10) {
          day = '0' + day;
      }
      if (month < 10) {
          month = '0' + month;
      }
      if (hours < 10) {
          hours = '0' + hours;
      }
      if (minutes < 10) {
          minutes = '0' + minutes;
      }
  
      return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
    

      formatDateToUK(date) {

        let _date = new Date(date)
        var day:any = _date.getDate();
        var month:any = _date.getMonth() + 1; // Adjust month to 1-indexed for UK format
        var year = _date.getFullYear();
      
        // Add leading zeros if necessary
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
      
        return day + '/' + month + '/' + year;
      }

      formatTime(date) {

        var d = new Date(`${date.replace(" ", "T")}Z`);
        var hh = d.getHours();
        var mm = d.getMinutes();
        var ss = d.getSeconds();
        var dd = "am";
        var h = hh;
        if (h >= 12) {
          h = hh - 12;
          dd = "pm";
        }
        if (h == 0) {
          h = 12;
        }
        let m = mm < 10 ? "0" + mm : mm;

        let s = ss < 10 ? "0" + ss : ss;

        return `${h}:${m} ${dd}`
      }

      formatAMPM(date: Date) {

          var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';

          hours = hours % 12;

          let hhs = hours ? hours : 12; // the hour '0' should be '12'
          let mms = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hhs + ':' + mms + ' ' + ampm;

          return strTime;

      }

      formatEnUSWithOption(_date){

        if(typeof _date === 'object'){
          var date  = new Date(_date);
        }else if(typeof _date === 'string'){
          var date  = new Date(`${_date.toString().replace(" ", "T")}Z`);
            if(_date.indexOf("0Z") > -1){
              date  = new Date(_date);
            }
        }

        let isValidDate = date instanceof Date && !isNaN(date.valueOf());

        // // console.log(isValidDate, "tsting")
        // if(! isValidDate ){
        //       return "";
        // }

        var options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:"numeric", minute: "numeric" };

        if(typeof date === undefined ){
            return _date;
        }
        try{
            return  date.toLocaleDateString("en-GB", options);
        }catch(e){}
      }


      timeSince(date) {

        if (typeof date !== 'object') {
          date = new Date(date);
        }

        let now:any = new Date();

        var seconds = Math.floor((now - date) / 1000);
        var intervalType;

        var interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
          intervalType = 'year';
        } else {
          interval = Math.floor(seconds / 2592000);
          if (interval >= 1) {
            intervalType = 'month';
          } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
              intervalType = 'day';
            } else {
              interval = Math.floor(seconds / 3600);
              if (interval >= 1) {
                intervalType = "hour";
              } else {
                interval = Math.floor(seconds / 60);
                if (interval >= 1) {
                  intervalType = "minute";
                } else {
                  interval = seconds;
                  intervalType = "second";
                }
              }
            }
          }
        }

        if (interval > 1 || interval === 0) {
          intervalType += 's';
        }

        return interval + ' ' + intervalType;
      };

       datediff(first, second) {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second-first)/(1000*60*60*24));
    }
}




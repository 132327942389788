import { UserList } from "../../shared/models/auth.models"
import { Editable } from "../equipment/vehicles/details/vehicle.data"
import { JobLocation } from '../reporting/service-report/editor/model/report.model';

export class DropDownMenuList{
    type: string
    name: string
}

export let dropMenuList: DropDownMenuList[] = [
  {name: "Equipment", type:"equipment"},
  {name: "Observation", type:"observation"},
  {name: "Procedure", type:"procedure"},
  {name: "Drugs", type:"drug"}
]


export class Tools extends Editable{
  id!:number 
  uuid!:string 
  number!:string 
  item!:string 
  location!: string 
  place!: JobLocation
  location_id!: number
  description!: string
  serial_number!:string 
  radio_number!:string 
  assigned_to_user_id!:number 
  note!:string 
  _state!:number 
  status!:number 
  created_at!:string 
  updated_at!:string 
  current!: ToolHistory
  histories!: ToolHistory[]
  qrcode!: string
  images!: ToolImages[]
}

export class ToolHistory extends Editable {
  id!:number 
  uuid!:string 
  radio_id!:number 
  user_id!:number 
  location_id!: number
  note!:string 
  _state!:number 
  status!:number 
  created_at!:string 
  updated_at!:string 
  user!: UserList
  place!: JobLocation
  target?: string
}

export class ToolImages extends Editable {

  id!: number
  uuid!: string
  vehicle_id!: number
  name!: string
  type!: string
  status!: number
  created_at!: string
  updated_at!: string
  editable: boolean = false

}

export class ToolLocation
{
    id!: number;
    uuid!: string
    identifier!: string
    name!: string;
    address!: string;
    type!: string;
    status!: number;
    created_at!: string;
    updated_at!: string;
    tools!: Tools[];
    scans!: ToolHistory[]
}
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { RequestService } from 'src/app/shared/services/request.service';
import { PayloadService } from 'src/app/shared/services/requests/payload.service';

@Component({
  selector: 'idelete',
  templateUrl: `./idelete.component.html`,
  styleUrls: ['./idelete.component.scss'],
})
export class IDeleteComponent {

    @Input() id: string = ""
    @Input() path: string = ""
    
    @Input() onDeleteChange: Subject<any>

    @Output() changeModelChange = new EventEmitter<string>();
    
    @Output() onSuccess = new EventEmitter<boolean>();

    constructor(
      private requestService: RequestService,
      private payloadService: PayloadService,
      private toastr: ToastrService,)
      {

    }

    public message: string = "Are you sure you want to delete this?"
 
    onDelete(data:any){

        console.log("is delete clicked....")
        this.onDeleteChange.next({id: this.id, status:"warning", message: this.message})
    }

  delete($event: any){

    let $this = this

    if($event.state && $event.id){
        // send delete Request to server
        this.payloadService.request(`${this.path}${$event.id}`, null, this.payloadService.TYPE_GET, function(response, status){

          if(status){
              $this.onSuccess.emit(true)
              $this.toastr.success("Request Successfully completed", "User record was deleted")
          }
        })
    }
}

}
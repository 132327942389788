import { PreloaderComponent } from './loader/loader.component';
import { VehicleFileComponent } from './../equipment/vehicles/details/files/files.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { VehicleImagesComponent } from './../equipment/vehicles/details/profile/image.component';
import { VehicleServiceHistoryComponent } from './../equipment/vehicles/details/services/service.component';
import { VehicleMessageComponent } from './../equipment/vehicles/details/message/message.component';
import { VehicleScheduleComponent } from './../equipment/vehicles/details/schedules/schedule.component';
import { VehicleAssignedComponent } from './../equipment/vehicles/details/assigned/assigned.component';
import { SelectComponent } from './select/select.component';
import { AddStockComponent } from './modals/add-stock/add-stock.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { EmbeddedCalendarComponent } from './calendar/embedded-calendar.component';
import { UpdatePatientComponent } from './modals/update-patient/update-patient.component';
import { SendReportComponent } from './modals/send-report/send-report.component';
import { AddPatientComponent } from './modals/add-patient/add-patient.component';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { QuillModule } from 'ngx-quill';
import { AccessNoticeComponent } from './access/access-notice.component';
import { AlertComponent } from './alerts/alert.component';
import { InlineChatComponent } from './chat/inline-chat.component';
import { ParticipantsComponent } from './chat/invite/chat-invite.component';
import { ImagePickerComponent } from './element/picker/image-picker.component';
import { TextEditorComponent } from './element/text-editor/text-editor.component';
import { TextInputComponent } from './element/text/text-input.component';
import { IDeleteComponent } from './idelete/idelete.component';
import { IEditorComponent } from './ieditor/ieditor.component';
import { InputComponent } from './input/input.component';
import { AddReportComponent } from './modals/add-report/add-report.component';
import { TextAreaComponent } from './textarea/textarea.component';
import { UploaderComponent } from './uploader/uploader.component';
import { IDateEditorComponent } from './ieditor/idate-editor/idate-editor.component';
import { IDateNormalEditorComponent } from './ieditor/idate-normal/idate-normal.component';
import { MiniProfileComponent } from './mini-profile/mini-profile.component';
import { CopyChannelComponent } from './chat/log/copy-chat.component';
import { AssignSeniorStaffComponent } from './modals/assign-senior-staff/assign-senior-staff.component';
import { ToolImagesComponent } from '../tools-assets/tools/tools-image/tools-image.component';
import { ToolsAndAssetsListComponent } from '../tools-assets/tools/tools-list/tools-list.component';
import { BriefingComponent } from './briefing/briefing.component';
import { QuillTextEditorComponent } from './ieditor/editor/quill-editor.component';
import { TransferAssetsComponent } from './transfer-assets/assets-transfer.component';
import { DropDownFormComponent } from './dropdown-form/dropdown-form.component';
import { TreatmentNoteComponent } from './modals/treatment-note/treatment-note.component';


const components = [
    TextAreaComponent,
    InputComponent,
    AlertComponent,
    TextInputComponent,
    TextEditorComponent,
    ImagePickerComponent,
    AccessNoticeComponent,
    IEditorComponent,
    IDeleteComponent,
    InlineChatComponent,
    ParticipantsComponent,
    AddReportComponent,
    UploaderComponent,
    IDateEditorComponent,
    MiniProfileComponent,
    CopyChannelComponent,
    AddPatientComponent,
    IDateNormalEditorComponent,
    AssignSeniorStaffComponent,
    SendReportComponent,
    UpdatePatientComponent,
    EmbeddedCalendarComponent,
    AddStockComponent,
    SelectComponent,

    //Vehicle Components
    VehicleAssignedComponent,
    VehicleScheduleComponent,
    VehicleMessageComponent,
    VehicleServiceHistoryComponent,
    VehicleImagesComponent,
    VehicleFileComponent,
    PreloaderComponent,

    //Tools Components
    ToolImagesComponent,
    ToolsAndAssetsListComponent,
        
    //JobDetails
    BriefingComponent,
    QuillTextEditorComponent,
    TransferAssetsComponent,
    DropDownFormComponent,
    TreatmentNoteComponent
]

@NgModule({
   imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        NgxDropzoneModule,
        DropzoneModule,
        SweetAlert2Module,
        NgbPaginationModule,
        QuillModule.forRoot(),
        GalleryModule.forRoot(),
        CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory
        }),
        NgbModule
   ],
    declarations:  components,
    exports:  components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})

export class ComponentModule { }


import { Injectable } from '@angular/core';
import { CoreService } from './../requests/core/app.service';
import { CoreRequestService } from './../requests/core/request.service';

@Injectable({
    providedIn: 'root'
  })
  export class EventBriefingService extends CoreRequestService {
  
      constructor( protected override appService: CoreService){
          super(appService)
  
          this.FETCH_PATH     = `job/briefing/fetch/briefing`;
          this.ALL_PATH       = `job/briefing/list/briefing`;
          this.FIND_PATH      = `job/briefing/find/briefing`;
          this.UPDATE_PATH    = `job/briefing/update/briefing`;
          this.CREATE_PATH    = `job/briefing/create/briefing`;
          this.DELETE_PATH    = `job/briefing/delete/briefing`;
      }
  
      /**
       * All Other methods can come here
       */
  }

  @Injectable({
    providedIn: 'root'
  })
  export class EventUsersService extends CoreRequestService {
  
      constructor( protected override appService: CoreService){
          super(appService)
  
          this.FETCH_PATH     = `job/briefing/fetch/managers`;
          this.ALL_PATH       = `job/briefing/list/managers`;
          this.FIND_PATH      = `job/briefing/find/managers`;
          this.UPDATE_PATH    = `job/briefing/update/managers`;
          this.CREATE_PATH    = `job/briefing/create/managers`;
          this.DELETE_PATH    = `job/briefing/delete/managers`;
      }
  
      /**
       * All Other methods can come here
       */
  }
import { VehicleImages } from './../vehicle.data';
import { Vehicle } from './../../../../reporting/service-report/editor/model/report.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VehicleImageService } from '../../../../../shared/services/requests/core/vehicles.service';
import { ImageUtilService } from '../../../../../shared/services/requests/core/image.utils.service';
import { environment } from '../../../../../../environments/environment.prod';

@Component({
  selector: 'vehicle-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class VehicleImagesComponent implements OnInit {

  @Output() onSuccessResponse = new EventEmitter<boolean>();

  @Input() vehicle!: Vehicle;
  @Input() images!: VehicleImages[]

  constructor(
    private toastr: ToastrService,
    private vehicleImageService: VehicleImageService,
    private imageUtilService: ImageUtilService
    ) {

   }


  public userid:any;

  ngOnInit(): void {

  }

  public mainImage: string;
  selectedImage(image: VehicleImages){
      this.mainImage = image.name
  }

  deleteImage(image: VehicleImages){

      this.vehicleImageService.delete(image.uuid).subscribe(response => {
          if(response.status == "success"){
              this.onSuccessResponse.emit(true)
              this.toastr.success(response.message || "Delete request successfully completed"); 
              return;
          }
          this.toastr.error(response.message || "Unable to complete delete request")
      })
  }

  getFirstImage(images: VehicleImages[]){

      if(this.mainImage){
          return this.mainImage;
      }
      if(images.length > 0){
        this.mainImage = images[0].name
        return this.mainImage
      }
      return null;
  }

  /**
  * Image Section
  */
 files: File[] = [];

 onSelect(event: any) {

  let $this = this;

  //  console.log(event);
  //  this.files.push(...event.addedFiles);
  event.addedFiles.forEach(element => {
      this.files.push(element)
  });

   this.imageUtilService.getFileContent(event.addedFiles[0], function(imageString){
        let payload = {
            image: imageString,
            vehicle_id: $this.vehicle.id,
            type: "rms_products",
            sizes: {
                small: {width: 70, height:70},
                medium: {width: 320, height:350},
                large: {width: 600, height:670}
            }
        }
        $this.vehicleImageService.create(payload).subscribe(response => {
            if(response.status == "success"){
                $this.onSuccessResponse.emit(true)
                $this.toastr.success("Upload successfully completed"); return;
            }
            $this.toastr.error("Unable to complete upload request")
        })
   })
 }

 onRemove(event: any) {
  // console.log(event);
   this.files.splice(this.files.indexOf(event), 1);
 }

 setImagePath(image: string){
  // console.log("Image Path::", image, this.images)
  return `${environment.apiUrl}/storage/${image}`;
}

}

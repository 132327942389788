<label>  
    <div ngbDropdown class="main-dropdown-form-demo">
       <div class="d-flex justify-content-between">
            
            <button class="btn btn-sm ripple btn-main-primary infoBox" ngbDropdownToggle> 
                <i class="icon ion-ios-arrow-down"></i>
            </button>
            <span>{{ label }}</span>
       </div>
        <div ngbDropdownMenu>

            <h6 class="dropdown-title text-center mb-4">{{ label }}</h6>
            
            <div class="form-group">
                
                <app-select
                    [list]="list"
                    [id]="'managers'"
                    [name]="'name'"
                    [value]="selectedManager.name"
                    [placeholder]="'Event Manager'"
                    (inputModelChange)="onSelect($event)"
                    [(inputModel)]="selectedManager">
                </app-select>
                
            </div>
            <!-- <button class="btn ripple btn-primary btn-block">Add Manager</button> -->
        </div>
    </div>
</label>
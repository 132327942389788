<ng-template #openSendReportModalButton let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Send Service Report</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <h6>Service Report </h6> -->
        <!-- Select2 -->
  
        <div class="row">

            <div class="col-lg-7">
                <div class="form-group row">
  
                    <div class="col-lg-12">
                        <div class="m-2">
                            
                            <app-select
                                [list]="users"
                                [id]="'users'"
                                [name]="'name'"
                                [value]="selectedUser.name"
                                [placeholder]="'Users'"
                                [(inputModel)]="selectedUser"
                                (inputModelChange)="addSelectedUser()">
                            </app-select>
        
                        </div>
                    </div> 
        
                      <div class="col-lg-12 mt-4">
                          <app-input
                            [type]="'text'"
                            [class]="'form-control'"
                            [label]="'Emails to Copy'"
                            [id]="'name'"
                            [value]="mail.emails"
                            [placeholder]="'Email seprated by comma'"
                            [(inputModel)]="mail.emails">
                          </app-input>
                      </div>
        
                      <div class="col-lg-12 mt-4">

                        <div style="max-height: 300px;overflow-y: scroll;">
                            <quill-text-editor 
                                [label]="'Email Message'"
                                [id]="'message'"
                                [value]="mail.message"
                                (onModelChanged)="onModelChanged($event)"
                                [(inputModel)]="mail.message">
                            </quill-text-editor>
                        </div>
        
                      </div>
        
              </div>
            </div>
            <div class="col-lg-5">
                <h6 class="modal-title mb-2">Selected Receipients</h6>
                <div class="border" style="height: 400px;overflow-y: scroll;">
                    
                    <div class="main-list-item p-3 d-flex justify-content-between" *ngFor="let user of selectedUsers">
                        <h6>{{ user.name }}</h6>
                        <button (click)="deleteSelectedUser(user.id)" class="btn btn-sm pull-right"><i class="fa fa-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>
  
    </div>
    <div class="modal-footer">
        <button (click)="sendReport($event)" class="btn ripple btn-primary" type="button">Send Report</button>
        <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
    </div>
  
  </ng-template>
  
  <button id="openSendReportModalButton" [hidden]="true" data-toggle="modal" (click)="openPopUPModal(openSendReportModalButton)" data-target="#myModal">Open Modal</button>
  
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'preloader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class PreloaderComponent {

    @Input() loading: boolean = false;

    constructor(){ }

    ngOnInit(){ }


}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

    constructor(){}

    search(array: any, search: string){

      if(!search){ return [] }

      return array.filter(x => {
          let filter = JSON.stringify(x).toLowerCase().includes(search.toLowerCase())
          if(filter){
              return x;
          }
      })
    }


}

<ng-template #UpdateOrCreatePosttreatmentRecord let-modal>
    <!-- Select2 modal -->
    <div class="modal-header">
        <h6 class="modal-title">Patient Treatment Note</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- Select2 -->
  
        <div class="form-group row">

            <div class="col-lg-6 mt-4">

                <label>Alcohol Related</label>
                <app-select
                    [list]="status"
                    [id]="'alcohold'"
                    [name]="'name'"
                    [value]="selectedAlcoholRelated.name"
                    [placeholder]="'Alcohol Related'"
                    [(inputModel)]="selectedAlcoholRelated">
                </app-select>

            </div>

            <div class="col-lg-6 mt-4">

                <label>Heat Related</label>
                <app-select
                    [list]="status"
                    [id]="'heat'"
                    [name]="'name'"
                    [value]="selectedHeatRelated.name"
                    [placeholder]="'Ambulance Needed'"
                    [(inputModel)]="selectedHeatRelated">
                </app-select>

            </div>

            <div class="col-lg-6 mt-4">

                <label>Patient on Drug</label>
                <app-select
                    [list]="status"
                    [id]="'patient'"
                    [name]="'name'"
                    [value]="selectedPatientOnDrug.name"
                    [placeholder]="'Patient on Drug'"
                    [(inputModel)]="selectedPatientOnDrug">
                </app-select>

            </div>

            <div class="col-lg-6 mt-4">

                <label>Call out Patient</label>
                <app-select
                    [list]="status"
                    [id]="'callout'"
                    [name]="'name'"
                    [value]="selectedCallOutPatient.name"
                    [placeholder]="'Call out Patient'"
                    [(inputModel)]="selectedCallOutPatient">
                </app-select>

            </div>

            <div class="col-lg-4 mt-4">

                <label>Discharge to</label>
                <app-select
                    [list]="status"
                    [id]="'distcharge'"
                    [name]="'name'"
                    [value]="selectedDischargeTo.name"
                    [placeholder]="'Discharge to'"
                    [(inputModel)]="selectedDischargeTo">
                </app-select>

            </div>

            <div class="col-lg-4 mt-4">

                <label>Oxygen Usage</label>
                <app-select
                    [list]="usages"
                    [id]="'oxygen'"
                    [name]="'name'"
                    [value]="selectedOxygenUsage.name"
                    [placeholder]="'Oxygen Usage'"
                    [(inputModel)]="selectedOxygenUsage">
                </app-select>

            </div>

            <div class="col-lg-4 mt-4">

                <label>Enthonox Usage</label>
                <app-select
                    [list]="usages"
                    [id]="'enthonox'"
                    [name]="'name'"
                    [value]="selectedAEnthonoxUsage.name"
                    [placeholder]="'Enthonox Usage'"
                    [(inputModel)]="selectedAEnthonoxUsage">
                </app-select>

            </div>

            <div class="col-lg-6 mt-4">

                <label>Select Category</label>
                <app-select
                    [list]="categories"
                    [id]="'vehicles'"
                    [name]="'name'"
                    [value]="selectedCategory.name"
                    [placeholder]="'Medical or Trauma'"
                    [(inputModel)]="selectedCategory">
                </app-select>

            </div>

            <div class="col-lg-6 mt-4">

                <label>Select Category Type</label>
                <app-select
                    [list]="types"
                    [id]="'category'"
                    [name]="'name'"
                    [value]="selectedCategoryType.name"
                    [placeholder]="'Select Category'"
                    [(inputModel)]="selectedCategoryType">
                </app-select>

            </div>
        </div>
    </div>

<div class="modal-footer">
    <button (click)="updateOrCreateTreatment()" class="btn ripple btn-primary" type="button">Save Record</button>
    <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
</div>

</ng-template>

<button id="openUpdateTreatmentPatientModalButton" 
    [hidden]="false" 
    class="btn btn-info btn-sm mr-2" data-toggle="modal"
    (click)="open(UpdateOrCreatePosttreatmentRecord)" 
    data-target="#myModal">
    Update Patient Record
</button>

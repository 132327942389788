import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

    find(data:any, id:number ){

      let value = data.filter(x => {
        return x.id == id
      })
      return value[0] || data[0];
    }

    findBy(data:any, key:any, value:any ){

      let result = data.filter(x => {

        console.log("Checking Status:", x[key], key, value)

        return x[key] == value
      })
      return result[0];
    }

    takeFirstNthWords(inputString, length: number = 30) {
      // Step 1: Split the string into words using whitespace as the delimiter
      const wordsList = inputString.split(/\s+/);
    
      // Step 2: Take the first 30 words from the array
      const first30Words = wordsList.slice(0, length);
    
      // Step 3: Reconstruct the first 30 words into a new string
      const resultString = first30Words.join(' ');
    
      return resultString;
    }

    wordCount(inputString) {
      // Step 1: Split the string into words using whitespace as the delimiter
      const wordsList = inputString.split(/\s+/);

      return wordsList.length;
    }
}




import { UserList } from "../../../../shared/models/auth.models"
import { Job } from "../../../../shared/models/job.model"

export class Editable {
    editable:boolean =  false
}

export class AssignedVehicle extends Editable
{
    id!: number
    uuid!: string
  	job_id!: number
    vehicle_id!: number
    description!: string
    assigned_by_id!: number
    _state!: number
    created_at!: string
    updated_at!: string
    job!: Job
}

export class ServiceHistory extends Editable{

    id!: number
    uuid!: string
    vehicle_id!: number
    servicer_id!: number
    services_required!: string
    services_performed!: string
    workshop_id!: number
    due_date!: string
    _state!: number
    status!: number
    created_at!: string
    updated_at!: string
}

export class VehicleDocuments extends Editable{

    id!:number
    uuid!: string
    vehicle_id!: number
    name!: string
    type!: string
    status!: number
    created_at!: string
    updated_at!: string

}

export class VehicleSchedule extends Editable{

  id!: number
  uuid!: string
  vehicle_id!: number
  name!: string
  comment!: string
  frequency_id!: number
  due_date!: string
  _state!: number
  status!: number
  created_at!: string
  updated_at!: string

}

export class VehicleImages extends Editable{

  id!: number
  uuid!: string
  vehicle_id!: number
  name!: string
  type!: string
  status!: number
  created_at!: string
  updated_at!: string
  editable: boolean = false

}

export class VehicleUsers {
    id: number
    client_id: number
    name: string
    phone: string
    identifier: string
    is_super_admin: number
    status: number
    created_at: string
    updated_at: string
}

export class VehicleWorkshop extends Editable{

    id!: number
    uuid!: string
    name!: string
    address!: string
    email!: string
    phone!: string
    owner_id!: number
    status!: number
    created_at!: string
    updated_at!: string
    services!: ServiceHistory[]
    owner: VehicleUsers
}

export class States{
  id!: number
  name!: string
}

export  let states: States[] = [
    {id: 0, name: "Pending"},
    {id: 1, name: "Completed"},
    {id: 2, name: "In-Progress"},
    {id: 3, name: "Cancelled"}
]

export  let scheduleStates: States[] = [
  {id: 0, name: "Paused"},
  {id: 1, name: "Running"},
]


export  let frequencies: States[] = [
  {id: 0, name: "Daily"},
  {id: 1, name: "Weekly"},
  {id: 2, name: "Monthly"},
  {id: 3, name: "Yearly"}
]

import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { CoreService } from '../../../../app/shared/services/requests/core/app.service';
import { CoreRequestService } from '../../../../app/shared/services/requests/core/request.service';


@Injectable({
  providedIn: 'root'
})
export class UserService extends AppService {

    all(callback){
        
        this.get(`user/list`, {}).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

       },error => callback(error, false));
    }

    availability(callback){
        
        this.get(`fetch/job/availability/list/all`, {}).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

      },error => callback(error, false));
    }

  register(target, options, callback){
        
      this.post(`auth/user/${target}`, options).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

     },error => callback(error, false));
  }

    send(target, options, callback){
        
      this.post(`auth/admin/${target}`, options).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

     },error => callback(error, false));
  }

  participants(options){
        
    return this.post(`user/participant`, options);
  }

  users(options){
        
    return this.get(`user/list`, options);
  }

  managers(options){
    return this.get(`user/managers`, options);
  }

  addChatParticipant(options){

    return this.post(`user/participant/create`, options);
  }

  removeChatParticipant(options){

    return this.post(`user/participant/delete`, options);
  }

  delete(id, callback){
        
    this.get(`auth/admin/delete/${id}`, {}).subscribe(response => {

      let status =  false
      if(response.status == "success"){
          status = true
      }
      callback(response.data, status);

  },error => callback(error, false));
}

}

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)
    }

    /**
     * All Other methods can come here
     */
    updateRecord(params){
        return this.appService.post('user/update/profile', params);
    }
  
}
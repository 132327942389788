import { ToastrService } from 'ngx-toastr';
import { ReportAccessor } from './../../../../shared/services/jobs/report.accessor';
import { Drug, Equipment, Observation } from './../../../reporting/service-report/editor/model/report.model';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss']
})

/*
 *
 */
export class AddStockComponent implements OnInit, AfterViewInit {

  public modalValue: Subject<any> = new Subject();

  @Input() jobid: any;
  @Output() onSuccessResponse = new EventEmitter<any>();

  public equipments: Array<Equipment> = new Array<Equipment>()
  public observations: Array<Observation> = new Array<Observation>()
  public drugs: Array<Drug> = new Array<Drug>()

    constructor(
      private modal: NgbModal, private reportAccessor: ReportAccessor, private toastr: ToastrService){
    }

    ngAfterViewInit(){

    }

    ngOnInit(): void {

      this.reportAccessor.equipments$.subscribe(response => {
          this.equipments = response;
      })

      this.reportAccessor.drugs$.subscribe(response => {
          this.drugs = response;
      })

      this.reportAccessor.observation$.subscribe(response => {
          this.observations = response;
      })

      this.reportAccessor.getEquipment();
      this.reportAccessor.getDrug();
      this.reportAccessor.getObservation();

    }

    open(){

        let doc:any = document.querySelector("#openModalButton")
        doc.click();
    }

    openPopUPModal(addReportModal){

        this.modal.open(addReportModal, {size: 'lg'})
    }

    onSubmit(){

        const body: any = {}
        body.drugs = this.selectedDrug
        body.observations = this.selectedObservation
        body.equipment = this.selectedEquipment
        body.job_id = this.jobid

        this.reportAccessor.setStock(body).subscribe(respoonse => {

          if(respoonse.status == "success"){
              this.onSuccessResponse.emit(true)
              this.modal.dismissAll()
          }
        }, error => this.toastr.error("Unable create stock request"))

    }

    private selectedEquipment  = []
  onCheckedEquipment(event) {

    // set child to true if checked
    if(event.target.checked){

        this.selectedEquipment.push(event.target.value)
    } else {
      //set child model to false
      this.selectedEquipment = this.selectedEquipment.filter(function(item){
        return item !== event.target.value;
      });

    }
  }

  private selectedObservation  = []
  onCheckedObservation(event) {

    // set child to true if checked
    if(event.target.checked){

        this.selectedObservation.push(event.target.value)
    } else {
      //set child model to false
      this.selectedObservation = this.selectedObservation.filter(function(item){
        return item !== event.target.value;
      });

    }
  }

  private selectedDrug  = []
  onCheckedDrug(event) {

    // set child to true if checked
    if(event.target.checked){

        this.selectedDrug.push(event.target.value)
    } else {
      //set child model to false
      this.selectedDrug = this.selectedDrug.filter(function(item){
        return item !== event.target.value;
      });

    }
  }

}

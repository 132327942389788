export let reportMessage = `

<p>Dear [Recipient's Name],</p>
<p></p>
<p>I hope this email finds you well. We are pleased to inform you that the comprehensive report for [Event Name] is now available and ready for your review.</p>
<p></p>
<p>We encourage you to take some time to review the report at your convenience. Your feedback and insights are invaluable to us, and they will help us improve and tailor our future events to better meet your expectations.</p>
<p></p>
<p>If you have any questions, require further information, or would like to discuss any aspects of the report, please do not hesitate to reach out to us. Our team is here to assist you and provide any additional clarification you may need.</p>
<p></p>
<p>Thank you,
<p></p>
<p></p>
Best regards,
[Your Name]<br/>
[Your Title]<br/>
[Your Company/Organization]<br/>
[Your Email Address]<br/>
[Your Phone Number]
`
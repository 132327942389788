import { UtilService } from './../../../../../shared/services/utilities/utils.service';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { GalleryService } from '@ks89/angular-modal-gallery';
import { AuthenticationService } from '../../../../../shared/services/auth.service';
import { VehicleService } from '../../../../../shared/services/requests/core/vehicles.service';
import { Vehicle } from '../../../../reporting/service-report/editor/model/report.model';
import { InlineChatComponent } from '../../../../common/chat/inline-chat.component';
import { ChatObserver } from '../../../../common/chat/chat.observer';

@Component({
  selector: 'vehicle-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class VehicleMessageComponent implements OnInit {

  @ViewChild(InlineChatComponent) chat: InlineChatComponent;

  @Input() uuid: string

  public onDeleteChange: Subject<any> = new Subject();

  public changingValue: Subject<any> = new Subject();

  public page = 1
  public config: any = {
      currentPage: 1,
      pageSize: 6,
      maxSize: 5
  };

  public is_update: boolean = false

  public status: string = "success"

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    private utilService: UtilService,
    private vehicleService: VehicleService,
    private chatObserver: ChatObserver,
    ) {



   }


  public userid:any;

  ngOnInit(): void {




  }

  ngAfterViewInit(){

    this.vehicleService.find({uuid: this.uuid}).subscribe(response => {

        if(response.status == 'success'){

            this.vehicle = response.data[0]

            //console.log("Chat Object", this.chat, this.vehicle)

            this.name = this.vehicle.name
            this.channel = `VCH:${this.vehicle.id}`
            this.target = this.vehicle.identifier

            this.elt_name = `ELT Log For: ${this.vehicle.name}`
            this.elt_channel = `ELT-VCH:${this.vehicle.id}`
            this.elt_target = `ELT:${this.vehicle.identifier}`
        }
    })

  }

  public vehicle: Vehicle;

  public name: string = "Vehicle Chat";
  public channel: string;
  public target: string;

  public elt_name: string = "Vehicle ELT Log Chat";
  public elt_channel: string;
  public elt_target: string
}

<div class="card-body border">



  <div class="row row-sm">
      <div class="col-md-12 col-lg-12">
          <div class="card custom-card">
              <div class="card-header  border-bottom-0 pb-0">

                  <!-- <button (click)="add()" class="pull-right btn-danger btn-sm btn">Add Schedule</button> -->

                  <div>
                      <div class="d-flex">
                          <label class="main-content-label my-auto pt-2"> Job History</label>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <div class="row table-filter">
                      <div class="col-lg-3">
                          <div class="show-entries">
                              <span>Show</span>
                              <select class="form-control">
                                  <option>10</option>
                              </select>
                              <span>entries</span>
                          </div>
                      </div>
                      <div class="col-lg-9 d-lg-flex">
                          <div class="d-flex ml-auto mt-4 mr-3 mt-lg-0">
                              <div class="filter-group">
                                  <input type="text" class="form-control" placeholder="search">
                              </div>
                              <button type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
                          </div>
                          <div class="d-flex mt-4 mt-lg-0">

                          </div>
                      </div>
                  </div>
                  <div class="table-responsive border">
                      <table class="table mb-0 text-nowrap text-md-nowrap">
                          <thead>
                              <tr>
                                  <th>Job</th>
                                  <th>Start Date</th>
                                  <th>End Date</th>
                                  <th>Location</th>
                                  <th>Status</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr class="border-bottom" *ngFor="let item of assigns">

                              <td *ngIf="!item.editable&&item.job">{{ item.job.name }}</td>
                              <td *ngIf="!item.editable&&item.job">{{ formatDateToUK(item.job.start_date)  }}</td>
                              <td *ngIf="!item.editable&&item.job">{{ formatDateToUK(item.job.end_date)  }}</td>
                              <td *ngIf="!item.editable&&item.job">{{ item.job.location  }}</td>
                              <td *ngIf="!item.editable">
                                  <span *ngIf="item._state==0">Paused</span>
                                  <span *ngIf="item._state==1">Running</span>
                                  <span *ngIf="item._state==2">Blocked</span>
                              </td>
                              <td *ngIf="!item.editable&&isSuperAdmin()">
                                <div class="button-list">
                                    <!-- <idelete (onSuccess)="load()" [onDeleteChange]="onDeleteChange" [id]="data.id" [path]="delete_path"></idelete> -->
                                </div>
                            </td>
                          </tr>
                          </tbody>
                      </table>

                      <!-- <div class="mt-3">
                          <ngb-pagination id="page2" class="d-flex justify-content-end" [collectionSize]="user.availability.length" [maxSize]="moddal_available_config.maxSize" [pageSize]="moddal_available_config.maxSize" [(page)]="available_page" aria-label="Custom pagination">
                              <ng-template ngbPaginationPrevious>Prev</ng-template>
                              <ng-template ngbPaginationNext>Next</ng-template>
                          </ngb-pagination>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- End Row -->





</div>

<ng-template #UpdatePatientRecord let-modal>
  <!-- Select2 modal -->
  <div class="modal-header">
      <h6 class="modal-title">Service Items</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <!-- Select2 -->

      <div class="form-group row">

          <div class="col-lg-12 mt-4 text-center">

              <label class="custom-switch">
                  <input type="checkbox" checked (click)="onSwitchToggled('patient_name')" name="custom-switch-checkbox" class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description" style="max-width: 350px ;">Click the button to disable patient name if you don't know the name of the name and initials of this patient</span>
              </label>
          </div>

          <div class="col-lg-12">

            <div class="row">

                <div class="col-lg-4 mt-4">

                    <label>Gender</label>
                    <!-- <ng-select class="form-control p-0" [(ngModel)]="new_record.gender">
                        <ng-option *ngFor="let data of genders; let i = index" value="{{ data.name }}">{{ data.name }}</ng-option>
                    </ng-select> -->

                    <app-select
                        [list]="genders"
                        [id]="'genders'"
                        [name]="'name'"
                        [value]="selectedGender.name"
                        [placeholder]="'Gender'"
                        [(inputModel)]="selectedGender">
                    </app-select>

                </div>

                <div class="col-lg-8 mt-4" *ngIf="is_patient_name">
                    <label>
                        Patient Name
                    </label>
                    <app-input
                        [type]="'text'"
                        [class]="'form-control'"
                        [id]="'patient'"
                        [value]="new_record.patient"
                        [placeholder]="'Staff Name'"
                        [(inputModel)]="new_record.patient">
                    </app-input>

                </div>

            </div>

          </div>


          <div class="col-lg-12 mt-4">

            <div class="row">

                <div class="col-lg-4 mt-4">
                  <label>Hospital</label>
                  <app-select
                    [list]="listOfHospitals"
                    [id]="'hospitals'"
                    [name]="'name'"
                    [value]="selectedHospitals.name"
                    [placeholder]="'Hospital'"
                    [(inputModel)]="selectedHospitals">
                </app-select>
              </div>


              <div class="col-lg-4 mt-4">
                <label>Time In</label>
                <app-input
                    [type]="'datetime-local'"
                    [class]="'form-control'"
                    [id]="'time_in'"
                    [value]="new_record.home"
                    [placeholder]="'Select Time'"
                    [(inputModel)]="new_record.time_in">
                </app-input>
            </div>

            <div class="col-lg-4 mt-4">

                <label>Do you need an Ambulance?</label>
                <app-select
                    [list]="ambulanceNeededStatus"
                    [id]="'ambulance'"
                    [name]="'name'"
                    [value]="selectedAmbulanceRequired.name"
                    [placeholder]="'Ambulance Needed'"
                    [(inputModel)]="selectedAmbulanceRequired">
                </app-select>

            </div>

            <div class="col-lg-4 mt-4">

                <label>Select Ambulance, if Ambulance needed is Yes</label>
                <app-select
                    [list]="listOfVehicles"
                    [id]="'vehicles'"
                    [name]="'name'"
                    [value]="selectedVehicles.name ? selectedVehicles.name : ''"
                    [placeholder]="'Ambulance Needed'"
                    [(inputModel)]="selectedVehicles">
                </app-select>

            </div>

            <div class="col-lg-4 mt-4">
              <label>Patient's SHE Number</label>
              <app-input
                  [type]="'text'"
                  [class]="'form-control'"
                  [id]="'she_number'"
                  [value]="new_record.she_number"
                  [placeholder]="'Enter She Number'"
                  [(inputModel)]="new_record.she_number">
              </app-input>
            </div>

              <!-- End Row -->
            </div>

          </div>


          <div class="col-lg-6 mt-4">
            <h4>Equipment</h4>

              <div style="max-height:400px; overflow-y:scroll;width:100%">

                <table class="table table-invoice table-bordered">

                  <tbody>
                    <tr *ngFor="let data of equipments; let i = index" value="{{ data.id }}">
                        <td><label for="{{ data.name }}">{{ data.name }}</label></td>
                        <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                        <td>
                          <app-input
                                [type]="'number'"
                                [class]="'form-control'"
                                [id]="'quantity'"
                                [placeholder]="'0'"
                                (inputModelChange)="onCheckedEquipment(data.id)"
                                [(inputModel)]="data.quantity">
                            </app-input>
                        </td>
                        <td class="tx-4">
                            <input id="{{ data.name }}" type="checkbox" [checked]="data.checked" (change)="data.checked = !data.checked" name="n0" value="{{ data.id }}" />
                        </td>
                    </tr>
                </tbody>

                </table>

              </div>

          </div>

        <div class="col-lg-6 mt-4">
            <h4>Observation</h4>

              <div style="max-height:400px; overflow-y:scroll;width:100%">

                <table class="table table-invoice table-bordered">

                  <tbody>
                      <tr *ngFor="let data of observations; let i = index" value="{{ data.id }}">
                          <td><label for="{{ data.name }}">{{ data.name }}</label></td>
                          <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                          <td>
                            <app-input
                                  [type]="'number'"
                                  [class]="'form-control'"
                                  [id]="'quantity'"
                                  [value]="data.quantity"
                                  [placeholder]="'0'"
                                  (inputModelChange)="onCheckedObservation(data.id)"
                                  [(inputModel)]="data.quantity">
                              </app-input>
                          </td>
                          <td class="tx-4">
                              <input id="{{ data.name }}" type="checkbox" [checked]="data.checked" (change)="data.checked = !data.checked" name="n0" value="{{ data.id }}" />
                              <!-- <input id="{{ data.name }}" type="checkbox" checked *ngIf="inArray(data)" (change)="onCheckedObservation($event)" name="n0" value="{{ data.id }}" />
                              <input type="checkbox" *ngIf="!inArray(data)" (change)="onCheckedObservation($event)" name="n1" value="{{ data.id }}" /> -->
                          </td>
                      </tr>
                  </tbody>

                </table>

              </div>

        </div>

        <div class="col-lg-6 mt-4">
            <h4>Drugs</h4>

              <div style="max-height:400px; overflow-y:scroll;width:100%">

                <table class="table table-invoice table-bordered">

                  <tbody>
                      <tr *ngFor="let data of drugs; let i = index" value="{{ data.id }}">
                          <td><label for="{{ data.name }}">{{ data.name }}</label></td>
                          <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                          <td>
                            <app-input
                                  [type]="'number'"
                                  [class]="'form-control'"
                                  [id]="'quantity'"
                                  [value]="data.quantity"
                                  [placeholder]="'0'"
                                  (inputModelChange)="onCheckedDrug(data.id)"
                                  [(inputModel)]="data.quantity">
                              </app-input>
                          </td>
                          <td class="tx-4">
                              <input id="{{ data.name }}" type="checkbox" [checked]="data.checked" (change)="data.checked = !data.checked" name="n0" value="{{ data.id }}" />
                              <!-- <input id="{{ data.name }}" type="checkbox" checked *ngIf="inArray(data)" (change)="onCheckedObservation($event)" name="n0" value="{{ data.id }}" />
                              <input type="checkbox" *ngIf="!inArray(data)" (change)="onCheckedObservation($event)" name="n1" value="{{ data.id }}" /> -->
                          </td>
                      </tr>
                  </tbody>

                </table>

              </div>

        </div>


          <div class="col-lg-6 mt-4">
            <h4>Procedure</h4>

              <div style="max-height:400px; overflow-y:scroll;width:100%">

                <table class="table table-invoice table-bordered">

                  <tbody>
                    <tr *ngFor="let data of procedures; let i = index" value="{{ data.id }}">
                        <td><label for="{{ data.name }}">{{ data.name }}</label></td>
                        <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                        <td>
                              <app-input
                                    [type]="'number'"
                                    [class]="'form-control'"
                                    [id]="'quantity'"
                                    [value]="data.quantity"
                                    [placeholder]="'0'"
                                    (inputModelChange)="onCheckedProcedure(data.id)"
                                    [(inputModel)]="data.quantity">
                                </app-input>
                        </td>
                        <td class="tx-4">
                            <input id="{{ data.name }}" type="checkbox" [checked]="data.checked" (change)="data.checked = !data.checked" name="n0" value="{{ data.id }}" />
                            <!-- <input id="{{ data.name }}" type="checkbox" checked *ngIf="inArray(data)" (change)="onCheckedDrug($event)" name="n0" value="{{ data.id }}" />
                            <input type="checkbox" *ngIf="!inArray(data)" (change)="onCheckedDrug($event)" name="n1" value="{{ data.id }}" /> -->
                        </td>
                    </tr>
                </tbody>

                </table>

              </div>

          </div>


          <div class="col-lg-6">

            <div class="row">

                <div class="col-lg-12 mt-4">

                    <label>Treatment Descriptions</label>
                    <app-textarea
                        [type]="'text'"
                        [class]="'form-control'"
                        [id]="'illness_or_treatment_description'"
                        [value]="new_record.illness_or_treatment_description"
                        [placeholder]="'Enter Description'"
                        [(inputModel)]="new_record.illness_or_treatment_description">
                    </app-textarea>
                </div>
            </div>

        </div>


          <div class="col-lg-6">

              <div class="row">

                  <div class="col-lg-6 mt-4">
                      <label class="custom-switch">
                          <input type="radio" id="is_exhibitor" (click)="onSwitchToggled('exhibitor')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Exhibitor</span>
                      </label>
                  </div>
                  <div class="col-lg-6 mt-4">
                      <label class="custom-switch">
                          <input type="radio" id="is_contractor" (click)="onSwitchToggled('contractor')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Contractor</span>
                      </label>
                  </div>

                  <div class="col-lg-6 mt-4">
                      <label class="custom-switch">
                          <input type="radio" id="is_staff" (click)="onSwitchToggled('staff')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Arena Staff</span>
                      </label>
                  </div>

                  <div class="col-lg-6 mt-4">
                      <label class="custom-switch">
                          <input type="radio" id="is_visitor" (click)="onSwitchToggled('visitor')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Visitor</span>
                      </label>
                  </div>

                  <div class="col-lg-6 mt-4">
                      <label class="custom-switch">
                          <input type="radio" id="is_catering" (click)="onSwitchToggled('catering')" name="custom-switch-checkbox" class="custom-switch-input">
                          <span class="custom-switch-indicator"></span>
                          <span class="custom-switch-description">Catering</span>
                      </label>
                  </div>

                  <div class="col-lg-6 mt-4">
                    <label class="custom-switch">
                        <input type="radio" id="is_security" (click)="onSwitchToggled('security')" name="custom-switch-checkbox" class="custom-switch-input">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">Security</span>
                    </label>
                </div>

                <div class="col-lg-6 mt-4">
                    <label class="custom-switch">
                        <input type="radio" id="is_cleaning" (click)="onSwitchToggled('cleaning')" name="custom-switch-checkbox" class="custom-switch-input">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">Cleaning</span>
                    </label>
                </div>

              </div>
          </div>



        </div>

  </div>
  <div class="modal-footer">
      <button (click)="onCreateRecord()" class="btn ripple btn-primary" type="button">Update Patient Record</button>
      <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>

</ng-template>

<button id="openUpdatePatientModalButton" [hidden]="true" class="btn btn-info btn-sm mr-2" data-toggle="modal" (click)="openAddRecord(UpdatePatientRecord)" data-target="#myModal">Update Patient Record</button>

<div class="card-body border">



  <div class="row row-sm">
      <div class="col-md-12 col-lg-12">
          <div class="card custom-card">
              <div class="card-header  border-bottom-0 pb-0">

                  <button *ngIf="!isWorkshop()" (click)="add()" class="pull-right btn-danger btn-sm btn">Add Service</button>

                  <div>
                      <div class="d-flex">
                          <label class="main-content-label my-auto pt-2">Service History</label>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <div class="row table-filter">
                      <div class="col-lg-3">
                          <div class="show-entries">
                              <span>Show</span>
                              <select class="form-control">
                                  <option>10</option>
                              </select>
                              <span>entries</span>
                          </div>
                      </div>
                      <div class="col-lg-9 d-lg-flex">
                          <div class="d-flex ml-auto mt-4 mr-3 mt-lg-0">
                              <div class="filter-group">
                                  <input type="text" class="form-control" placeholder="search">
                              </div>
                              <button type="button" class="btn btn-primary"><i class="fa fa-search"></i></button>
                          </div>
                          <div class="d-flex mt-4 mt-lg-0">

                          </div>
                      </div>
                  </div>
                  <div class="table-responsive border">
                      <table class="table mb-0 text-nowrap text-md-nowrap">
                          <thead>
                              <tr>
                                  <th>Serviced By</th>
                                  <th>Workshop</th>
                                  <th>Due Date</th>
                                  <th>State</th>
                                  <th>Service Required</th>
                                  <th>Status</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr class="border-bottom" *ngFor="let item of services">
                              <td *ngIf="item.editable" style="padding:30px" valign="top" colspan="7">
                                    <div class="row">

                                        <!-- <div class="col-lg-3">
                                          <div class="m-2">
                                            <label>Serviced By </label>
                                            <app-select
                                                [list]="users"
                                                [id]="'users'"
                                                [name]="'name'"
                                                [value]="selectedUser.name"
                                                [placeholder]="'Workshops'"
                                                [(inputModel)]="selectedUser">
                                            </app-select>

                                          </div>
                                        </div> -->
                                        <div class="col-lg-6">
                                          <div class="m-2">
                                            <label>Service Workshop</label>
                                            <app-select
                                                [list]="workshops"
                                                [id]="'workshops'"
                                                [name]="'name'"
                                                [value]="selectedWorkshop.name"
                                                [placeholder]="'Workshops'"
                                                [(inputModel)]="selectedWorkshop">
                                            </app-select>

                                          </div>
                                        </div>
                                        <div class="col-lg-3">
                                          <div class="m-2">
                                            <label>Service Status</label>
                                            <app-select
                                                [list]="states"
                                                [id]="'states'"
                                                [name]="'name'"
                                                [value]="selectedState.name"
                                                [placeholder]="'States'"
                                                [(inputModel)]="selectedState">
                                            </app-select>
                                          </div>
                                        </div>

                                        <div class="col-lg-3">
                                            <div class="m-2">
                                                <label>Service Due Date</label>
                                                <input class="form-control" type="date" [(ngModel)]="payload.due_date" placeholder=""/>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="m-2">
                                              <label>Service Required</label>
                                              <textarea rows="10" class="form-control m-2"  type="text" [(ngModel)]="payload.services_required" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                          <div class="m-2">
                                            <label>Service Performed</label>
                                            <textarea rows="10" class="form-control m-2"  type="text" [(ngModel)]="payload.services_performed" placeholder=""></textarea>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-lg-6">
                                          <button class="btn btn-success btn-sm m-2" *ngIf="payload.id" (click)="update()">Update Record</button>
                                          <button class="btn btn-primary btn-sm m-2" *ngIf="!payload.id" (click)="save()">Save Record</button>
                                          <button class="btn btn-danger btn-sm m-2" (click)="cancel(item)">Cancel</button>
                                      </div>
                                    </div>
                              </td>
                              <td *ngIf="!item.editable&&item.serviced_by">{{ item.serviced_by.name }}</td>
                              <td *ngIf="!item.editable&&item.workshop">{{ item.workshop.name }}</td>
                              <td *ngIf="!item.editable">{{ formatUkDate(item.due_date) }}</td>
                              <td *ngIf="!item.editable">
                                  <span *ngIf="item._state==0">Pending</span>
                                  <span *ngIf="item._state==1">Serviced</span>
                                  <span *ngIf="item._state==2">Cancelled</span>
                              </td>
                              <td *ngIf="!item.editable">{{ item.services_required }}</td>
                              <td *ngIf="!item.editable">{{ item.status ? 'Active' : 'Closed' }}</td>

                              <td *ngIf="!item.editable&&isSuperAdmin()">
                                <div class="button-list">
                                    <a href="javascript:;" class="btn"><i class="ti ti-eye"></i></a>
                                    <a href="javascript:;" (click)="edit(item)" class="btn"><i class="ti ti-pencil"></i></a>
                                    <!-- <idelete (onSuccess)="load()" [onDeleteChange]="onDeleteChange" [id]="data.id" [path]="delete_path"></idelete> -->
                                </div>
                            </td>
                          </tr>
                          </tbody>
                      </table>

                      <!-- <div class="mt-3">
                          <ngb-pagination id="page2" class="d-flex justify-content-end" [collectionSize]="user.availability.length" [maxSize]="moddal_available_config.maxSize" [pageSize]="moddal_available_config.maxSize" [(page)]="available_page" aria-label="Custom pagination">
                              <ng-template ngbPaginationPrevious>Prev</ng-template>
                              <ng-template ngbPaginationNext>Next</ng-template>
                          </ngb-pagination>
                      </div> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!-- End Row -->





</div>

<ng-template #addRecord let-modal>
  <!-- Select2 modal -->
  <div class="modal-header">
      <h6 class="modal-title">Service Items</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <!-- Select2 -->

      <div class="form-group row">

          <div class="col-lg-12 mt-4 text-center">

              <label class="custom-switch">
                  <input type="checkbox" checked (click)="onSwitchToggled('patient_name')" name="custom-switch-checkbox" class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description" style="max-width: 350px ;">Click the button to disable patient name if you don't know the name of the name and initials of this patient</span>
              </label>
          </div>

          <div class="col-lg-12">

            <div class="row">

                <div class="col-lg-4 mt-4">

                    <label>Gender</label>
                    <app-select
                        [list]="genders"
                        [id]="'genders'"
                        [name]="'name'"
                        [value]="selectedGender ? selectedGender.name : ''"
                        [placeholder]="'Gender'"
                        [(inputModel)]="selectedGender">
                    </app-select>

                </div>

                <div class="col-lg-8 mt-4" *ngIf="is_patient_name">
                    <label>
                        Patient Name
                    </label>
                    <app-input
                        [type]="'text'"
                        [class]="'form-control'"
                        [id]="'patient'"
                        [value]="new_record.patient"
                        [placeholder]="'Staff Name'"
                        [(inputModel)]="new_record.patient">
                    </app-input>

                </div>

                <div class="col-lg-4 mt-4">
                    <label>Hospital</label>
                    <app-select
                        [list]="hospitals"
                        [id]="'hospitals'"
                        [name]="'name'"
                        [value]="selectedHospitals ? selectedHospitals.name : ''"
                        [placeholder]="'Hospital'"
                        [(inputModel)]="selectedHospitals">
                    </app-select>
                </div>

                <!-- <div class="col-lg-4 mt-4">
                    <label>Location</label>
                    <ng-select class="form-control p-0" [(ngModel)]="new_record.location_id">
                        <ng-option value="0">Select Location</ng-option>
                        <ng-option *ngFor="let data of locations" value="{{ data.id }}">{{ data.name }}</ng-option>
                    </ng-select>
                </div> -->

                <div class="col-lg-4 mt-4">
                    <label>Time In</label>
                    <app-input
                        [type]="'datetime-local'"
                        [class]="'form-control'"
                        [id]="'time_in'"
                        [value]="new_record.home"
                        [placeholder]="'Select Time'"
                        [(inputModel)]="new_record.time_in">
                    </app-input>
                </div>

                <div class="col-lg-4 mt-4">

                      <label>Do you need an Ambulance?</label>
                      <app-select
                        [list]="ambulanceNeededStatus"
                        [id]="'ambulance'"
                        [name]="'name'"
                        [value]="selectedAmbulanceRequired ? selectedAmbulanceRequired.name : ''"
                        [placeholder]="'Ambulance Needed'"
                        [(inputModel)]="selectedAmbulanceRequired">
                    </app-select>

                </div>

                <div class="col-lg-4 mt-4">

                    <label>Select Ambulance, if Required</label>
                    <app-select
                        [list]="listOfVehicles"
                        [id]="'vehicles'"
                        [name]="'name'"
                        [value]="selectedVehicles ? selectedVehicles.name : ''"
                        [placeholder]="'Ambulance Needed'"
                        [(inputModel)]="selectedVehicles">
                    </app-select>

                </div>

                <div class="col-lg-4 mt-4">
                  <label>Patient's SHE Number</label>
                  <app-input
                      [type]="'text'"
                      [class]="'form-control'"
                      [id]="'she_number'"
                      [value]="new_record.she_number"
                      [placeholder]="'Enter She Number'"
                      [(inputModel)]="new_record.she_number">
                  </app-input>
              </div>

            </div>

          </div>

          <div class="row">

            <div class="col-lg-6 mt-4">
              <label>Equipment</label>

                <div style="max-height:400px; overflow-y:scroll;width:100%">

                  <table class="table table-invoice table-bordered">

                      <tbody>
                          <tr *ngFor="let data of equipments; let i = index" value="{{ data.id }}">
                              <td>{{ data.name }}</td>
                              <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                              <td>
                                <app-input
                                    [type]="'number'"
                                    [class]="'form-control'"
                                    [id]="'quantity'"
                                    [value]="data.quantity"
                                    [placeholder]="'0'"
                                    [(inputModel)]="data.quantity">
                                </app-input>
                              </td>
                              <td class="tx-4">
                                  <input type="checkbox" (change)="onCheckedEquipment($event)" name="" value="{{ data.id }}" />
                              </td>
                          </tr>
                      </tbody>
                  </table>

                </div>

            </div>
            <!-- End of Equipment Section -->

            <div class="col-lg-6 mt-4">
              <label>Observations</label>

                <div style="max-height:400px; overflow-y:scroll;width:100%">

                  <table class="table table-invoice table-bordered">

                      <tbody>
                          <tr *ngFor="let data of observations; let i = index" value="{{ data.id }}">
                              <td>{{ data.name }}</td>
                              <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                              <td>

                                <app-input
                                    [type]="'number'"
                                    [class]="'form-control'"
                                    [id]="'quantity'"
                                    [value]="data.quantity"
                                    [placeholder]="'0'"
                                    [(inputModel)]="data.quantity">
                                </app-input>

                                <!-- <ieditor  [type]="number" [id]="data.id" [column]="'quantity'" [table]="'observations'" [label]="data.quantity" [state]="true"></ieditor> -->

                              </td>
                              <td class="tx-4">
                                  <input type="checkbox" (change)="onCheckedObservation($event)" name="" value="{{ data.id }}" />
                              </td>
                          </tr>
                      </tbody>
                  </table>

                </div>

            </div>

            <!-- End of Observation section -->

            <div class="col-lg-6 mt-4">
              <label>Drugs</label>

                <div style="max-height:400px; overflow-y:scroll;width:100%">

                  <table class="table table-invoice table-bordered">

                      <tbody>
                          <tr *ngFor="let data of drugs; let i = index" value="{{ data.id }}">
                              <td>{{ data.name }}</td>
                              <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                              <td>
                                <app-input
                                      [type]="'number'"
                                      [class]="'form-control'"
                                      [id]="'quantity'"
                                      [value]="data.quantity"
                                      [placeholder]="'0'"
                                      [(inputModel)]="data.quantity">
                                  </app-input>
                              </td>
                              <td class="tx-4">
                                  <input type="checkbox" (change)="onCheckedDrug($event)" name="" value="{{ data.id }}" />
                              </td>
                          </tr>
                      </tbody>
                  </table>

                </div>

            </div>

            <div class="col-lg-6 mt-4">
                <label>Procedure</label>
  
                  <div style="max-height:400px; overflow-y:scroll;width:100%">
  
                    <table class="table table-invoice table-bordered">
  
                        <tbody>
                            <tr *ngFor="let data of procedures; let i = index" value="{{ data.id }}">
                                <td>{{ data.name }}</td>
                                <td *ngIf="isAdmin()">£{{ data.cost }}</td>
                                <td>
                                  <app-input
                                        [type]="'number'"
                                        [class]="'form-control'"
                                        [id]="'quantity'"
                                        [value]="data.quantity"
                                        [placeholder]="'0'"
                                        [(inputModel)]="data.quantity">
                                    </app-input>
                                </td>
                                <td class="tx-4">
                                    <input type="checkbox" (change)="onCheckedDrug($event)" name="" value="{{ data.id }}" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
  
                  </div>
  
              </div>

            <div class="col-lg-6 mt-4">

                <div class="row">
  
                    <div class="col-lg-12">
  
                      <label>Treatment Descriptions</label>
                      <app-textarea
                          [type]="'text'"
                          [class]="'form-control'"
                          [id]="'illness_or_treatment_description'"
                          [value]="new_record.illness_or_treatment_description"
                          [placeholder]="'Enter Description'"
                          [(inputModel)]="new_record.illness_or_treatment_description">
                      </app-textarea>
                    </div>
                </div>
            </div>

            <!-- End of Drug section -->

            <div class="col-lg-6 mt-4">

              <div class="row">

                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" (click)="onSwitchToggled('exhibitor')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Exhibitor</span>
                        </label>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" (click)="onSwitchToggled('contractor')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Contractor</span>
                        </label>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" (click)="onSwitchToggled('staff')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Arena Staff</span>
                        </label>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" (click)="onSwitchToggled('visitor')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Visitor</span>
                        </label>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" (click)="onSwitchToggled('catering')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Catering</span>
                        </label>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" checked="false" (click)="onSwitchToggled('security')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Security</span>
                        </label>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <label class="custom-switch">
                            <input type="radio" (click)="onSwitchToggled('cleaning')" name="custom-switch-checkbox" class="custom-switch-input">
                            <span class="custom-switch-indicator"></span>
                            <span class="custom-switch-description">Cleaning</span>
                        </label>
                    </div>

              </div>

            </div>
            <!-- Treatment section -->


        </div>

          <!-- <div class="col-lg-6 mt-4">

              <label>Staff</label>
              <ng-select class="form-control p-0" (change)="onDataSelected($event, 'user')">
                  <ng-option *ngFor="let data of users; let i = index" value="{{ data.id }}">{{ data.name }}</ng-option>
              </ng-select>

          </div> -->







          <div class="col-lg-12">

            <!-- <div class="row">
                <div class="col-lg-12">
                    <label>Do you need an Ambulance?</label>
                    <ng-select class="form-control p-0" [(ngModel)]="new_record.is_ambulance_needed">
                        <ng-option value="1">Yes</ng-option>
                        <ng-option value="0">No</ng-option>
                    </ng-select>
                </div>
            </div> -->


          </div>



        </div>

  </div>
  <div class="modal-footer">
      <button (click)="onCreateRecord()" class="btn ripple btn-primary" type="button">Add Patient Record</button>
      <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>

</ng-template>

<button id="openModalButton" class="btn btn-info btn-sm mr-2" data-toggle="modal" (click)="openAddRecord(addRecord)" data-target="#myModal">Add Patient Record</button>

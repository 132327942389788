import { VehicleScheduleService } from './../../../../../shared/services/requests/core/vehicles.service';
import { frequencies, ServiceHistory, States, scheduleStates, VehicleSchedule, VehicleUsers, VehicleWorkshop } from './../vehicle.data';
import { UtilService } from './../../../../../shared/services/utilities/utils.service';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
  ButtonsConfig, ButtonsStrategy, AdvancedLayout, GalleryService, Image, KS_DEFAULT_BTN_CLOSE, KS_DEFAULT_BTN_DELETE, KS_DEFAULT_BTN_DOWNLOAD, KS_DEFAULT_BTN_EXTURL, KS_DEFAULT_BTN_FULL_SCREEN, ButtonEvent, ButtonType, PlainGalleryConfig, PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery';
import { AuthenticationService } from '../../../../../shared/services/auth.service';
import { request_error } from '../../../../../shared/services/requests/core/status.data';

@Component({
  selector: 'vehicle-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class VehicleScheduleComponent implements OnInit {


  @Input() vehicleid!: number
  @Input() schedules!: VehicleSchedule[];

  public onDeleteChange: Subject<any> = new Subject();
  @Output() onSuccessResponse = new EventEmitter<string>();

  public page = 1
  public config: any = {
      currentPage: 1,
      pageSize: 6,
      maxSize: 5
  };

  public is_update: boolean = false

  public status: string = "success"

  public selectedFrequency: States = new States()
  public frequencys: States[] =  frequencies


  public selectedState: States = new States()
  public states: States[] = scheduleStates;

  public payload: VehicleSchedule = new VehicleSchedule()

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    private utilService: UtilService,
    private scheduleService: VehicleScheduleService
    ) {}

  public userid:any;

  ngOnInit(): void {

    this.load()
  }

  load(){

      this.onSuccessResponse.emit('')
  }

  add(){
      let service = new VehicleSchedule()
      service.editable = true
      this.schedules.push(service)
      this.payload = service;
  }

  edit(item: VehicleSchedule){

      this.schedules.forEach(element => {
        element.editable = false
      })

      item.editable = true
      this.payload = item

      this.selectedState = this.utilService.find(this.states, this.payload._state)
      this.selectedFrequency = this.utilService.find(this.frequencys, this.payload.frequency_id)
      // this.selectedWorkshop = this.utilService.find(this.workshops, this.payload.workshop_id)
  }

  cancel(item){
      item.editable = false
  }

  getFrequency(item: VehicleSchedule){

    try{
       let frequency: States = this.utilService.find(this.frequencys, this.payload.frequency_id)
       return frequency.name
    }catch(e){
      return '-'
    }
  }

  save(item){

    this.payload._state = this.selectedState.id
    this.payload.frequency_id = this.selectedFrequency.id
    // this.payload.workshop_id = this.selectedWorkshop.id

    this.payload.vehicle_id = this.vehicleid;
    this.scheduleService.create(this.payload).subscribe(response => {
        if(response.status == 'success'){
            this.toastr.success(response.message)
            item.editable = false
            return;
        }
        this.toastr.error(response.message)
    }, error => this.toastr.error(request_error))
  }

  update(item){

    this.payload._state = this.selectedState.id
    this.payload.frequency_id = this.selectedFrequency.id
    // this.payload.workshop_id = this.selectedWorkshop.id

      const body:any = {}
      body.target = {uuid: this.payload.uuid}
      body.data = this.payload;

      this.scheduleService.update(body).subscribe(response => {
        if(response.status == 'success'){
            this.toastr.success(response.message)

            item.editable = false
            this.load(); return;

        }
        this.toastr.error(response.message)
    }, error => this.toastr.error(request_error))
  }

  isSuperAdmin(){
      return true;
  }

}

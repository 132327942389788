import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {

    @Input() placeholder: string = "";
    @Input() info: string = "";
    @Input() required:boolean = true;
    @Input() name: string = "name";
    @Input() label: string = ""
    @Input() list: Array<any> = new Array<any>()
    @Input() disabled:boolean = false;

    @Input() inputModel: any = "Default Value";
    @Output() inputModelChange = new EventEmitter<any>();

    constructor(){

    }

    ngOnInit(){
       // console.log("Select:", this.list, this.placeholder)
    }

    updateSelected($event){

      this.inputModelChange.emit(this.inputModel)
    }

}

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { until } from 'protractor';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Access, AuthenticationService, Control } from './auth.service';


//Menu Bar
export interface Menu {
  headTitle?: string;
  title?: string;
  path?: string;
  icon?: string;
  type?: string;
  key?: string;
  badgeClass?: string;
  badgeValue?: string;
  active?: boolean;
  children?: Menu[];
  show?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  private access: Access = new Access();


  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  public megaMenu: boolean = false;
  public megaMenuCollapse: boolean = window.innerWidth < 1199 ? true : false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  private userid: number;
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {

    this.userid = this.authService.userid();

    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize').pipe(
      debounceTime(1000),
      takeUntil(this.unsubscriber)
    ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
      if (evt.target.innerwidth < 991) {
        this.collapseSidebar = false;
        this.megaMenu = false;
      }

      if (evt.target.innerWidth < 1199) {
        this.megaMenuCollapse = true;
      }
    });
    if (window.innerWidth < 991) {
      this.router.events.subscribe(event => {
        this.collapseSidebar = false;
        this.megaMenu = false;
      })
    }

  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }


  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  MENUITEMS: Menu[] = [
    //title
    { headTitle: 'dashboard' },
    {
      path: '/dashboard', title: 'Dashboard', type: 'link', icon: 'ti-home', active: false, show: true
    },
    {
      title: 'Job Records', icon: 'ti-file', type: 'sub', key: 'job', active: false, show: this.access.job.read,
      children: [
        { path: '/jobs', icon: 'ti-file', title: 'Job Lists', type: 'link', show: true },
        { path: '/jobs/calendar', icon: 'ti-calendar', title: 'Calendar', type: 'link' ,  show: true },
        { path: '/jobs/assigned', icon: 'ti-check-box', title: 'Assigned', type: 'link', show: true },
        {path: '/jobs/archives', icon: 'ti-notepad', title: 'Archived Jobs', type: 'link',  show: true },
        {path: '/jobs/elt-logs', icon: 'ti-notepad', title: 'ELT Logs', type: 'link',  show: true },
      ]
    },
    {
      title: 'Invoices', icon: 'ti-file', type: 'sub', key: 'job', active: false, show: this.access.job.read,
      children: [
        { path: '/invoices', icon: 'ti-file', title: 'Records', type: 'link', show: true },
        { path: '/invoices/controls', icon: 'ti-file', title: 'Controls', type: 'link', show: true },
        { path: '/invoices/raised', icon: 'ti-file', title: 'Cumulative', type: 'link', show: true },
      ]
    },
   
    {
      path: '/users', title: 'Workers', type: 'link',  key: 'user', icon: 'ti-user', active: false, show: this.access.user.read
    },
    { path: '/users/payables', icon: 'ti-alarm-clock', title: 'Payable Hours', type: 'link', show: true },
    { path: '/equipments/locations', icon: 'ti-location-pin', title: 'Locations', type: 'link', show: true },
    
    {
      title: 'Manage Vehicles', icon: 'ti-truck', type: 'sub', key: 'settings', active: false, show: this.access.settings.read,
      children: [
        { path: '/vehicles', icon: 'ti-truck', title: 'Vehicles', type: 'link', show: true },
        { path: '/vehicles/workshops', icon: 'ti-truck', title: 'Workshops', type: 'link', show: true },
        { path: '/vehicles/faults', icon: 'ti-truck', title: 'Faults', type: 'link', show: true },
      ]
    },
    { path: '/equipments', icon: 'ti-slice', title: 'Accessories', type: 'link', show: true },
    {
      title: 'Tools & Assets', icon: 'ti-truck', type: 'sub', key: 'settings', active: false, show: this.access.settings.read,
      children: [
        { path: '/resources', icon: 'ti-slice', title: 'Manage', type: 'link', show: true },
        { path: '/resources/locations', icon: 'ti-map', title: 'Locations', type: 'link', show: true },
      ]
    },
    
    // { path: '/resources', icon: 'ti-slice', title: 'Tools & Assets', type: 'link', show: true },

    { path: '/equipments/hospitals', icon: 'ti-slice', title: 'Hospitals', type: 'link', show: true },
    {
      path: '/report/service', icon: 'ti-file', title: 'Reporting Services', type: 'link', key: 'report', show: this.access.report.read
    },
    {
      path: '/jobs/notifications', icon: 'ti-bell', title: 'Notifications', type: 'link', key: 'report', show: this.access.report.read
    },
    {
      path: '/jobs/clients', icon: 'ti-agenda', title: 'Organizations', type: 'link', key: 'organization', show: this.access.organization.read
    },
    // {
    //   path: '/jobs/channels', icon: 'ti-agenda', title: 'Channels', type: 'link', key: 'channels', show: true
    // },
    {
      title: 'Settings', icon: 'ti-settings', type: 'sub', key: 'settings', active: false, show: this.access.settings.read,
      children: [
        { path: '/settings/access', title: 'Access', type: 'link', show: true },
        { path: '/settings/connect', title: 'Connect', type: 'link', show: true },
      ]
    }
  ];


  USER_MENUITEMS: Menu[] =
  [
    { headTitle: 'dashboard' },
    {
      path: '/dashboard', title: 'Dashboard', type: 'link', icon: 'ti-home', active: false, show: true
    },
    {
      path: `/users/${this.authService.userid()}/details`, icon: 'ti-notepad', title: 'My profile', type: 'link', show: true
    },
    { path: '/users/schedule', icon: 'ti-check-box', title: 'Schedule Availability', type: 'link', show: true },
    { path: '/users/payables', icon: 'ti-file', title: 'Payable Hours', type: 'link', show: true },
  ]

  COMPANY_MENUITEMS: Menu[] = [
    { headTitle: 'dashboard' },
    {
      path: '/dashboard', title: 'Dashboard', type: 'link', icon: 'ti-home', active: false, show: true
    },
    { path: '/jobs/calendar', icon: 'ti-calendar', title: 'Calendar', type: 'link' , key: 'calendar', show: true },
    {
      path: '/users/profiles', title: 'Team Profile', type: 'link',  key: 'user', icon: 'ti-user', active: false, show: true
    },
    { path: '/jobs', icon: 'ti-file', title: 'Jobs', type: 'link' , show: true},
    {
      path: '/jobs/elt-logs', icon: 'ti-notepad', title: 'ELT Logs', type: 'link', show: true
    },
  ];

  WORKSHOP_MENUITEMS: Menu[] = [
    { headTitle: 'dashboard' },
    {
      path: '/dashboard', title: 'Dashboard', type: 'link', icon: 'ti-home', active: false, show: true
    },
    {
      path: `/users/${this.authService.userid()}/details`, icon: 'ti-notepad', title: 'My profile', type: 'link', show: true
    },
    { path: '/vehicles/services/history', icon: 'ti-file', title: 'Service History', type: 'link' , show: true},
    { path: '/vehicles', icon: 'ti-truck', title: 'Vehicles', type: 'link', show: true },
  ];


  setUserMenu(){

    this.access = this.authService.setAccess();

    // console.log("nav Service issues::", this.access, this.MENUITEMS)

    // console.log("Admin:", this.authService.isAdmin(), "Client::", this.authService.isClient())


    var data = this.MENUITEMS.filter(x =>{

      if(typeof x.key != undefined){

          let access: Control = this.access[x.key]
          if(access){
            // console.log("Access CHECK::", x.key,  access)
            x.show = access.read
          }
      }
      return x
    })

    // console.log("Filter Service issues::",data, this.access, this.MENUITEMS)

      let menu = this.USER_MENUITEMS;
      if(this.authService.isClient()){
          menu = this.COMPANY_MENUITEMS;
      }else if(this.authService.isAdmin()){
          menu = this.MENUITEMS;
      } else if(this.authService.isWorkshop()){
          menu = this.WORKSHOP_MENUITEMS
      }

      return menu;
  }

  //array
  /**
   * Switch the menu based on the type of
   * user who has logged into the system
   */
  items = new BehaviorSubject<Menu[]>(this.setUserMenu());
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class JobService extends AppService {

    all(callback, data = {}){

        this.post(`fetch/job/list`, data).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

       },error => callback(error, false));
    }

    search(body, callback){

      this.post(`fetch/job/search/with/date`, body).subscribe(response => {

          let status =  false
          if(response.status == "success"){
              status = true
          }
          callback(response.data, status);

       },error => callback(error, false));
    }

    send(body:any, callback){

      body.created_by_id = 1;

      this.post(`create/job/request/record`, body).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

     },error => callback(error, false));
    }

    update(body:any, callback){

      this.post(`update/job`, body).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

     },error => callback(error, false));
    }

  /**
   *
   * @param path
   * @param callback
   */
  _get(path, callback){

    this.get(path, {}).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }else{
        }
        callback(response.data, status);

    },(error: HttpErrorResponse) => callback(error, false));
  }


  /**
   * IMPLEMENTATION FOR THE NEW JOB FETCH
   * 
   * @param path 
   * @param callback 
   * @returns 
   */
  getRequest(path){
     return this.get(path, {});
  }

  postRequest(path, params){
      return this.post(path, params);
  }

  /**
   *
   * @param path
   * @param body
   * @param callback
   */
  _post(path, body, callback){

    this.post(path, body).subscribe(response => {

        let status =  false
        if(response.status == "success"){
            status = true
        }
        callback(response.data, status);

    },(error: HttpErrorResponse) => callback(error, false));
  }

  copy(params:any){
      return this.post(`job/logs/copy/channel`, params)
  }
}

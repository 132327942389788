<div class="page main-signin-wrapper">

    <!-- Row -->
    <div class="row signpages text-center">
        <div class="col-md-12">
            <div class="card">
                <div class="row row-sm">
                    <div class="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                        <div class="mt-5 pt-2 text-center p-2 pos-absolute fill">
                           
                                <img src="../../assets/img/logo.png" style="width:180px;display:block;
                                margin:auto;" class="header-brand-img mb-4" alt="logo">
                            
                            <div class="clearfix"></div>
                            <img src="../../assets/img/svgs/user.svg" class="ht-100 mb-0" alt="user">
                            <h5 class="mt-4 text-white">Medical Solutions Ltd</h5>
                            <span class="tx-white-6 tx-13 mb-5 mt-xl-0">

                                 We have a wide range of <br />
                                 ambulances at our disposal. Professionals  <br />
                                 including, Doctors, Nurses, Paramedics, <br />
                                 Ambulance Technicians FPOS.
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                        <div class="container-fluid">
                            <div class="row row-sm">
                                <div class="card-body mt-2 mb-2">
                                    <!-- <img src="../../assets/img/logo.png" class=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo"> -->
                                    <div class="clearfix"></div>
                                    <form>
                                        <h5 class="text-left mb-2 mt-5 text-center">New Account SignUp</h5>
                                        <p class="mb-4 text-center text-muted tx-13 ml-0 text-left">
                                            Create new user account
                                        </p>
                                        <!--<ngb-alert type="primary" [dismissible]="false">
                                             <span class="alert-inner--text">Use account <strong>spruha@demo.com</strong> and password <strong>spruha</strong> to continue.</span> 
                                        </ngb-alert>-->
                                        <div class="form-group row">

                                            
                                    
                                                <div class="col-lg-12 mt-4 text-left">
                                                    
                                                    <label>Full Name</label>
                                                    <app-input 
                                                        [type]="'text'" 
                                                        [class]="'form-control'" 
                                                        [id]="'names'"
                                                        [value]="newUser.names"
                                                        [placeholder]="'Enter Last Name'"
                                                        [(inputModel)]="newUser.names">
                                                    </app-input>
                                    
                                                </div>
                                    
                                                <div class="col-lg-12 mt-4 text-left">
                                                    
                                                    <label>Email Address</label>
                                                    <app-input 
                                                        [type]="'email'" 
                                                        [class]="'form-control'" 
                                                        [id]="'email'"
                                                        [value]="newUser.email"
                                                        [placeholder]="'Enter Username'"
                                                        [(inputModel)]="newUser.email">
                                                    </app-input>
                                    
                                                </div>

                                                <div class="col-lg-12 mt-4 text-left">

                                                    <!-- <label>Staff Type</label>
                                                    <ng-select [(ngModel)]="newUser.type" class="form-control p-0">
                                                        <ng-option value="1">Permanent Staff</ng-option>
                                                        <ng-option value="2">Temporary Staff</ng-option>
                                                        <ng-option value="3">Contract Staff</ng-option>
                                                    </ng-select> -->

                                                    <app-select
                                                        [list]="types"
                                                        [id]="'type'"
                                                        [name]="'name'"
                                                        [value]="selectedType.name"
                                                        [placeholder]="'Staff Type'"
                                                        [(inputModel)]="selectedType">
                                                    </app-select>
                                
                                                </div>


                                                <div class="col-lg-12 mt-4  text-left">
                                                    <label>Phone Number</label>
                                                    <app-input 
                                                        [type]="'phone'" 
                                                        [class]="'form-control'" 
                                                        [id]="'phone'"
                                                        [value]="newUser.phone"
                                                        [placeholder]="'Enter Phone Number'"
                                                        [(inputModel)]="newUser.phone">
                                                    </app-input>
                                    
                                                </div>
                                    
                                                <div class="col-lg-6 mt-4 text-left">
                                                    <label>Password</label>
                                                    <app-input 
                                                        [type]="'password'" 
                                                        [class]="'form-control'" 
                                                        [id]="'password'"
                                                        [value]="newUser.password"
                                                        [placeholder]="'Enter Password'"
                                                        [(inputModel)]="newUser.password">
                                                    </app-input>
                                    
                                                </div>

                                                <div class="col-lg-6 mt-4 text-left">
                                                    <label>Confirm Password</label>
                                                    <app-input 
                                                        [type]="'password'" 
                                                        [class]="'form-control'" 
                                                        [id]="'confim_password'"
                                                        [value]="newUser.confim_password"
                                                        [placeholder]="'Confirm Password'"
                                                        [(inputModel)]="newUser.confim_password">
                                                    </app-input>
                                    
                                                </div>
                                    
                                                
                                    
                                        </div>
                                            
                                        <button class="btn ripple btn-main-primary btn-block" (click)="submit()"
                                            >Sign In</button>
                                            <div class="form-group text-center mt-2" *ngIf="isloading">
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span> 
                                                </div>
                                                Loading...
                                            </div>

                                            <div class="mt-2 alert alert-danger" *ngIf="isSignUpFailed">
                                                Invalid Username or Password
                                            </div>

                                            <div class="mt-2 alert alert-success text-left" *ngIf="isSignUpSuccess">
                                                Account successfully created! <a href="javascript:;" class="pull-right" routerLink="login">Please, Login</a>
                                            </div>
                                    </form>

                                    <div class="mt-5"></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Row -->

</div>
import { VehicleAssigned } from './../../../../reporting/service-report/editor/model/report.model';
import { VehicleScheduleService, VehicleJobHistoryService } from './../../../../../shared/services/requests/core/vehicles.service';
import { frequencies, ServiceHistory, States, scheduleStates, VehicleSchedule, VehicleUsers, VehicleWorkshop, AssignedVehicle } from './../vehicle.data';
import { UtilService } from './../../../../../shared/services/utilities/utils.service';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
  ButtonsConfig, ButtonsStrategy, AdvancedLayout, GalleryService, Image, KS_DEFAULT_BTN_CLOSE, KS_DEFAULT_BTN_DELETE, KS_DEFAULT_BTN_DOWNLOAD, KS_DEFAULT_BTN_EXTURL, KS_DEFAULT_BTN_FULL_SCREEN, ButtonEvent, ButtonType, PlainGalleryConfig, PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery';
import { AuthenticationService } from '../../../../../shared/services/auth.service';
import { request_error } from '../../../../../shared/services/requests/core/status.data';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'vehicle-assigned',
  templateUrl: './assigned.component.html',
  styleUrls: ['./assigned.component.scss']
})
export class VehicleAssignedComponent implements OnInit {


  @Input() vehicleid!: number
  @Input() assigns!: AssignedVehicle[];

  public onDeleteChange: Subject<any> = new Subject();
  @Output() onSuccessResponse = new EventEmitter<string>();

  public page = 1
  public config: any = {
      currentPage: 1,
      pageSize: 6,
      maxSize: 5
  };

  public is_update: boolean = false

  public status: string = "success"

  public selectedFrequency: States = new States()
  public frequencys: States[] =  frequencies


  public selectedState: States = new States()
  public states: States[] = scheduleStates;

  public payload: AssignedVehicle = new AssignedVehicle()

  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private galleryService: GalleryService,
    private utilService: UtilService,
    private assignedService: VehicleJobHistoryService,
    private dateService: DateService
    ) {}

  public userid:any;

  ngOnInit(): void {

    this.load()
  }

  load(){

      this.onSuccessResponse.emit('')
  }

  add(){

      let service = new AssignedVehicle()
      service.editable = true
      this.assigns.push(service)
      this.payload = service;
  }


  cancel(item){
      item.editable = false
  }

  isSuperAdmin(){
      return true;
  }

  formatDateToUK(date: string){
      
    return this.dateService.formatDateToUK(date)
 }

}

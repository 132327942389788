import { Injectable } from '@angular/core';
import { CoreService } from './app.service';
import { CoreRequestService } from './request.service';


@Injectable({
  providedIn: 'root'
})
export class ToolsService extends CoreRequestService {

    constructor( protected override appService: CoreService){
        super(appService)

        this.FETCH_PATH     = `tools/fetch/tools`;
        this.ALL_PATH       = `tools/list/tools`;
        this.FIND_PATH      = `tools/find/tools`;
        this.UPDATE_PATH    = `tools/update/tools`;
        this.CREATE_PATH    = `tools/create/tools`;
        this.DELETE_PATH    = `tools/delete/tools`;
    }

    /**
     * All Other methods can come here
     */
}


@Injectable({
    providedIn: 'root'
  })
  export class ToolHistoryService extends CoreRequestService {
  
      constructor( protected override appService: CoreService){
          super(appService)
  
          this.FETCH_PATH     = `tools/fetch/tool-history`;
          this.ALL_PATH       = `tools/list/tools-history`;
          this.FIND_PATH      = `tools/find/tools-history`;
          this.UPDATE_PATH    = `tools/update/tools-history`;
          this.CREATE_PATH    = `tools/create/tools-history`;
          this.DELETE_PATH    = `tools/delete/tools-history`;
      }
  
      /**
       * All Other methods can come here
       */
  }

  @Injectable({
    providedIn: 'root'
  })
  export class ToolImageService extends CoreRequestService {
  
      constructor( protected override appService: CoreService){
          super(appService)
  
          this.FETCH_PATH     = `tools/fetch/images`;
          this.ALL_PATH       = `tools/list/images`;
          this.FIND_PATH      = `tools/find/images`;
          this.UPDATE_PATH    = `tools/update/images`;
          this.CREATE_PATH    = `tools/create/images`;
          this.DELETE_PATH    = `tools/delete/images`;
      }
  
      /**
       * All Other methods can come here
       */
  }


  @Injectable({
    providedIn: 'root'
  })
  export class AssetTransferService extends CoreRequestService {
  
      constructor( protected override appService: CoreService){
          super(appService)
  
          this.FETCH_PATH     = `tools/fetch/tool-transfer`;
          this.ALL_PATH       = `tools/list/tool-transfer`;
          this.FIND_PATH      = `tools/find/tool-transfer`;
          this.UPDATE_PATH    = `tools/update/tool-transfer`;
          this.CREATE_PATH    = `tools/create/tool-transfer`;
          this.DELETE_PATH    = `tools/delete/tool-transfer`;
      }
  
      /**
       * All Other methods can come here
       */
  }

  @Injectable({
    providedIn: 'root'
  })
  export class ToolLocationsService extends CoreRequestService {
  
      constructor( protected override appService: CoreService){
          super(appService)
  
          this.FETCH_PATH     = `tools/fetch/location`;
          this.ALL_PATH       = `tools/list/location`;
          this.FIND_PATH      = `tools/find/location`;
          this.UPDATE_PATH    = `tools/update/location`;
          this.CREATE_PATH    = `tools/create/location`;
          this.DELETE_PATH    = `tools/delete/location`;
      }
  
      /**
       * All Other methods can come here
       */
  }
<div class="row">

<div class="col-lg-6">
    <div class="card-body p-0 border p-0 rounded-10">
      <inline-chat *ngIf="vehicle" [ownerid]="channel" [target]="target" [owner]="'vehicle'" [menu]="false" [name]="name"></inline-chat>
    </div>
</div>

<div class="col-lg-6">
  <div class="card-body p-0 border p-0 rounded-10">
    <inline-chat *ngIf="vehicle" [ownerid]="elt_channel" [target]="elt_target" [owner]="'vehicle'" [menu]="false" [name]="elt_name"></inline-chat>
  </div>
</div>

</div>

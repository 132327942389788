import { JobReportService } from './../../../../shared/services/jobs/job.report.service';
import { ToastrService } from 'ngx-toastr';
import { JobAccessor } from '../../../../shared/services/jobs/job.accessor';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UserRecord } from 'src/app/shared/models/auth.models';
import { UserService } from 'src/app/shared/services/users/user.service';
import { reportMessage } from './report.data';

@Component({
  selector: 'send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.scss']
})

/*
 *
 */
export class SendReportComponent implements OnInit, AfterViewInit {

  @Output() onSuccessResponse: EventEmitter<any> = new EventEmitter();
  @Input() eventid: number;

    constructor(
      private modal: NgbModal,
      private reportService: JobReportService,
      private jobAccessor: JobAccessor,
      private userService: UserService,
      private toastr: ToastrService)
      {

    }

    public mail: Mail = new Mail()

    ngAfterViewInit(){

    }

    public emails: string[] = new Array<string>()
    

    ngOnInit(): void {



      this.mail.job_id = this.eventid
      this.mail.message = reportMessage

      this.loadUsers();
    }

    open(){
        let doc:any = document.querySelector("#openSendReportModalButton")
        doc.click();
    }

    openPopUPModal(addReportModal){

        this.modal.open(addReportModal, {size: 'lg'})
    }

    public selectedUser: UserRecord = new UserRecord;
    public users: UserRecord[] = new Array<UserRecord>()
    public selectedUsers: UserRecord[] = new Array<UserRecord>()

    loadUsers(){
        this.userService.managers({role: 1}).subscribe(response => {
            if(response.status == "success"){
                  this.users = response.data
            }
        })
    }

    onModelChanged($event){
          this.mail.message = $event
    }


    sendReport($event){


    let emails = []
    this.selectedUsers.forEach(user => {
        emails.push(user.email)
    })

    if(this.mail.emails){
        let list = this.mail.emails.split(",")
        if(list.length > 0){
            list.forEach(email => {
              emails.push(email)
          })
        }
    }

    if( !this.mail.job_id || !this.mail.message){
        this.toastr.warning("All fields are required!");
        return;
    }
    
    if(emails.length == 0){
      if(!this.mail.emails){
          this.toastr.warning("You must select or enter receipient emails!");
          return;
      }
    }

    const body:any = {
        job_id: this.mail.job_id,
        emails: emails,
        message: this.mail.message
    }
    // console.log("Checking em,ails::::", body, this.selectedUsers); return;

    this.jobAccessor.sendReport(body).subscribe(response => {

        if(response.status == "success"){
            this.onSuccessResponse.emit(true);
            this.toastr.success("Job/Event report successfully sent")
            this.modal.dismissAll()
            return;
        }
        this.toastr.error("Unable to send Job/Event Report")
    }, error => {
        this.toastr.error("Unable to send Job/Event Report")
    })
  }

  addSelectedUser(){

    if(this.selectedUser.id){
        this.deleteSelectedUser(this.selectedUser.id)
    }
    this.selectedUsers.push(this.selectedUser)
  }

  deleteSelectedUser(id: number){
      this.selectedUsers = this.selectedUsers.filter(x => {
        return x.id != id
      })
  }
  
}


class Mail {
    job_id: number;
    emails: string
    message: string
}

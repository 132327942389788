import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../../app.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends AppService {

    all(){
        return this.get(`notifications/list/logs`)
    }
    fetch(){
        return this.get(`notifications/fetch/logs`)
    }
    clear(identifier){
        return this.get(`notifications/delete/logs/${identifier}`)
    }
}

/**
 * ALL JOB RELATED INTERFACES HERE
 */

import { UserList } from "./auth.models"


export interface IJob {

    id?: number
    uuid?: string
    name?: string
    start_date?: string
    end_date?: string
    open_at?: string
    close_at?: string
    location?: string
    location_id?:number
    type?: string
    requirements?: string
    workers_needed?: string
    is_ambulance_required?: number
    workers?: WorkerRequired
    created_by_id?: string
    show_on_calendar?: string
    status?: number
    _state?: number
}




export class Assigned {
    clocked_in?: string
    clocked_out?: string
    created_at: string
    id: number
    job_id: number
    status: number
    user_id: number
    updated_at: string
    job_assigned_date: string
    is_reminder_sent: number
    _state: string
    job: Job = new Job()
    user: UserList = new UserList()
}

export class PayableHour extends Assigned{
  shift_ends_at: string
  shift_starts_at: string
  hours: number = 0
  hours_label: string = "-"
  arranged_overtime: number = 0
  arranged_overtime_label: string = "-"
  unarranged_overtime: number = 0
  unarranged_overtime_label: string = "-"
  before_time: number = 0
  before_time_label: string = "-"
}

export class Payable{
  hours: number = 0
  hours_label: string
  arranged_overtime: number = 0
  arranged_overtime_label: string
  unarranged_overtime: number = 0
  unarranged_overtime_label: string
  before_time: number = 0
  before_time_label: string;
}

export class Schedule {

    created_at: string
    days: string
    hours: string
    id: number
    schedule_id: number
    status: number
    updated_at: string
    user_id: number
}

export class SchedulePayload {
    start_date: string;
    end_date: string;
    user_id: number;
}

/**
 * ALL JOB RELATED CLASSES
 */

export class Job implements IJob {
    name?: string;
    id?: number
    uuid!: string
    identifier: string
    start_date?: string
    end_date?: string
    open_at?: string
    close_at?: string
    location?: string
    location_id?: number
    type?: string
    requirements?: string
    workers_needed?: string
    is_ambulance_required?: number
    workers?: WorkerRequired
    created_by_id?: string
    show_on_calendar?: string
    status?: number;
    _state?: number;
    reports!: Report
    files!: JobFile
    log!: ELTLog
    briefing!: Briefing
}

export class WorkerRequired {
    id!: number
    job_id!: number
    doctors: number = 0;
    first_aiders: number  = 0;
    technicians: number  = 0;
    nurses: number  = 0;
    others: number  = 0;
    paramedics: number  = 0;
    radiographers: number = 0;
    frec_4: number = 0;
    frec_3: number = 0;
    managers: number = 0;
    controllers: number = 0;
    logistics: number = 0;
    status!: number
    updated_at!: string
    created_at!: string
}

export class ELTLog extends Job {}

export class Report{
    id: number;
    job_id:number;
    senior_staff_id: number;
    paramedics: number;
    technicians: number;
    clock_in_time: string;
    clock_out_time: string;
    callouts: number;
    ear_pugs: number;
    ambulances: number;
    arena_staff: number;
    catering:number;
    event_staff: number;
    competitor: number;
    visitor: number;
    use_of_o2: number;
    use_of_entonox: number;
    status: number;
    created_at: string;
    updated_at: string;
    user: UserList = new UserList
}


export class JobAssignedPayload {
    sender_id: number
    user_id: number
    message: string
}


export class JobFile
{
    public job_id: number;
    public name: string;
    public link: string;
    public status: number;
    public created_at: string;
    public updated_at: string;
}

export class Briefing { 

    id!: number 
    job_id!: number
    uuid!: string
    event_manager!: string
    security_manager!: string
    esr!: string
    max_cap!: string
    roaming_medics!: string
    upstairs_medics!: string
    downstairs_medics!: string
    artist_stage_time!: string
    manager_on_call!: string
    call_sign_venue!: string
    door_opening_time!: string
    finishing_time!: string
    note!: string
    status!: number
}

export class User {

    id!: number
    uuid!: string
    name!: string
    email!: string
    phone!: string
    _type!: number
    status!: number
}
import { Notice } from './notice.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class NotificationAccessor {

  constructor(private notificationService: NotificationService){}

  private _notice: BehaviorSubject<Notice[] | null> = new BehaviorSubject<Notice[] | null>(null)
  private _previous_notice: BehaviorSubject<Notice[] | null> = new BehaviorSubject<Notice[] | null>(null)


  getNotificationService(){
      return this.notificationService;
  }

  /*** Getter for brands */
  get notice$(): Observable<Notice[] | null>
  {
      return this._notice.asObservable();
  }

  get previous$(): Observable<Notice[] | null>
  {
      return this._previous_notice.asObservable();
  }

   getlist(){
         this.notificationService.all().subscribe(response => {
           if(response){
             this._notice.next(response.data)
           }
         })
    }

    fetchlist(){
      this.notificationService.fetch().subscribe(response => {
        if(response){
          this._previous_notice.next(response.data)
        }
      })
 }

    clear(identifier){
       return this.notificationService.clear(identifier);
    }
}

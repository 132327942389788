import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserList, } from '../../../shared/models/auth.models';

@Component({
  selector: 'dropdown-form',
  templateUrl: `./dropdown-form.component.html`,
  styleUrls: ['./dropdown-form.component.scss'],
})
export class DropDownFormComponent {

    @Input() label: string
    @Input() list: UserList[] = Array<UserList>()
    @Output() onSuccessResponse = new EventEmitter<UserList>();

    public selectedManager: UserList = new UserList()
    constructor( private toastr: ToastrService){

    }

    onSelect($event: UserList){
        this.onSuccessResponse.emit($event)
    }
}
import { UtilService } from './../../../../shared/services/utilities/utils.service';
import { EquipmentPayload } from './../../../reporting/service-report/editor/model/report.model';
import { AuthenticationService } from 'src/app/shared/services/auth.service';
import { ReportAccessor } from '../../../../shared/services/jobs/report.accessor';
import { JobAccessor } from '../../../../shared/services/jobs/job.accessor';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from 'src/app/shared/services/request.service';
import { PathService } from 'src/app/shared/services/path.service';
import { ServiceRecord, JobLocation, Vehicle, Hospital, Equipment } from '../../../reporting/service-report/editor/model/report.model';
import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Job } from 'src/app/shared/models/job.model';

@Component({
  selector: 'update-patient',
  templateUrl: './update-patient.component.html',
  styleUrls: ['./update-patient.component.scss']
})

/*
 *
 */
export class UpdatePatientComponent implements OnInit {

  public modalValue: Subject<any> = new Subject();
  public changingValue: Subject<any> = new Subject();

  @Input() job: Job;
  @Input() jobid: number;
  @Input() reportid: number;
  @Input() report: ServiceRecord;
  @Input() locationid: number
  @Output() onSuccessResponse = new EventEmitter<any>();

 constructor(
  private model: NgbModal,
  private pathService: PathService,
  private requestService: RequestService,
  private reportAccessor: ReportAccessor,
  private authService: AuthenticationService,
  private utilService: UtilService,
  private jobAccessor: JobAccessor,
  private toastr: ToastrService){

    // this.new_record = this.report
  }

  public genders: Gender[] = [
    {id: 1, name: "Male"},
    {id: 2, name: "Female"  },
    {id: 3, name: "Rather Not Say"}
  ]

  public selectedHospitals: Hospital = new Hospital()
  public selectedLocations: JobLocation = new JobLocation()
  public selectedVehicles: Vehicle = new Vehicle()
  public selectedGender: Gender = new Gender()

  public listOfHospitals: Hospital[] = new Array<Hospital>()
  ngOnInit(): void {

    let hospital = new Hospital()
    hospital.id = 0
    hospital.name = "Not Required"
    this.listOfHospitals.push(hospital)

    this.reportAccessor.equipments$.subscribe(response => {
      if(response){
         this.equipments = response;
      }
    })

    this.reportAccessor.hospitals$.subscribe(response => {
      if(response){
          this.listOfHospitals = response;
          this.listOfHospitals.unshift(hospital)
      } 
    })

    this.reportAccessor.locations$.subscribe(response => {
      if(response){
          this.locations = response;
      }
    })

    this.reportAccessor.vehicles$.subscribe(response => {
      if(response){
          this.vehicles = response;
      }
    })

    this.reportAccessor.drugs$.subscribe(response => {
      if(response){
        this.drugs = response;
      }
    })

    this.reportAccessor.observation$.subscribe(response => {
        if(response){
          this.observations = response;
        }
    })

    this.reportAccessor.procedures$.subscribe(response => {
      if(response){
        this.procedures = response;
      }
  })

    this.reportAccessor.getEquipment();
    this.reportAccessor.getLocation();
    this.reportAccessor.getVehicle();
    this.reportAccessor.getDrug();
    this.reportAccessor.getObservation();
    this.reportAccessor.getProcedure();

    
  }

  public listOfVehicles: Vehicle[] = new Array<Vehicle>()
  findAssignedVehicles(jobid: number){

    this.reportAccessor.findVehicle({job_id: jobid}).subscribe(response => {

      console.log("----------->>>Vehicles:::::::", response, jobid)

      if(response.status == 'success'){

          //Reset Record back to empty array
          this.listOfVehicles = this.vehicles = []

          let vehicles = response.data;
          if(vehicles){

            let vehiclePlaceHolder = new Vehicle()
            vehiclePlaceHolder.id = 0;
            vehiclePlaceHolder.name = "Not Required"

            vehicles.forEach(element => {
                this.vehicles.push(element.vehicle)
            });
            
            this.vehicles.unshift(vehiclePlaceHolder)
            this.listOfVehicles = this.vehicles

            if(this.listOfVehicles.length > 0){

              let vehicle_id = this.new_record.vehicle_id
              if(!vehicle_id){

                vehicle_id = this.new_record.vehicle ? this.new_record.vehicle.id : null
              }

              if(vehicle_id){
                this.selectedVehicles = this.utilService.find(this.listOfVehicles, vehicle_id) || vehiclePlaceHolder
              }
            }
          }
      }
    })
  }

  public ambulanceNeededStatus: Status[] = [
      {id: 1, name: "Yes"},
      {id: 0, name: "No"}
  ]
  public selectedAmbulanceRequired: Status = new Status()
  public selectedAmbulance: Vehicle = new Vehicle()

 /**
   * Use this to determine if the patient name is known or not
   */
  public is_patient_name: boolean = true;

  public locations: Array<JobLocation> = new Array<JobLocation>()
  public vehicles: Array<Vehicle> = new Array<Vehicle>()
  public hospitals: Array<Hospital> = new Array<Hospital>()
  public equipments: Array<Equipment> = new Array<Equipment>()
  public drugs: Array<Equipment> = new Array<Equipment>()
  public observations: Array<Equipment> = new Array<Equipment>()
  public procedures: Array<Equipment> = new Array<Equipment>()

  public new_record: ServiceRecord = new ServiceRecord;

  onSwitchToggled(type){

    this.new_record.is_visitor = 0;
    this.new_record.is_arena_staff = 0;
    this.new_record.is_catering = 0;
    this.new_record.is_contractor = 0;
    this.new_record.is_exhibitor = 0;
    this.new_record.is_cleaning = 0;
    this.new_record.is_security = 0;

    switch(type)
    {
        case 'visitor': this.new_record.is_visitor = 1; break;
        case 'staff': this.new_record.is_arena_staff = 1; break;
        case 'catering': this.new_record.is_catering = 1; break;
        case 'cleaning': this.new_record.is_cleaning = 1; break;
        case 'security': this.new_record.is_security = 1; break;
        case 'contractor': this.new_record.is_contractor = 1; break;
        case 'exhibitor': this.new_record.is_exhibitor = 1; break;
        case 'patient_name': this.is_patient_name = this.is_patient_name ? false : true; break
    }
  }

  isAdmin(){
    return this.authService.isAdmin()
}

findHospitals(){
    
  if(!this.job.location_id){
      this.toastr.info("No location ID Found: Unable to fetch hospitals assigned!"); return;
  } 
  this.reportAccessor.findHospital({location_id:this.job.location_id})
}


  public selectedEquipment;
  public selectedObservation;
  public selectedDrug;
  public selectedProcedure;

  onInitModal(data: ServiceRecord, jobid: number){

    this.jobid = jobid

    this.findAssignedVehicles(jobid);
    this.findHospitals()
   
    this.new_record = data

    let equipment:any = this.new_record.equipment

    if(equipment){

        this.selectedEquipment = equipment.filter(x => { return x.type == "equipment"; })
        this.selectedObservation = equipment.filter(x => { return x.type == "observation"; })
        this.selectedDrug = equipment.filter(x => { return x.type == "drug"; })
        this.selectedProcedure = equipment.filter(x => { return x.type == "procedure"; })
    }

    this.equipments = this.setCheckedValue(this.equipments, this.selectedEquipment)
    this.observations = this.setCheckedValue(this.observations, this.selectedObservation)
    this.drugs = this.setCheckedValue(this.drugs, this.selectedDrug)
    this.procedures = this.setCheckedValue(this.procedures, this.selectedProcedure)

    // this.selectedVehicles = this.utilService.find(this.vehicles, this.new_record.vehicle_id)
    
    if(this.new_record.hospital_id){
        this.selectedHospitals = this.utilService.find(this.listOfHospitals, this.new_record.hospital_id) 
    }

    switch(this.new_record.gender){
        case "Male":
          this.selectedGender = this.genders[0]; break;
        case "Female":
          this.selectedGender = this.genders[1]; break;
        default:
          this.selectedGender = this.genders[2]; break;
    }

    if(this.new_record.location_id){
        this.selectedLocations = this.utilService.find(this.locations, this.new_record.location_id)
    }

    this.selectedAmbulanceRequired = this.utilService.find(this.ambulanceNeededStatus, this.new_record.is_ambulance_needed || 0)

    this.new_record.location_id = null
    this.new_record.hospital_id = null
    this.new_record.vehicle_id = null
    this.new_record.is_ambulance_needed = null

    let element:any = document.querySelector('#openUpdatePatientModalButton');
    element.click()

    /**
     * Set active on the patient type
     */
    this.setActiveToggle()
  }

  inArray(data){

    if(!this.new_record.equipment){ return false}

    let equipments = this.new_record.equipment;

      var res = equipments.filter(x => {
          return x.equipment_id == data.id
      })

      return res.length!=0
  }

  getCheckedValue(records: any){

      let res = records.filter(x => {
          return x.quantity > 0 && x.checked
      })
      return res;
    }

  setCheckedValue(records: any, data:any){

    if(!records){ return; }

    let list = records.filter(element => {

         let eq:EquipmentPayload[] = data.filter(x => {
              return x.equipment_id == element.id
          })

          if(eq.length == 0){
              element.quantity = 0
              element.checked = false
              element.report_item_id = this.new_record.id
              element.is_new = true
              return element;
          }

          element.quantity = eq[0].quantity
          element.checked = true
          element.report_item_id = eq[0].report_item_id
          element.equipment_id = eq[0].equipment_id
          element.is_new = false

        return element
    });
    return list;
  }

  inArrayLabel(id: any, arr: any[]): string {

      var res = arr.filter(x => {
          return x.id == id
      })

      return res.length == 0 ? '' : res[0].name
  }

  openAddRecord(UpdatePatientRecord:any ){
      this.model.open(UpdatePatientRecord, {size: "xl"})
  }

  isChecked(data: any){

      return data ? "checked" : ""
  }

  onChecked($event, type, old_value:any){

    this.new_record.is_arena_staff =  this.new_record.is_catering =
    this.new_record.is_contractor =  this.new_record.is_exhibitor =
    this.new_record.is_visitor = 0

    switch(type){
        case 'visitor': this.new_record.is_visitor = old_value ? 0: 1; break;
        case 'staff': this.new_record.is_arena_staff = old_value ? 0: 1;break;
        case 'catering': this.new_record.is_catering = old_value ? 0: 1; break;
        case 'contractor': this.new_record.is_contractor = old_value ? 0: 1; break;
        case 'exhibitor': this.new_record.is_exhibitor = old_value ? 0: 1; break;
    }

    // console.log("Testing cahnges;;;;", type, this.new_record)
  }

  setActiveToggle(){

    if(this.new_record.is_visitor){
        this.findActiveToggle("is_visitor")
    }else if(this.new_record.is_arena_staff){
      this.findActiveToggle('is_staff')
    }else if(this.new_record.is_catering){
        this.findActiveToggle('is_catering')
    }else if(this.new_record.is_cleaning){
        this.findActiveToggle('is_cleaning')
    }else if(this.new_record.is_security){
        this.findActiveToggle('is_security')
    }else if(this.new_record.is_contractor){
        this.findActiveToggle('is_contractor')
    }else if(this.new_record.is_exhibitor){
        this.findActiveToggle('is_exhibitor')
    }
  }

  findActiveToggle(key: string){
    let dom:any = document.querySelector(`#${key}`)
    dom.setAttribute("checked", true)
  }


  public message: string = "Are you sure you want to do this?"
  public selected

  onCreateRecord(){

    let $this = this

    let data:any = this.new_record;
    delete data["hospital"]
    // delete data["equipment"]
    delete data["location"]
    delete data["vehicle"]
    delete data["user"]

    this.new_record.equipment = this.getCheckedValue(this.equipments)
    this.new_record.observation = this.getCheckedValue(this.observations)
    this.new_record.drug = this.getCheckedValue(this.drugs)
    this.new_record.procedure = this.getCheckedValue(this.procedures)

    this.new_record.is_ambulance_needed = this.selectedAmbulanceRequired.id
    this.new_record.vehicle_id = this.selectedAmbulance.id
    this.new_record.hospital_id = this.selectedHospitals.id
    this.new_record.gender = this.selectedGender.name

    const body: any = {}
    body.target = {id: this.new_record.id}
    body.data = this.new_record

    this.requestService._post(this.pathService.UPDATE_RECORDS, body, function(result, status){

      if(status){
          $this.onSuccessResponse.emit(true)
          $this.toastr.success("Patient successfully added to this report")
          $this.model.dismissAll()
       }
    })
  }

  onCheckedDrug(event){
      if(!event.target){ return; }
      if(event.target.checked){
          this.drugs.filter(x => {
              if(x.id == event.target.value){
                if(event.target.checked){
                  x.checked = true
              }else{
                x.checked = false}
              }
              return x
          })
        return;
      }
  }

  onCheckedEquipment(event){
      if(!event.target){ return; }
      if(event.target.checked){
        this.equipments.filter(x => {
            if(x.id == event.target.value){

              if(event.target.checked){
                  x.checked = true
              }else{
                x.checked = false}
            }
            return x
        })
      return;
    }
  }

  onCheckedObservation(event){
      if(!event.target){ return; }
      if(event.target.checked){
        this.observations.filter(x => {
            if(x.id == event.target.value){
              if(event.target.checked){
                x.checked = true
              }else{
                x.checked = false}
            }
            return x
        })
      return;
    }
  }

  onCheckedProcedure(event){
    if(!event.target){ return; }
    if(event.target.checked){
      this.procedures.filter(x => {
          if(x.id == event.target.value){
            if(event.target.checked){
              x.checked = true
            }else{
              x.checked = false}
          }
          return x
      })
    return;
  }
}

}


class Status{
    id!: number 
    name!: string
}

class Gender {
    id!: number 
    name!: string
}